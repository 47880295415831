import {AppResources, Components, Functions, Providers, React} from "Universal/Packages";

const WalletOrderInfo = (properties: any) =>
{
    const translate = Functions.translate();
    let title: string;

    switch (properties.store.payment.providerCode)
    {
        case Providers.Apple:
            title = translate(AppResources.Pages.Payment.ApplePay)
            break;

        case Providers.Google:
            title = translate(AppResources.Pages.Payment.GooglePay)
            break;

        default:
            title = translate(AppResources.Pages.Payment.SamsungPay)
            break;
    }

    return (
        <div className={"modal-info-order-wallet-pay"}>
            <div className="model-provider model-provider-content-wallet-pay">
                <span className="model-provider-content">{title}</span>
            </div>
            <Components.Timeout store={properties.store} onTimeout={properties.onTimeout}/>
            <Components.CheckoutOrder store={properties.store} isShowSecure={false}/>
        </div>
    )
}

export {WalletOrderInfo};
