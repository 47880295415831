import {ImageLoading} from "Core/Components/Loadings/Loadings";
import {AppResources} from "Core/Controllers/Controllers";
import {Functions, Tags} from "Core/References";


const InternetScreen = () =>
{
    const translate = Functions.translate();
    return (
        <Tags.Container id={"internet-loading"} className={"loading-screen loading"}>
            <div className="loading-component">
                <ImageLoading/>
            </div>
            <div className="loading-message">
                {translate(AppResources.Messages.Offline)}
            </div>
            <div className="loading-overlay"></div>
        </Tags.Container>
    );
};


export
{
    InternetScreen,
};