abstract class LanguageOption
{
    // en
    public static English: string = "en";

    // vi
    public static Vietnamese: string = "vi";
}


export {LanguageOption};