import {
    Containers,
    Functions,
    IAppProperties,
    IRouterProperties,
    IStoreProperties,
    ITheme,
    PageRoutes,
    Pages,
    Skins,
    StateEvents,
    Stores,
    TransactionReducer,
    VariantOption
} from "./Packages";


const locationParams = "/:transactionID/:dateTimeExpire/:signature/:language";
const router: IRouterProperties = {
    default: PageRoutes.Home,
    fallback: PageRoutes.Error,
    routes: [
        {
            name: PageRoutes.Home,
            element: <Pages.HomePage skin={Skins.NoHeaderSkin}/>
        },
        {
            name: PageRoutes.Error,
            element: <Pages.ErrorPage skin={Skins.NoHeaderSkin}/>
        },

        // Checkout
        {
            name: `${PageRoutes.Checkout.Index}${locationParams}`,
            element: <Pages.CheckoutPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        {
            name: `${PageRoutes.Checkout.Finish}${locationParams}`,
            element: <Pages.CheckoutFinishPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        {
            name: `${PageRoutes.Checkout.Domestic}${locationParams}`,
            element: <Pages.CheckoutDomesticPage skin={Skins.CheckoutSkinWithOrder}/>
        },
        {
            name: `${PageRoutes.Checkout.International}${locationParams}`,
            element: <Pages.CheckoutInternationalPage skin={Skins.CheckoutSkinWithOrderEmbedded}/>
        },
        {
            name: `${PageRoutes.Checkout.Wallet}${locationParams}`,
            element: <Pages.CheckoutWalletPage skin={Skins.CheckoutSkinWithOrder}/>
        },
        {
            name: `${PageRoutes.Checkout.Hub}${locationParams}`,
            element: <Pages.CheckoutHubPage skin={Skins.CheckoutSkinWithOrder}/>
        },
        {
            name: `${PageRoutes.Checkout.PayLater}${locationParams}`,
            element: <Pages.CheckoutPayLaterPage skin={Skins.CheckoutSkinWithOrder}/>
        },

        // Pay With Option
        {
            name: `${PageRoutes.PayWithOption.Index}${locationParams}`,
            element: <Pages.PayOptionPage skin={Skins.CheckoutSkinWithOrderEmbedded}/>
        },

        // Payment
        {
            name: `${PageRoutes.Payment.Index}${locationParams}`,
            element: <Pages.PaymentPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // International
        {
            name: `${PageRoutes.Payment.International}${locationParams}`,
            element: <Pages.InternationalPage skin={Skins.CheckoutSkinWithOrderEmbedded}/>
        },
        {
            name: `${PageRoutes.Payment.InternationalSetup}${locationParams}`,
            element: <Pages.InternationalSetupPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        {
            name: `${PageRoutes.Payment.InternationalAuthenticate}${locationParams}`,
            element: <Pages.InternationalAuthenticatePage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // Payment > Domestic
        {
            name: `${PageRoutes.Payment.Domestic}${locationParams}`,
            element: <Pages.DomesticPage skin={Skins.CheckoutSkinWithOrder}/>
        },
        {
            name: `${PageRoutes.Payment.DomesticAuthenticate}${locationParams}`,
            element: <Pages.DomesticAuthenticatePage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // QR
        {
            name: `${PageRoutes.Payment.QR}${locationParams}`,
            element: <Pages.QRPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // Hub
        {
            name: `${PageRoutes.Payment.Hub}${locationParams}`,
            element: <Pages.HubPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        // Payment > Hub > Apple Pay
        {
            name: `${PageRoutes.Payment.ApplePay}${locationParams}`,
            element: <Pages.ApplePayPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        
        // Payment > Hub > Samsung Pay
        {
            name: `${PageRoutes.Payment.SamsungPay}${locationParams}`,
            element: <Pages.SamsungPayPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // Payment > Hub > Google Pay
        {
            name: `${PageRoutes.Payment.GooglePay}${locationParams}`,
            element: <Pages.GooglePayPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // Pay Later
        {
            name: `${PageRoutes.Payment.PayLater}${locationParams}`,
            element: <Pages.PayLaterPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },

        // Result
        {
            name: `${PageRoutes.Payment.Result}${locationParams}`,
            element: <Pages.ResultPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        {
            name: `${PageRoutes.Payment.Finish}${locationParams}`,
            element: <Pages.FinishPage skin={Skins.CheckoutSkinWithoutFooter}/>
        },
        {
            name: `${PageRoutes.Payment.Pending}/:transactionID/:language`,
            element: <Pages.PendingPage skin={Skins.EmptySkin}/>
        }
    ]
};

const theme: ITheme = {
    skin: Skins.DefaultSkin,
    container: Containers.DefaultContainer,
    styles: {
        button: {
            variant: VariantOption.Text
        },
        input: {
            variant: VariantOption.Text,
            fullWidth: true,
            disableUnderline: false,
            style: {
                height: "60px",
            },
            valueStyle: {
                fontSize: "14px",
                color: "#000000"
            },
            focusStyle: {
                color: "#6D6E71",
                fontSize: "14px",
                fontWeight: 400,
                borderColor: "#6D6E71"
            },
            hoverStyle: {
                borderColor: "#6D6E71",
                borderWidth: "2px"
            }
        },
        label: {
            errorStyle: {
                color: "#db3131"
            },
            focusStyle: {
                color: "#6D6E71"
            },
            style: {
                fontSize: "14px",
                fontWeight: 500,
            }
        }
    }
};

const store: IStoreProperties = {reducers: {}};
if (store.reducers)
{
    store.reducers[Stores.PreOrder] = new TransactionReducer();
    store.reducers[Stores.Transaction] = new TransactionReducer();
}

const getTargetOrigin = () =>
{
    return (window as any).TargetOrigin ?? "*";
}

const setTargetOrigin = (origin: string) =>
{
    return (window as any).TargetOrigin = origin;
}

const WindowMessageID = "galaxy-pay-upc";

const onStartUp = () =>
{
    window.addEventListener("message", (event: any) =>
    {
        if (Functions.isNullOrWhiteSpace(document.referrer))
        {
            return;
        }
        
        const data = event.data;
        const parentOrigin = new URL(document.referrer).origin;
        if (event.origin !== parentOrigin ||
            data.id !== WindowMessageID)
        {
            return;
        }
        
        setTargetOrigin(data.origin);
    });

    Functions.subscribeEvent(
        StateEvents.StateChanged,
        (state: string, title: string, result: any) =>
        {
            const targetOrigin = getTargetOrigin();
            Functions.logDebug("STATE CHANGED", targetOrigin, state, title, result);

            const message = {
                id: WindowMessageID,
                state: state,
                title: title,
                content: result
            }

            Functions.logDebug("WINDOW POST MESSAGE", message);
            window.parent.postMessage(message, targetOrigin);
        });
}

const UniversalApp: IAppProperties = {
    router: router,
    theme: theme,
    store: store,
    onStartUp: onStartUp
}


export default UniversalApp;