import {Functions, IFormik, Tags} from "Core/Core";
import {IFieldProperties} from "Core/Interfaces/Properties/IProperties";
import {useFormikContext} from "formik";
import {useEffect} from "react";


const CardNumberField = (properties: IFieldProperties) =>
{
    const maxLength = 23;
    const formik = useFormikContext() as IFormik<any>;
    const onKeyDown = Functions.verifyNumericKeys;
    const onInput = (event: any) =>
    {
        const field = (event.target as HTMLInputElement);
        let position = field.selectionEnd ?? 0;
        const length = field.value.length;
        field.value = format(field.value);
        field.selectionEnd =
            position + ((field.value.charAt(position - 1) === ' ' && field.value.charAt(length - 1) === ' ') ? 1 : 0);
    };

    const onBlur = (event: any) =>
    {
        const element = (event.target as HTMLInputElement);
        const value = format(element.value);
        formik.setFieldValue(properties.name, value);
    };

    const format = (value: string) =>
    {
        const result = value
            .replace(/\D/g, "")
            .replace(/\d{4}(?=.)/g, "$& ");

        return Functions.left(result, maxLength);
    };

    const inputProps = {
        inputMode: "numeric"
    }

    let value = undefined;
    if (properties.value)
    {
        value = format(properties.value);
    }
    
    useEffect(() => {
        if (properties.value)
        {
            formik.setFieldValue(properties.name, format(properties.value));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Tags.TextField {...properties}
                        value={value}
                        onBlur={onBlur}
                        onInput={onInput}
                        onKeyDown={onKeyDown}
                        inputProps={inputProps}/>
    );
}


export {CardNumberField};