import {useRef, useState} from "react";
import {
    Functions,
    IPageProperties,
    Pages,
    PaymentController,
    React,
    SourceData,
    Tags,
    TransactionData
} from "Universal/Packages";
import {IGooglePayProperties} from "./GooglePay/IGooglePayProperties";


const global = (window as any);
global.GalaxyPay = global.GalaxyPay || {};

const GooglePayPage = (properties: IPageProperties) =>
{
    const [isLoading, setIsLoading] = useState(true);
    const [isAvailable, setIsAvailable] = useState(false);
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const [googlePayButton, setGooglePayButton] = useState<any>(<Tags.Empty/>);
    const setupIFrame = useRef<any>();
    const getFingerprint = useRef<any>();

    const onQuerySuccess = (_: TransactionData) =>
    {
        if (Functions.isIframe())
        {
            setIsAvailable(true);
        }
        else
        {
            setIsAvailable(false);
        }

        setIsLoading(false);
    };

    const onInitPayment = async (validationURL: string, validationHost: string) =>
    {
        const source = new SourceData();
        source.validationURL = validationURL;
        source.validationHost = validationHost;

        setIsLoading(true);
        return await controller.initPaymentAsync(source).catch();
    };

    const onConfirmPayment = async (paymentToken: any) =>
    {
        setIsLoading(true);
        const dataPayment = JSON.parse(paymentToken);
        const assuranceDetails = dataPayment.paymentMethodData.info.assuranceDetails;
        const accountVerified = assuranceDetails.accountVerified;
        const cardHolderAuthenticated = assuranceDetails.cardHolderAuthenticated;
        return controller.capturePaymentAsync(paymentToken, accountVerified, cardHolderAuthenticated).catch();
    };

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    }

    controller.queryPayment({onSuccess: onQuerySuccess});
    const renderBody = (() =>
    {
        const props: IGooglePayProperties = {
            isGooglePayAvailable: isAvailable,
            controller: controller,
            onInitPayment: onInitPayment,
            onConfirmPayment: onConfirmPayment,
            onCancel: onCancel,
            googlePayButton: googlePayButton,
            setGooglePayButton: setGooglePayButton,
            setIsLoading: setIsLoading,
            store: transaction,
            setupIFrame: setupIFrame,
            getFingerprint: getFingerprint,
        };

        if (Functions.isIframe())
        {
            return <Pages.GooglePayIFramePage {...props}/>;
        }
        else
        {
            return <Pages.GooglePaySelfPage {...props}/>;
        }
    });

    const body = isLoading ? <Tags.Empty/> : renderBody();
    return (
        <>
            <div hidden ref={getFingerprint}/>
            <div hidden ref={setupIFrame}/>
            <controller.Page skin={properties.skin}
                             store={controller.Transaction}
                             controller={controller}
                             isLoading={isLoading}>
                {body}
            </controller.Page>
        </>
    );
}


export {GooglePayPage};