import {AppResources, FieldController, Functions, IFormik, IOption, ISelectProperties, Tags} from "Core/References";

import {useFormikContext} from "formik";
import React, {useEffect, useMemo} from "react";
import {Images} from "Universal/Options/Assets/Images";
import {Material} from "../MaterialPackage";


const DrawerSelectField = (properties: ISelectProperties): React.JSX.Element =>
{
    const translate = Functions.translate();
    const defaultOption: IOption = {value: ""};
    const findOption = (value?: string) =>
    {
        return properties.options?.find(obj =>
        {
            return obj.value === value;
        });
    };

    const defaultValue = findOption(properties.value);
    const [selectedOption, setSelectedOption] = React.useState(defaultValue ?? defaultOption);
    const [isOpen, setIsOpen] = React.useState(false);
    const [query, setQuery] = React.useState("");
    const formikContext = useFormikContext() as IFormik<any>;
    const controller = new FieldController(properties, formikContext);
    const options = useMemo(() => properties.options ?? [], [properties.options]);

    const toggleDrawer = (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
    {
        if (event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        )
        {
            return;
        }
        setIsOpen(state);

        formikContext.touched[properties.name] = true;
        formikContext.validateField(properties.name);
    };

    const icon = selectedOption && Functions.isNullOrWhiteSpace(selectedOption.value) === false && properties.renderValue
        ? <>{properties.renderValue(selectedOption)}</>
        : <Tags.Empty></Tags.Empty>

    const onChange = (event: any, option: any) =>
    {
        formikContext.setFieldValue(properties.name, option?.value ?? "");

        if (properties.onChange)
        {
            properties.onChange(option);
        }

        setSelectedOption(option);
    };

    const onSelectOption = (option: any) =>
    {
        onChange(null, option);
        setQuery("")
        setIsOpen(false);
    };


    const filter = (query: string, data: any) =>
    {
        if (Functions.isNullOrWhiteSpace(query))
        {
            return data;
        }

        const plainQuery = Functions.removeVietnamese(query).toLowerCase();
        return data.filter(function (option: any)
            {
                return option.searchText.includes(plainQuery);
            }
        );
    };

    const filteredOptions = useMemo(() => filter(query, options), [query, options]);
    const isDataNotFound = filteredOptions.length === 0;
    const searchTitle = translate(AppResources.Messages.SearchTitle, {fieldName: properties.name}) ?? "";
    const searchLabel = translate(AppResources.Messages.SearchLabel, {fieldName: properties.name}) ?? "";
    const searchResult = useMemo(() =>
    {
        return isDataNotFound
            ? <span>{translate(AppResources.Messages.DataNotFound)}</span>
            : filteredOptions.map((option: any, index: any) => (
                <div key={index}>
                    <div className={"model-option-item"}>
                        <Material.ListItemButton style={{padding: "8px 0"}}
                                                 selected={selectedOption === option}
                                                 onClick={() => onSelectOption(option)}>
                            {
                                properties.renderOption
                                    ? properties.renderOption(properties, option, {})
                                    : <span className={"model-option-label-text"}>{option.text}</span>
                            }
                        </Material.ListItemButton>
                    </div>
                    <Material.Divider/>
                </div>
            ));
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataNotFound, filteredOptions, selectedOption, translate]);

    const drawer = () => (
        <Tags.Box className="model-save-card-info">
            <div className="model-save-card-info-header"
                 style={{backgroundImage: `url(${Images.Backgrounds.DrawerHeader})`}}>
                <div className="margin-auto">
                    <span className="model-save-card-info-header-title font-jambono">
                        {searchTitle.toUpperCase()}
                    </span>
                </div>
                <div className="model-save-card-info-header-close">
                    <Tags.Button onClick={toggleDrawer(false)}>
                        <Tags.Icons.Close/>
                    </Tags.Button>
                </div>
            </div>
            <div className="model-save-card-info-content-select">
                <Material.TextField
                    placeholder={searchLabel}
                    variant={"outlined"}
                    autoComplete={"off"}
                    autoFocus={true}
                    inputRef={(input) => input?.focus()}
                    InputProps={{
                        startAdornment: <Tags.InputAdornment position="start">
                            <Tags.Image src={Images.Icons.search} style={{height: "20px", marginBottom: "2px"}}/>
                        </Tags.InputAdornment>
                    }}
                    size={"small"}
                    onChange={(e: any) =>
                    {
                        const value = e.target.value || "";
                        setQuery(value);
                    }}
                    value={query}
                />
                <div className={`modal-option-label ${isDataNotFound ? "align-items-center" : ""}`}>
                    {searchResult}
                </div>
            </div>
        </Tags.Box>
    );

    useEffect(() =>
    {
        const selectedValue = formikContext.values[properties.name];
        if (Functions.isNullOrWhiteSpace(selectedValue) === false)
        {
            const currentValue = formikContext.values[properties.name];
            const option = findOption(currentValue);
            if (!option)
            {
                formikContext.setFieldValue(properties.name, "");
                setSelectedOption(defaultOption);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.options])

    return (
        <Tags.Box>
            <Tags.Row spacing={2} className={"model-account-option-item"}>
                <Tags.Column xs={properties.renderValue ? 10 : 12}>
                    <Tags.TextField {...properties} visible={false}/>
                    <Material.TextField
                        name={Functions.getGUID()}
                        variant={Functions.getTextVariant(properties.variant)}
                        required={properties.required}
                        inputProps={{
                            autoComplete: properties.autoComplete === true ? "on" : "off",
                            maxLength: properties.maxLength,
                            readOnly: properties.readonly
                        }}
                        InputProps={{
                            endAdornment: <Tags.InputAdornment position="end">
                                <Tags.IconButton onClick={toggleDrawer(true)}>
                                    <Tags.Icons.ArrowDropDown/>
                                </Tags.IconButton>
                            </Tags.InputAdornment>
                        }}
                        label={controller.Text}
                        placeholder={controller.PlaceHolder}
                        helperText={controller.HelpText}
                        error={controller.IsHasError}
                        onClick={toggleDrawer(true)}
                        value={selectedOption.text ?? ""}/>
                    <Tags.SwipeableDrawer
                        disableSwipeToOpen={true}
                        className={"drawer-select-field"}
                        anchor="bottom"
                        open={isOpen}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                        PaperProps={{sx: {borderRadius: "8px", overflow: "hidden"}}}
                    >
                        {drawer()}
                    </Tags.SwipeableDrawer>
                </Tags.Column>
                <Tags.Column xs={properties.renderValue ? 2 : 0}>
                    {icon}
                </Tags.Column>
            </Tags.Row>
        </Tags.Box>
    );
};


export {DrawerSelectField};
