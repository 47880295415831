import MasterData from "assets/data/MasterData.json";
import {
    Functions,
    ICardData,
    Images,
    IResultMessage,
    PageController,
    React,
    StateEvents,
    Tags
} from "Universal/Packages";


class UPCController extends PageController
{
    public DefaultCardInfo: ICardData = {
        logo: "",
        brandCode: "",
        isHasLength19: false,
        isUseExpireDate: false
    };

    public getDomesticCard = (value: string, paymentSource?: string) =>
    {
        if (Functions.isNullOrWhiteSpace(value))
        {
            return undefined;
        }

        value = value.replace(/\s/g, "");
        if (value.startsWith("9704") === false)
        {
            return undefined;
        }

        const source = paymentSource ?? "";
        let cardBrands = MasterData.Brands["NAPAS"];
        const cardBrand = this.getCardBrand(value, cardBrands);
        if (source)
        {
            return cardBrand && cardBrand.bankCode === source;
        }

        return cardBrand;
    }

    public getInternationalCard = (value: string, paymentSource?: string) =>
    {
        if (Functions.isNullOrWhiteSpace(value))
        {
            return undefined;
        }

        value = value.replace(/\s/g, "");
        const source = paymentSource ?? undefined;
        let cardBrands: any = [];

        if (value.startsWith("4"))
        {
            cardBrands = MasterData.Brands["VISA"];
        }
        else if (value.startsWith("5") || value.startsWith("2"))
        {
            cardBrands = MasterData.Brands["MASTER"];
        }
        else if (value.startsWith("35"))
        {
            cardBrands = MasterData.Brands["JCB"];
        }
        else if (value.startsWith("34") || value.startsWith("37"))
        {
            cardBrands = MasterData.Brands["AMEX"];
        }

        const cardBrand = this.getCardBrand(value, cardBrands);
        if (source &&
            cardBrand &&
            cardBrand.brandCode !== source)
        {
            return undefined;
        }

        return cardBrand;
    }

    public getCardBrand = (cardNumber: any, cardBrands: any) =>
    {
        if (!cardNumber ||
            !cardBrands)
        {
            return undefined;
        }

        return cardBrands.find((cardBrand: any) =>
        {
            const bin = cardBrand.rangeLength
                ? cardNumber.substring(0, cardBrand.rangeLength)
                : cardNumber.substring(0, 6);

            return (
                (cardBrand.isRangeNumber === false && cardBrand.cardBINs.includes(bin)) ||
                (cardBrand.isRangeNumber && bin >= cardBrand.minNumber && bin <= cardBrand.maxNumber)
            )
        })
    }

    public isInvalidDate = (value: string) =>
    {
        if (Functions.isNullOrUndefined(value))
        {
            return true;
        }

        const mm = parseInt(value.substring(0, 2), 10);
        return mm <= 0 || mm > 12;
    }

    public isInvalidDateRange = (value: string, isExpireDate: boolean = true) =>
    {
        if (this.isInvalidDate(value))
        {
            return true;
        }

        const mm = parseInt(value.substring(0, 2), 10);
        const yy = parseInt(value.substring(3), 10);
        const dateCurrent = new Date();
        const yearCurrent = parseInt(
            dateCurrent.getFullYear().toString().substring(2, 4), 10
        );
        const monthCurrent = parseInt(dateCurrent.getMonth().toString(), 10) + 1;
        return isExpireDate === true
            ? (yearCurrent > yy || (yearCurrent === yy && monthCurrent > mm))
            : (yearCurrent < yy || (yearCurrent === yy && monthCurrent < mm));
    }

    public isInvalidFulName = (value: string) =>
    {
        return !/[^A-Za-z0-9\s]/g.test(value);
    }

    public isHasSpecialCharacter = (value: string) =>
    {
        return !/[^A-Za-z0-9]/g.test(value);
    }

    public renderInternationalCard = (cardInfo: ICardData) =>
    {
        if (!cardInfo ||
            Functions.isNullOrWhiteSpace(cardInfo.brandCode))
        {
            return <Tags.Empty/>;
        }

        const src = Functions.format(Images.Brands.InternationalCard, cardInfo.brandCode);
        return (
            <Tags.Image src={src} className={"margin-top-16"}/>
        );
    }

    public renderDomesticCard = (cardInfo: ICardData) =>
    {
        if (!cardInfo ||
            Functions.isNullOrWhiteSpace(cardInfo.brandCode))
        {
            return <Tags.Empty/>;
        }

        const src = Functions.format(Images.Brands.DomesticCard, cardInfo.logo);
        return (
            <Tags.Image src={src} className={"margin-top-16"}/>
        );
    }

    public filterTokens = (tokens: Array<any>, isLocalCard: boolean) =>
    {
        const result = new Array<any>();
        tokens.forEach((token) =>
        {
            const isLocalBIN = Functions.left(token.accountNumber, 4) === "9704";
            if (isLocalCard)
            {
                if (isLocalBIN)
                {
                    result.push(token);
                }
            }
            else if (isLocalBIN === false)
            {
                result.push(token);
            }
        });

        return result;
    }

    public changeState = (currentState: string, title: string, result: IResultMessage) =>
    {
        Functions.publishEvent(StateEvents.StateChanged, currentState, title, result);
    }
}


export {UPCController};