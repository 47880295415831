import {Functions, LanguageOption} from "Core/References";


class RequestData
{
    public requestID: string;
    public requestDateTime: string;
    public requestData: any;

    public language: string;

    public constructor();
    public constructor(requestData?: any)
    public constructor(requestID?: string, requestData?: any);
    public constructor(requestID?: string, requestData?: any, requestLanguage?: string)
    {
        this.requestID = requestID ?? Functions.getGUID();
        this.requestDateTime = Functions.getCurrentDateTime().toString();
        this.requestData = requestData;
        this.language = requestLanguage ?? LanguageOption.English;
    }
}


export {RequestData};