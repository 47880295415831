import React, {useState} from "react";
import {
    Components,
    Functions,
    PageRoutes,
    PaymentController,
    PaymentMethods,
    PayOptionData,
    TransactionData
} from "Universal/Packages";


const PayOptionPage = (properties: any) =>
{
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const [isLoading, setIsLoading] = useState(true);

    const onSuccess = (transaction: TransactionData) =>
    {
        const paymentMethod = transaction.payment.paymentMethod;
        if (Functions.isNullOrWhiteSpace(paymentMethod) === false &&
            Functions.isInArray(
                paymentMethod,
                PaymentMethods.Domestic,
                PaymentMethods.International) === false)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Index);
        }
        else
        {
            setIsLoading(false);
        }
    };

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onSubmit = async (option: PayOptionData) =>
    {
        setIsLoading(true);
        await controller.updatePaymentAsync(option).then(() =>
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Index);
        });
    };

    controller.queryPayment({onSuccess: onSuccess});
    const body =
        <>
            <Components.Timeout store={transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
            <Components.PaymentOptionForm store={transaction} onSubmit={onSubmit}/>;
        </>;

    return (
        <controller.Page
            skin={properties.skin}
            store={transaction}
            controller={controller}
            isLoading={isLoading}
        >
            {body}
        </controller.Page>
    );
}


export {PayOptionPage};
