import {Material} from "../MaterialPackage";


const Typography = (properties: any) =>
{
    return (
        <Material.Typography {...properties}/>
    );
};


export {Typography};