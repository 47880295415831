import {
    AppResources,
    Functions,
    Images,
    PaymentMethods,
    PreOrderData,
    Providers,
    React,
    Tags,
} from "Universal/Packages";


interface IProviderProperties
{
    store?: PreOrderData
}

const getTitle = (paymentMethod: string, paymentSource: string) =>
{
    const translate = Functions.translate();
    switch (paymentMethod)
    {
        case PaymentMethods.Domestic:
            return translate(AppResources.Pages.Payment.Methods.Domestic);

        case PaymentMethods.International:
            return translate(AppResources.Pages.Payment.Methods.International);

        case PaymentMethods.QR:
        case PaymentMethods.QRPay:
        case PaymentMethods.Wallet:
            return translate(AppResources.Pages.Payment.Methods.QR, {providerCode: paymentSource});

        case PaymentMethods.Hub:
            return translate(AppResources.Pages.Payment.Methods.Hub, {providerCode: paymentSource});

        default:
            return "";
    }
};

const getSupportBrands = (preorder: PreOrderData) =>
{
    const images = new Array<React.ReactNode>();
    switch (preorder.payment.paymentMethod)
    {
        case PaymentMethods.Domestic:
            const imageUrl = Functions.format(Images.Logos.Provider, Providers.Napas);
            images.push(<Tags.Image key={Functions.getGUID()} src={imageUrl}/>)
            break;

        case PaymentMethods.International:
            const isEnable = (paymentSource: string, brand: string) =>
            {
                return !paymentSource || paymentSource === brand
                    ? ""
                    : "image-disable";
            };
            const supportBrands = preorder.payment.supportBrands || new Array<string>();
            supportBrands.forEach((brand: string) =>
            {
                const imageUrl = Functions.format(Images.Brands.InternationalCard, brand);
                images.push(<Tags.Image key={Functions.getGUID()}
                                        src={imageUrl}
                                        className={isEnable(preorder.payment.paymentSource, brand)}/>);
            });
            break;

        case PaymentMethods.Wallet:
            const imageUrlWallet = Functions.format(Images.Logos.Provider, preorder.payment.paymentSource);
            images.push(<Tags.Image key={Functions.getGUID()} src={imageUrlWallet} style={{width: "40px"}}/>);
            break;

        case PaymentMethods.QRPay:
            const imageUrlVietQR = Functions.format(Images.Logos.Provider, preorder.payment.paymentSource);
            images.push(<Tags.Image key={Functions.getGUID()} src={imageUrlVietQR} style={{width: "40px"}}/>);
            break;

        case PaymentMethods.Hub:
            const imageUrlHub = Functions.format(Images.Logos.Provider, preorder.payment.paymentSource);
            images.push(<Tags.Image key={Functions.getGUID()} src={imageUrlHub} style={{width: "60px"}}/>);
            break;
    }

    return images;
};

const CheckoutProvider = (properties: IProviderProperties) =>
{
    const translate = Functions.translate();
    const preorder = properties.store;
    if (!preorder)
    {
        return <Tags.Empty/>;
    }

    const title = getTitle(preorder.payment.paymentMethod, preorder.payment.paymentSource)
    const images = getSupportBrands(preorder);

    let className = "";
    let resource = "";
    let guidelineComponent = <></>;

    switch (preorder.payment.paymentMethod)
    {
        case PaymentMethods.Wallet:
        const isMobile = Functions.isMobile();
            resource = isMobile
            ? AppResources.Pages.Checkout.ProviderContentWallet.Mobile
            : AppResources.Pages.Checkout.ProviderContentWallet.Web;

        className = "display-space-between";
        guidelineComponent =
            <span className={"model-order-item margin-top-8"}>
                {translate(resource, {providerCode: preorder.payment.paymentSource})}
            </span>;
            break;
            
        case PaymentMethods.QRPay:
            resource = AppResources.Pages.Checkout.ProviderContentWallet.Web;

            className = "display-space-between";
            guidelineComponent =
                <span className={"model-order-item margin-top-8"}>
                {translate(resource, {providerCode: preorder.payment.paymentMethod})}
            </span>;
            break;
            
        case PaymentMethods.Hub:
            resource = AppResources.Pages.Checkout.ProviderContentAzupay.Web;
        className = "display-space-between";
        guidelineComponent =
            <span className={"model-order-item margin-top-8"}>
                {translate(resource, {providerCode: preorder.payment.paymentMethod})}
            </span>;
            break;
    }

    const isPayOption = Functions.isNullOrWhiteSpace(preorder.payment.paymentMethod);
    const body = isPayOption
        ? <Tags.Empty/>
        : <div className="model-provider">
            <div className={className}>
                <span className="model-provider-content">{title}</span>
                {images}
            </div>
            {guidelineComponent}
        </div>;

    return (
        <>
            {body}
        </>
    );
};


export {CheckoutProvider};