import {Material} from "../MaterialPackage";


const InputAdornment = (properties: any) =>
{
    return (
        <Material.InputAdornment {...properties}/>
    );
}


export {InputAdornment};