import {Material} from "../MaterialPackage";


const RadioField = (properties: any) =>
{
    return (
        <Material.Radio {...properties}/>
    );
}


export {RadioField};