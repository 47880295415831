import {Components, Functions, PageRoutes, React, ResponseCodeOption} from "Universal/Packages";
import {IGooglePayProperties} from "Universal/Pages/Payment/Methods/GooglePay/IGooglePayProperties";
import {useEffect} from "react";
import GooglePayButton from "@google-pay/button-react";

const win: any = (window as any);
win.isGooglePayInitial = false;

const GooglePaySelfPage = (properties: IGooglePayProperties) =>
{
    const controller = properties.controller;
    const onCancel = properties.onCancel;
    const onInitPayment = properties.onInitPayment
    const onConfirmPayment = properties.onConfirmPayment;
    const setIsLoading = properties.setIsLoading;
    const store = properties.store;
    const cancelButton = <Components.SimpleCancelDialog 
        cancelButton={Components.WalletCancelButton} 
        onCancel={onCancel}/>;
    const googlePayButton = properties.googlePayButton;
    const setInitData = properties.setGooglePayButton;
    const setupIFrame = properties.setupIFrame;
    const getFingerprint = properties.getFingerprint;

    const onTimeout = async () => {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };
    
    useEffect(  () =>
    {
        const fetchData = async () =>  {
            const domain = window.location.host;
            const result = await onInitPayment("", domain);

            const fingerprint = result.payment.googleInfo.fingerprint.htmlContent;
            const fragment = document
                .createRange()
                .createContextualFragment(fingerprint);
            getFingerprint!.current.append(fragment);
            
            const initData: any = result.payment.googleInfo.googleInfo;
            let paymentData: any = {
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                    {
                        type: 'CARD',
                        parameters: {
                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                        },
                        tokenizationSpecification: initData.tokenizationSpecification,
                    },
                ],
                merchantInfo: initData.merchantInfo,
                transactionInfo: initData.transactionInfo
            };

            let button = <GooglePayButton
                buttonType="pay"
                buttonSizeMode="fill"
                style={{width: '100%'}}
                environment={Functions.isDevelopment() ? "TEST" : "PRODUCTION"}
                paymentRequest={paymentData}
                onLoadPaymentData={ async (paymentRequest: any) => {
                    await onPaymentAuthorized(paymentRequest);
                }}
            />;
            
            setInitData(button);
            setIsLoading(false);
            win.isGooglePayInitial = true;
        }
        
        if (win.isGooglePayInitial === true) 
        {
            return;
        }
        
        fetchData();
        
    }, []);

    const onPaymentAuthorized = async (paymentData: any) => 
    {
        const paymentToken = JSON.stringify(paymentData);
        await onConfirmPayment(paymentToken).then((transaction) => 
        {
            if (transaction.responseCode === ResponseCodeOption.Created) 
            {
                setIsLoading(true);
                if (!transaction.payment.internationalInfo ||
                    !transaction.payment.internationalInfo.setupInfo) 
                {
                    controller.navigateWithCurrentParams(PageRoutes.Payment.Finish);
                    return;
                }
                
                // Register global function to window
                (window as any).submitCheckEnrollment = () => 
                {
                    controller.checkEnrollmentAsync().then(() => 
                    {
                        controller.navigateWithCurrentParams(PageRoutes.Payment.InternationalAuthenticate);
                    });
                }
                
                const setupForm = transaction.payment.internationalInfo.setupInfo.htmlContent;
                const fragment = document
                    .createRange()
                    .createContextualFragment(setupForm);
                setupIFrame?.current.append(fragment);
                return;
            }
            
            // Go to result page
            setTimeout(() =>
            {
                controller.queryPaymentAsync().then(() =>
                {
                    controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                });
            }, 3000);
        });
    }
    
    const actionButton = (() =>
    {
        return (
            <>
                {googlePayButton}
                {cancelButton}
            </>
        );
    })();
    
    const orderInfo = <Components.WalletOrderInfo store={store} onTimeout={onTimeout}/>

    return (
        <>
            {orderInfo}
            {actionButton}
        </>
    );
}


export {GooglePaySelfPage};