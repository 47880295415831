import {
    BaseService,
    CreatePaymentData,
    CreatePaymentRequest,
    Functions,
    PreOrderData,
    QueryRequest,
    Settings
} from "Universal/Packages";


class CheckoutService extends BaseService
{
    private BaseURL: string = Functions.getServiceURL(Settings.ServiceURL);
    private ServiceURL = this.BaseURL + "/api/v1.0/secure/";
    protected Timeout = Functions.getNumberSetting(Settings.RequestTimeout) * 1000;
    protected get ServiceName(): string {
        return "CheckoutService"
    };


    public cancelCheckout = async (request: QueryRequest) =>
    {
        const url = this.ServiceURL + "CancelCheckout";
        return await this.post<object>(url, request);
    };
    
    public queryCheckout = async (request: QueryRequest) =>
    {
        const url = this.ServiceURL + "QueryCheckout";
        return await this.post<PreOrderData>(url, request);
    };

    public createPaymentAsync = async (request: CreatePaymentRequest) =>
    {
        const url = this.ServiceURL + "CreatePayment";
        return await this.post<CreatePaymentData>(url, request);
    };
}


export {CheckoutService};