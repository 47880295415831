const Image = (properties: any) =>
{
    return (
        <img 
            className={properties.className} 
            style={properties.style}
            src={properties.src} 
            title={properties.title} 
            alt={properties.alt}
        />
    );
};


export {Image};