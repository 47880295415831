import {useEffect, useMemo, useRef, useState} from "react";
import {Components, Functions, TransactionData} from "Universal/Packages";


interface ITimerProperties
{
    store: TransactionData,
    onTimerElapsed?: () => void,
    classTimer?: string,
}

const Timer = (properties: ITimerProperties) =>
{
    const calculateTime = (): number =>
    {
        const currentDateTime: Date = new Date();
        const seconds: number = Math.floor((expireDateTime.getTime() - currentDateTime.getTime()) / 1000);
        return Math.max(seconds, 0);
    }

    const store = properties.store;
    const expireDateTime: Date = useMemo(() =>
    {
        const responseDateTime: Date = 
            store.dateTimeUpdate ? Functions.toDate(store.dateTimeUpdate.toString()) : new Date();
        responseDateTime.setSeconds(responseDateTime.getSeconds() + store.remainSeconds);
        return responseDateTime;
    }, [store]);

    const totalSeconds = calculateTime();
    const timer: any = useRef(undefined);
    const [count, setCount] = useState(totalSeconds - 1);
    const isElapsed = totalSeconds === 0 || count === 0;

    useEffect(() =>
    {
        timer.current = setInterval(() =>
        {
            // Process re-calculate each interval to avoid timer stop when document lost focus
            const remainSeconds = calculateTime();
            setCount(remainSeconds - 1);
        }, 1000);

        return () => clearInterval(timer.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() =>
    {
        if (isElapsed)
        {
            clearInterval(timer.current);
            if (properties.onTimerElapsed)
            {
                properties.onTimerElapsed();
            }
        }
    }, [isElapsed, properties]);

    return (
        <Components.Countdown
            remainSeconds={count}
            totalSeconds={totalSeconds}
            classTimer={properties.classTimer}
        />
    );
};


export {Timer}