import MasterData from "assets/data/MasterData.json";
import {
    AppResources,
    ButtonTypeOption,
    Components,
    DataTypeOption,
    FieldTypeOption,
    Functions,
    IFields,
    Images,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


const loadBanks = () =>
{
    const banks: Array<any> = [];
    Object.values(MasterData.Banks).map(item =>
    {
        if (item.isUnderMaintenance === true)
        {
            return null;
        }

        const text = item.shortName;
        const bank = {
            logo: item.logo,
            code: item.napasBankCode,
            text: text,
            value: item.bankCode,
            searchText: Functions.removeVietnamese(text).toLowerCase()
        }

        banks.push(bank);
        return null;
    });

    return banks;
}

let Banks: Array<any> = [];

const DomesticAccountForm = (properties: any) =>
{
    const store = properties.store;
    Banks = loadBanks();
    const onSubmit = (values: any) =>
    {
        const bank = Banks.find(item => item.value === values.BankCode);

        const source = new SourceData();
        source.sourceOfFund = SourceOfFunds.Account;
        source.bankCode = bank.code;
        source.accountBrand = bank.value;
        source.accountNumber = values.AccountNumber;
        source.accountName = values.AccountName;
        source.identificationNumber = values.IdentificationNumber;

        properties.onSubmit(source);
    }

    let sourceInfo = store.payment.sourceInfo || properties.source || {};
    const AccountFields: IFields = {
        BankCode: {
            name: "BankCode",
            textResource: AppResources.Fields.BankCode,
            placeHolderResource: AppResources.Fields.BankCodePlaceholder,
            required: true,
            readonly: true,
            type: FieldTypeOption.Select,
            dataType: DataTypeOption.Any,
            value: sourceInfo.accountBrand
        },
        AccountNumber: {
            name: "AccountNumber",
            textResource: AppResources.Fields.AccountNumber,
            placeHolderResource: AppResources.Fields.AccountNumberPlaceholder,
            required: true,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo.accountNumber,
            minLength: 5,
            maxLength: 20,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const value = element.value.replace(/\s/g, "");
                element.value = Functions.removeVietnamese(value).toUpperCase();
            }
        },
        AccountName: {
            name: "AccountName",
            textResource: AppResources.Fields.AccountName,
            placeHolderResource: AppResources.Fields.AccountNamePlaceholder,
            required: true,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo.accountName,
            maxLength: 50,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const value = element.value.replace(/  +/g, " ");
                element.value = Functions.removeVietnamese(value).toUpperCase();
            }
        },
        IdentificationNumber: {
            name: "IdentificationNumber",
            textResource: AppResources.Fields.IdentificationNumber,
            placeHolderResource: AppResources.Fields.IdentificationNumberPlaceholder,
            required: true,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo.identificationNumber,
            minLength: 4,
            maxLength: 12,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const value = element.value.replace(/\s/g, "");
                element.value = Functions.removeVietnamese(value).toUpperCase();
            }
        }
    };

    const renderOption = (props: object, option: any) => (
        <>
            <Tags.Image src={Functions.format(Images.Brands.DomesticBank, option.logo)}
                        style={{marginLeft: "5px", marginRight: "5px", height: "24px"}}/>
            <span className={"model-option-label-text"}>{option.text}</span>
        </>
    );

    const renderValue = (option: any) =>
    {
        return <Tags.Image src={Functions.format(Images.Brands.DomesticBank, option.logo)}
                           style={{height: "36px", marginTop: "6px"}}/>;
    };

    const errorMessage =
        sourceInfo.sourceType === SourceOfFunds.Account &&
        store.responseCode === ResponseCodeOption.PaymentSourceNotAvailable
            ? <Components.ErrorMessage message={store.responseMessage}/>
            : <></>;

    return (
        <div className={"model-card-input-cardNumber padding-0"}>
            <Tags.FormikForm fields={AccountFields} onSubmit={onSubmit}>
                <div style={{padding: "16px"}}>
                    <div>
                        <Tags.DrawerSelectField {...AccountFields.BankCode}
                                                options={Banks}
                                                renderOption={renderOption}
                                                renderValue={renderValue}/>
                    </div>
                    <div className="model-text-field-input">
                        <Tags.TextField {...AccountFields.AccountNumber}/>
                    </div>
                    <div className="model-text-field-input">
                        <Tags.TextField {...AccountFields.AccountName}/>
                    </div>
                    <div className="model-text-field-input">
                        <Tags.TextField {...AccountFields.IdentificationNumber}/>
                    </div>
                </div>
                {errorMessage}
                <Tags.Button id="btn-next-create-payment" className={"invisible"} type={ButtonTypeOption.Submit}/>
            </Tags.FormikForm>
        </div>
    );
};


export {DomesticAccountForm};