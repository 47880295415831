import {AppResources, Functions, Images, ResponseCodeOption, Tags} from "Universal/Packages";


interface ITransactionStatus
{
    icon: string,
    color: string,
    message: string,
    errorMessage: string
}

const Colors = {
    success: "#4FA800",
    failure: "#EB2629",
    warning: "#FDB913"
}

const getTransactionResult = (transaction: any): ITransactionStatus =>
{
    let icon = Images.Icons.warning;
    let color = Colors.warning;
    let message;
    let errorMessage;

    const translate = Functions.translate();
    if (transaction.ipn)
    {
        const ipn = transaction.ipn;
        message = translate(AppResources.Messages.ResultCode, {resultCode: ipn.resultCode});

        switch (ipn.transactionStatus)
        {
            case ResponseCodeOption.Success:
                icon = Images.Icons.success;
                color = Colors.success;
                break;

            case ResponseCodeOption.Expired:
            case ResponseCodeOption.ClientCancel:
                icon = Images.Icons.warning;
                color = Colors.warning;
                break;

            default:
                icon = Images.Icons.failure;
                color = Colors.failure;
                break;
        }
    }
    else
    {
        let errorCode = transaction.error?.errorCode || transaction.responseCode || ResponseCodeOption.Unauthorized;
        if (errorCode === ResponseCodeOption.Success)
        {
            errorCode = ResponseCodeOption.BadRequest;
        }

        message = translate(AppResources.Pages.Exception.Message, {errorCode: errorCode});
        errorMessage = transaction.responseMessage;
    }

    return {
        icon: icon,
        color: color,
        message: message,
        errorMessage: errorMessage
    };
};

const ResultForm = (properties: any) =>
{
    const translate = Functions.translate();
    const transaction = properties.store;
    const result: ITransactionStatus = getTransactionResult(transaction);
    const errorMessage = Functions.isNullOrWhiteSpace(result.errorMessage) === false
        ? <Tags.Typography className={"error-message"} color="#D91A21"
                           marginTop="8px">{result.errorMessage}</Tags.Typography>
        : <Tags.Empty/>;

    return (
        <Tags.Box className={"result-container"}>
            <Tags.Card className="card">
                <Tags.Image className="image" src={result.icon}/>
                <Tags.CardContent className="content">
                    <Tags.Typography
                        color={result.color}
                        className={"status"}
                        component="div">
                        {result.message}
                    </Tags.Typography>
                    {errorMessage}
                    <Tags.Typography marginBottom="1.5rem" marginTop="1.5rem">
                        <span
                            className={"text-gray"}>{translate(AppResources.Fields.TransactionID)}: {transaction?.transactionID}</span>
                    </Tags.Typography>
                </Tags.CardContent>
            </Tags.Card>
        </Tags.Box>
    );
};


export {ResultForm};