import {PayOptionData} from "Universal/Models/Payments/PayOptionData";
import {
    BaseRequest,
    EnrollmentData, FailureRequest,
    Functions,
    IPNBusiness,
    LanguageOption,
    PageRoutes,
    PaymentBusiness,
    PaymentChannels,
    Providers,
    SendBackData,
    SourceData,
    Stores,
    TransactionData,
    TransactionStages
} from "Universal/Packages";
import {UPCController} from "../Base/UPCController"


interface IPageLoadEvent
{
    onSuccess: (transaction: TransactionData) => void
}

class PaymentController extends UPCController
{
    public Transaction = this.getStore<TransactionData>(Stores.Transaction);
    private Business = new PaymentBusiness();


    public queryPayment = (event: IPageLoadEvent) =>
    {
        this.onPageLoad(() =>
        {
            this.getPaymentAsync()
                .then(event.onSuccess)
                .catch(() => {});
        });
    };

    public getPaymentAsync = (): Promise<TransactionData> =>
    {
        const request: BaseRequest = this.createRequest();
        return this.Business
            .getPaymentAsync(request, this.Transaction)
            .then(this.onComplete);
    };

    public queryPaymentAsync = (): Promise<TransactionData> =>
    {
        const request: BaseRequest = this.createRequest();
        return this.Business
            .queryPaymentAsync(request)
            .then(this.onComplete);
    };

    public initPaymentAsync = (source: SourceData): Promise<TransactionData> =>
    {
        source.sessionKey = this.Transaction.sessionKey;
        source.paymentMethod = this.Transaction.payment.paymentMethod;

        const request: BaseRequest = this.createRequest();
        return this.Business
            .initPaymentAsync(request, source)
            .then(this.onComplete);
    };

    public updatePaymentAsync = (option: PayOptionData): Promise<TransactionData> =>
    {
        const request: BaseRequest = this.createRequest();
        return this.Business
            .updatePaymentAsync(request, option)
            .then(this.onComplete);
    };

    public checkEnrollmentAsync = (enrollment?: EnrollmentData) =>
    {
        const request: BaseRequest = this.createRequest();
        return this.Business
            .checkEnrollmentAsync(request, enrollment)
            .then(this.onComplete);
    };

    public capturePaymentAsync = async (value: string, accountVerified?: boolean, cardHolderAuthenticated?: boolean) =>
    {
        const request: BaseRequest = this.createRequest();
        const securityCode = value;
        const sessionKey = this.Transaction.sessionKey;

        return this.Business
            .capturePaymentAsync(request, securityCode, sessionKey, accountVerified, cardHolderAuthenticated)
            .then(this.onComplete);
    };


    public processResultAsync = () =>
    {
        // Process for CANCEL or TIMEOUT
        if (this.Transaction.sendBackInfo)
        {
            this.Business.finishTransaction(this.Transaction);
            this.executeScript(this.Transaction.sendBackInfo);
            return;
        }

        // Process for SUCCESS or FAILURE
        const request: BaseRequest = this.createRequest();
        this.Business
            .processResultAsync(request, this.Transaction)
            .then(this.executeScript);
    }

    public processCancelAsync = async () =>
    {
        if (this.Transaction.transactionState === TransactionStages.Finish)
        {
            return;
        }

        const request: BaseRequest = this.createRequest();
        await this.Business
            .processCancelAsync(request, this.Transaction)
            .then(() =>
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Result);
            });
    }

    public processTimeoutAsync = async () =>
    {
        if (this.Transaction.transactionState === TransactionStages.Finish)
        {
            return;
        }

        const request: BaseRequest = this.createRequest();
        await this.Business
            .processTimeoutAsync(request, this.Transaction)
            .then(() =>
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Result);
            });
    }

    public processFailureAsync = async (message: string) =>
    {
        if (this.Transaction.transactionState === TransactionStages.Finish)
        {
            return;
        }

        const request: FailureRequest = this.createRequest();
        request.failureMessage = message;
        
        await this.Business
            .processFailureAsync(request, this.Transaction)
            .then(() =>
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Result);
            });
    }

    public processCloseAsync = async () =>
    {
        if (this.Transaction.ipn)
        {
            return;
        }

        await this.processCancelAsync();
    }


    public registerSocket = (
        transaction: TransactionData,
        onMessageReceive: (...args: any[]) => void) =>
    {
        const ipnBusiness = new IPNBusiness();
        ipnBusiness.registerSocket(
            Functions.getGUID(),
            transaction.transactionID,
            onMessageReceive);
    };


    public getTransactionStore = () =>
    {
        return this.getStore<TransactionData>(Stores.Transaction);
    };

    public setTransactionStore = (store: TransactionData) =>
    {
        return this.overrideStore(Stores.Transaction, store);
    }


    private createRequest = (): BaseRequest =>
    {
        const {transactionID, dateTimeExpire, signature} = this.LocationParams;
        const request: BaseRequest = new BaseRequest();
        request.transactionID = transactionID;
        request.dateTimeExpire = this.Functions.toNumber(dateTimeExpire);
        request.signature = signature;

        // Use for check current state
        request.currentState = this.Transaction?.processingStage || TransactionStages.Init;

        return request;
    };

    private onComplete = (transaction: TransactionData) =>
    {
        // Go to error page when transaction has error.
        if (!transaction ||
            transaction.error)
        {
            if(transaction.merchant)
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Result);
            }
            else
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Finish);
            }
            return Promise.reject<TransactionData>( new Error("Transaction has error."));
        }

        // Set user prefer language
        Functions.changeLanguage(transaction.order.language || LanguageOption.Vietnamese);

        // Go to result page when transaction has been completed or api response error.
        if (transaction.ipn)
        {
            if (this.getCurrentRoute() !== PageRoutes.Payment.Result)
            {
                this.navigateWithCurrentParams(PageRoutes.Payment.Result)
                return Promise.reject<TransactionData>(new Error("Transaction has been completed."));
            }
        }

        return transaction;
    };

    private executeScript = (response?: SendBackData) =>
    {
        const transaction = this.Transaction;
        const isModalCheckout = transaction.merchant.isModalCheckout;
        const isRedirectHub = transaction.payment.providerCode === Providers.Samsung ||
            (transaction.merchant.isMobileApplication &&
            Functions.isInArray(transaction.payment.providerCode, Providers.Apple));
        const isMobileAppToApp = transaction.payment.paymentChannel === PaymentChannels.Mobile &&
            Functions.isInArray(transaction.payment.providerCode, Providers.MoMo, Providers.Zalo);

        if (isModalCheckout === false ||
            isMobileAppToApp === true ||
            isRedirectHub)
        {
            if (response)
            {
                const id = Functions.getGUID();
                const division = document.createElement("div");
                division.id = id;
                division.innerHTML = response.htmlContent || "";
                document.body.appendChild(division);

                const container = document.getElementById(id);
                if (container)
                {
                    const script = container.querySelector("#AutoScript");
                    if (script)
                    {
                        const callbackScript = document.createElement("script");
                        callbackScript.innerHTML = script.innerHTML;
                        document.body.appendChild(callbackScript);
                    }
                }

                return;
            }
        }
    };
}


export {PaymentController};