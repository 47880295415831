import {Images, IPageProperties, PageController, PaymentBusiness, Tags} from "Universal/Packages";


const HomePage = (properties: IPageProperties) =>
{
    const business = new PaymentBusiness();
    const controller = new PageController();

    controller.onPageLoad(() =>
    {
        business.getVersionAsync().then();
    });

    return (
        <controller.Page skin={properties.skin} className={"justify-content-center"}>
            <Tags.Container style={{maxWidth: "600px"}}>
                <Tags.Row>
                    <Tags.Column className="text-align-center">
                        <Tags.Image src={Images.Logos.Logo}/>
                    </Tags.Column>
                    <Tags.Column className="text-align-right text-size-16">
                        Version: 1.0
                    </Tags.Column>
                </Tags.Row>
            </Tags.Container>
        </controller.Page>
    );
};


export {HomePage};