import {useEffect, useRef, useState} from "react";
import {
    Functions,
    Pages,
    PaymentController,
    React,
    Settings,
    SourceData,
    Tags,
    TransactionData
} from "Universal/Packages";
import {ISamsungPayProperties} from "./SamsungPay/ISamsungPayProperties";


const SamsungPayPage = (properties: any) =>
{
    const [isLoading, setIsLoading] = useState(true);
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const SamsungSDK = Functions.getSetting(Settings.SamsungSDK);
    const getFingerprint = useRef<any>();

    // Load Samsung Pay Script
    useEffect(() =>
    {
        if (Functions.isIframe())
        {
            return;
        }
        
        Functions.importScript(SamsungSDK, "sdkSamsungPay");
    }, []);

    const onQuerySuccess = (_: TransactionData) =>
    {
        // Await load SDK Samsung
        Functions.sleep(1).then(() => {
           setIsLoading(false);
        })
    };

    const onInitPayment = async (validationURL: string, validationHost: string) =>
    {
        const source = new SourceData();
        source.validationURL = validationURL;
        source.validationHost = validationHost;

        setIsLoading(true);
        return await controller.initPaymentAsync(source).catch();
    };

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    }

    controller.queryPayment({onSuccess: onQuerySuccess});

    const renderBody = (() =>
    {
        const props: ISamsungPayProperties = {
            controller: controller,
            store: transaction,
            onInitPayment: onInitPayment,
            setIsLoading: setIsLoading,
            onCancel: onCancel,
            getFingerprint: getFingerprint,
        };

        if (Functions.isIframe())
        {
            return <Pages.SamsungPayIFramePage {...props}/>;
        }
        else
        {
            return <Pages.SamsungPaySelfPage {...props}/>;
        }

    });

    const body = isLoading ? <Tags.Empty/> : renderBody();
    return (
        <>
            <div hidden ref={getFingerprint}/>
            <controller.Page skin={properties.skin}
                             store={controller.Transaction}
                             controller={controller}
                             isLoading={isLoading}>
                {body}
            </controller.Page>
        </>
    );
}


export {SamsungPayPage};