const ASCII = {
    arrowKeys: ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"],
    backspaceKey: "Backspace",
    endKey: "End",
    deleteKey: "Delete",
    dotKeys: ".",
    homeKey: "Home",
    commandKeys: ["Control", "Meta", "ContextMenu"],
    hotKeys: ["a", "A", "c", "C", "v", "V", "x", "X"],
    navigatorKeys: ["Backspace", "Tab", "End", "Home", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Delete"],
    numericKeys: [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9"
    ],
    decimalKeys: [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."
    ],
    tabKey: "Tab"
};

const getKey = (event: KeyboardEvent) =>
{
    return event.key;
};

const isFunctionKey = (event: KeyboardEvent, key: string) =>
{
    // Arrow, Backspace, Delete, End, Home, Tab
    if (ASCII.navigatorKeys.includes(key))
    {
        return true;
    }

    // Copy, Cut, Paste, Select All
    if (event.ctrlKey && ASCII.hotKeys.includes(key))
    {
        return true;
    }

    if (event.metaKey && ASCII.hotKeys.includes(key))
    {
        return true;
    }

    // Shift + Tab
    return event.shiftKey && ASCII.tabKey === key
}

const verifyNumericKeys = (event: KeyboardEvent) =>
{
    const key = getKey(event);
    if (isFunctionKey(event, key))
    {
        return;
    }

    if (ASCII.numericKeys.includes(key) && !event.shiftKey)
    {
        return;
    }

    event.preventDefault();
}


export const Events = {
    verifyNumericKeys
};