import {Components, ISkin, React, Tags,} from "Universal/Packages";
import {CheckoutSkin} from "./CheckoutSkin";


const CheckoutSkinWithoutFooter = (properties: ISkin): React.JSX.Element =>
{
    const Header: React.ElementType = properties.header ?? Components.CheckoutHeader;
    const Footer: React.ElementType = Tags.Empty;
    const Body = properties.body;

    return (
        <CheckoutSkin
            className={properties.className}
            header={Header}
            footer={Footer}
            body={Body}
            store={properties.store}
            controller={properties.controller}
            onClose={properties.onClose}
            isLoading={properties.isLoading}/>
    );
};


export {CheckoutSkinWithoutFooter};