import {IPageProperties, PaymentController, SourceData, SourceOfFunds, TransactionData} from "Universal/Packages";

const PayLaterPage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const transaction = controller.Transaction;

    const processPayment = (transaction: TransactionData) =>
    {
        if (transaction.payment.payLaterInfo)
        {
            window.location.href = transaction.payment.payLaterInfo.payUrl;
        }
    };

    const initPayment = (transaction: TransactionData) =>
    {
        const source = new SourceData();
        source.paymentMethod = transaction.payment.paymentMethod;
        source.sourceOfFund = SourceOfFunds.None;

        controller
            .initPaymentAsync(source)
            .then(processPayment);
    }

    controller.queryPayment({onSuccess: initPayment});

    return (
        <controller.Page
            skin={properties.skin}
            store={transaction}
            controller={controller}
            isLoading={true}
        >
        </controller.Page>
    );
};


export {PayLaterPage};