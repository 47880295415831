enum FieldTypeOption
{
    Text = "text",
    Password = "password",
    Select = "select",
    Checkbox = "check",
    Radio = "radio",
    Date = "date"
}


export {FieldTypeOption};