import React, {useState} from "react";
import {
    AppResources,
    Components,
    Functions,
    IPageProperties,
    PageRoutes,
    PaymentController,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags,
    TransactionData
} from "Universal/Packages";


interface ITabProperties
{
    isUseCard: boolean,
    setIsUseCard: React.Dispatch<React.SetStateAction<boolean>>
}

const Tab = (properties: ITabProperties) =>
{
    const translate = Functions.translate();
    const isUseCard = properties.isUseCard;
    const setIsUseCard = properties.setIsUseCard;

    const getClassName = (isActive: boolean) =>
    {
        return isActive
            ? "modal-tab-button-active"
            : "modal-tab-button";
    };

    return (
        <Tags.Box style={{width: "100%"}}>
            <Tags.Row spacing={2}>
                <Tags.Column xs={6} className={"padding-top-12"}>
                    <Tags.Button className={getClassName(isUseCard)}
                                 onClick={() => setIsUseCard(true)}
                    >
                        {translate(AppResources.Pages.Payment.SourceOfFund.Card)}
                    </Tags.Button>
                </Tags.Column>
                <Tags.Column xs={6} className={"padding-top-12"}>
                    <Tags.Button className={getClassName(isUseCard === false)}
                                 onClick={() => setIsUseCard(false)}
                    >
                        {translate(AppResources.Pages.Payment.SourceOfFund.Account)}
                    </Tags.Button>
                </Tags.Column>
            </Tags.Row>
        </Tags.Box>
    );
};

interface IBodyProperties
{
    store: TransactionData,
    controller: PaymentController,
    onSubmit: (source: SourceData) => void,
    onTimeout: () => void
    onCancel: () => void
}

const Body = (properties: IBodyProperties) =>
{
    const store = properties.store;
    const controller = properties.controller;
    const onSubmit = properties.onSubmit;
    const isUseAccount = store.payment.sourceInfo?.sourceType === SourceOfFunds.Account;
    const [isUseCard, setIsUseCard] = React.useState(isUseAccount === false);
    const translate = Functions.translate();

    const cancelButton = (onCancelClick: () => void) =>
    {
        return (
            <Tags.Button className={"modal-button-cancel-domestic"} onClick={onCancelClick}>
                <div className={"modal-text-cancel-domestic"}>
                    <span>{translate(AppResources.Messages.DialogCancel.ActionText)}</span>
                    <Tags.Icons.ArrowForwardIos className={"modal-icon-cancel-domestic"}/>
                </div>
            </Tags.Button>
        );
    };

    const cancelDialog = store.responseCode === ResponseCodeOption.PaymentSourceNotAvailable
        ? <Components.SimpleCancelDialog onCancel={properties.onCancel} cancelButton={cancelButton}/>
        : <Tags.Empty/>;

    const body = isUseCard
        ? <Components.DomesticCardForm onSubmit={onSubmit} store={store} controller={controller}/>
        : <Components.DomesticAccountForm onSubmit={onSubmit} store={store} controller={controller}/>;

    return (
        <>
            <Components.Timeout store={store}
                                onTimeout={properties.onTimeout}
                                isHideCountdown={true}/>
            <Tab isUseCard={isUseCard} setIsUseCard={setIsUseCard}/>
            <div className="full-width-100">
                {body}
                {cancelDialog}
            </div>
        </>
    );
};

const DomesticPage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const [isLoading, setIsLoading] = useState(true);

    const initPayment = async (source: SourceData) =>
    {
        setIsLoading(true);
        await controller
            .initPaymentAsync(source)
            .then(response =>
            {
                if (response.responseCode === ResponseCodeOption.PaymentSourceNotAvailable)
                {
                    setIsLoading(false);
                }
                else if (response.responseCode === ResponseCodeOption.Success)
                {
                    controller.navigateWithCurrentParams(PageRoutes.Payment.DomesticAuthenticate);
                }
                else
                {
                    controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                }
            })
            .catch(() => {});
    };

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    };

    const onSuccess = (transaction: TransactionData) =>
    {
        if (transaction.payment.domesticInfo)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.DomesticAuthenticate);
            return;
        }

        if (transaction.payment.isHostedMerchant)
        {
            const source = new SourceData();
            source.sourceOfFund = SourceOfFunds.None;
            initPayment(source).catch(() => {});
            return;
        }

        setIsLoading(false);
    };

    const isPaymentOption = transaction.payment.isPaymentOption === true;
    const onClose = () =>
    {
        controller.navigateWithCurrentParams(PageRoutes.PayWithOption.Index);
    };

    controller.queryPayment({onSuccess: onSuccess});
    const body = isLoading
        ? <Tags.Empty/>
        : <Body store={transaction}
                controller={controller}
                onSubmit={initPayment}
                onTimeout={onTimeout}
                onCancel={onCancel}/>;

    return (
        <controller.Page
            skin={properties.skin}
            store={transaction}
            controller={controller}
            isLoading={isLoading}
            onClose={isPaymentOption ? onClose : undefined}
        >
            {body}
        </controller.Page>
    );
};


export {DomesticPage};