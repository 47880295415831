import {useState} from "react";
import {
    Components,
    IPageProperties,
    PaymentController,
    Tags,
    TransactionData,
    TransactionStages
} from "Universal/Packages";


const ResultPage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const [isLoading, setIsLoading] = useState(true);

    const onSuccess = (transaction: TransactionData) =>
    {
        setIsLoading(false);
        controller.registerSocket(transaction, onSocketReceive);
    };

    const onSchedule = async () =>
    {
        await controller.getPaymentAsync().catch(() => {});
    };

    const onSendBack = async () =>
    {
        setIsLoading(true);
        await controller.processResultAsync();
    };

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
        setIsLoading(false);
    };

    const onSocketReceive = () =>
    {
        if (transaction.ipn ||
            transaction.sendBackInfo)
        {
            return;
        }

        controller.queryPaymentAsync().catch(() => {});
    };

    controller.queryPayment({onSuccess: onSuccess});

    const body = isLoading
        ? <Tags.Empty/>
        : <Components.ResultInfo store={transaction} onSchedule={onSchedule} onSendBack={onSendBack}/>;

    const timeout =
        transaction.ipn ||
        transaction.sendBackInfo ||
        controller.Functions.isInArray(
            transaction.transactionState,
            TransactionStages.Result, TransactionStages.Finish)
            ? <Tags.Empty/>
            : <Components.Timeout store={transaction} onTimeout={onTimeout}
                                  isHideCountdown={true}/>

    return (
        <controller.Page skin={properties.skin}
                         store={transaction}
                         controller={controller}
                         isLoading={isLoading}>
            {body}
            {timeout}
        </controller.Page>
    );
}


export {ResultPage};