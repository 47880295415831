import {useState} from "react";
import {
    AppResources,
    CheckoutController,
    Components,
    Functions,
    IPageProperties,
    PreOrderData,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


interface IBodyProperties
{
    store: PreOrderData,
    controller: CheckoutController,
    onSubmit: (source: SourceData) => void,
    source?: SourceData
}

interface ITabProperties
{
    isUseCard: boolean,
    setIsUseCard: React.Dispatch<React.SetStateAction<boolean>>
}

const Tab = (properties: ITabProperties) =>
{
    const translate = Functions.translate();
    const isUseCard = properties.isUseCard;
    const setIsUseCard = properties.setIsUseCard;

    const getClassName = (isActive: boolean) =>
    {
        return isActive
            ? "modal-tab-button-active"
            : "modal-tab-button";
    };

    return (
        <Tags.Box style={{width: "100%"}}>
            <Tags.Row spacing={2}>
                <Tags.Column xs={6} className={"padding-top-12"}>
                    <Tags.Button className={getClassName(isUseCard)}
                                 onClick={() => setIsUseCard(true)}
                    >
                        {translate(AppResources.Pages.Payment.SourceOfFund.Card)}
                    </Tags.Button>
                </Tags.Column>
                <Tags.Column xs={6} className={"padding-top-12"}>
                    <Tags.Button className={getClassName(isUseCard === false)}
                                 onClick={() => setIsUseCard(false)}
                    >
                        {translate(AppResources.Pages.Payment.SourceOfFund.Account)}
                    </Tags.Button>
                </Tags.Column>
            </Tags.Row>
        </Tags.Box>
    );
};

const Body = (properties: IBodyProperties) =>
{
    const isActiveAccount = (properties.source && properties.source.sourceOfFund === SourceOfFunds.Account) || false;
    const [isUseCard, setIsUseCard] = React.useState(isActiveAccount === false);
    const store = properties.store;
    const controller = properties.controller;
    const onSubmit = properties.onSubmit;

    const body = isUseCard
        ? <Components.DomesticCardForm onSubmit={onSubmit}
                                       store={store}
                                       controller={controller}
                                       source={properties.source}/>
        : <Components.DomesticAccountForm onSubmit={onSubmit}
                                          store={store}
                                          controller={controller}
                                          source={properties.source}/>;

    return (
        <>
            <Tab isUseCard={isUseCard} setIsUseCard={setIsUseCard}/>
            <div className="full-width-100">
                {body}
            </div>
        </>
    );
};

const CheckoutDomesticPage = (properties: IPageProperties) =>
{
    const controller = new CheckoutController();
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<any>(undefined);
    const [source, SetSource] = useState<SourceData | undefined>(undefined);
    const preorder = controller.PreOrder;

    const onSuccess = (_: PreOrderData) =>
    {
        setIsLoading(false);
    };

    const onSubmit = async (sourceInfo: SourceData) =>
    {
        setIsLoading(true);
        const result = await controller.createPaymentAsync(sourceInfo);
        if (result?.responseCode !== ResponseCodeOption.Success)
        {
            SetSource(sourceInfo);
            setResponse(result);
            setIsLoading(false);
        }
    }

    const onAlertClose = controller.closeOnCreatePaymentFailure;
    controller.queryCheckout({onSuccess: onSuccess});
    const body = isLoading
        ? <Tags.Empty/>
        : <Body store={preorder} controller={controller} source={source} onSubmit={onSubmit}/>;
    const alertDialog = response
        ? <Components.AlertDialog onClose={onAlertClose(response)} message={response.responseMessage}/>
        : <Tags.Empty/>;

    return (
        <controller.Page
            skin={properties.skin}
            store={preorder}
            controller={controller}
            isLoading={isLoading}
        >
            {body}
            {alertDialog}
        </controller.Page>
    );
};


export {CheckoutDomesticPage}