import {useMemo, useState} from "react";
import {
    ApiOperations,
    AppResources,
    ButtonTypeOption,
    Components,
    DataTypeOption,
    FieldTypeOption,
    Functions,
    ICardConsent,
    ICardData,
    IFields,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


const DomesticCardForm = (properties: any) =>
{
    const store = properties.store;
    const controller = properties.controller;
    const translate = Functions.translate();
    const tokens = controller.filterTokens(store.payment.tokens, true);
    const isHasToken = tokens.length > 0;
    const [isUseToken, setIsUseToken] = useState(isHasToken);
    const [isCreateToken, setIsCreateToken] =
        useState(store.payment.apiOperation === ApiOperations.PayWithCreateToken);

    const onSubmit = (values: any) =>
    {
        const source = new SourceData();
        source.apiOperation = isCreateToken ? ApiOperations.PayWithCreateToken : ApiOperations.Pay;
        source.sourceOfFund = isUseToken ? SourceOfFunds.Token : SourceOfFunds.Card;

        if (isUseToken)
        {
            source.token = values.Token;
        }
        else
        {
            source.cardNumber = values.CardNumber.replace(/\D/g, "");
            source.cardHolderName = values.CardHolderName;
            source.cardExpireDate = values.CardExpireDate;
        }

        properties.onSubmit(source);
    };

    let sourceInfo = store.payment.sourceInfo || properties.source;
    let domesticCard: ICardData = controller.DefaultCardInfo;
    let cardNumber = undefined;
    if (sourceInfo &&
        Functions.isNullOrEmpty(sourceInfo.cardNumber) === false
    )
    {
        cardNumber = Functions.decrypt(store.sessionKey, sourceInfo.cardNumber) || sourceInfo.cardNumber;
        domesticCard = controller.getDomesticCard(cardNumber) || controller.DefaultCardInfo;
    }
    else
    {
        sourceInfo = {};
    }

    const [cardInfo, setCardInfo] = useState(domesticCard);
    const cardBrandLogo = controller.renderDomesticCard(cardInfo);
    const isUseExpireDate = cardInfo?.isUseExpireDate;


    const CardFields: IFields = {
        CardNumber: {
            name: "CardNumber",
            textResource: AppResources.Fields.CardNumber,
            placeHolderResource: AppResources.Fields.CardNumberPlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: cardNumber,
            minLength: 16,
            maxLength: 23,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const cardBrand = controller.getDomesticCard(element.value);
                setCardInfo(cardBrand || controller.DefaultCardInfo);
            },
            validator: (value) =>
            {
                if (isUseToken)
                {
                    return true;
                }

                if (Functions.isInvalidCard(value))
                {
                    return false;
                }

                return controller.getDomesticCard(value);
            },
        },
        CardHolderName: {
            name: "CardHolderName",
            textResource: AppResources.Fields.CardHolderName,
            placeHolderResource: AppResources.Fields.CardHolderNamePlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo?.cardHolderName,
            maxLength: 26,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const value = element.value
                    .replace(/\d+/g, "")
                    .replace(/  +/g, " ");
                element.value = Functions.removeVietnamese(value).toUpperCase();
            }
        },
        CardExpireDate: {
            name: "CardExpireDate",
            label: isUseExpireDate ? "CardExpireDate" : "CardIssueDate",
            textResource: isUseExpireDate ? AppResources.Fields.CardExpireDate : AppResources.Fields.CardIssueDate,
            placeHolderResource: AppResources.Fields.CardExpireDatePlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo.cardExpireDate,
            minLength: 5,
            maxLength: 5,
            inputProps: {
                inputMode: "numeric"
            },
            language: store.order.language,
            validator: (value) =>
            {
                if (isUseToken)
                {
                    return true;
                }

                return controller.isInvalidDate(value) === false;
            }
        },
        Token: {
            name: "Token",
            text: "",
            value: isUseToken ? tokens[0].tokenID : "",
            required: false
        }
    };

    const errorMessage =
        sourceInfo.sourceType === SourceOfFunds.Card &&
        store.responseCode === ResponseCodeOption.PaymentSourceNotAvailable
            ? <Components.ErrorMessage message={store.responseMessage}/>
            : <></>;

    const cardConsent = useMemo(() =>
    {
        const identification = store.merchant.customerIdentification;
        if (Functions.isNullOrWhiteSpace(identification))
        {
            return <Tags.Empty/>;
        }

        const resources: ICardConsent =
            translate(`Messages.CardConsent.${identification}`, {returnObjects: true});

        return store.customer.isLogin
            ? <Components.CardConsent resources={resources} setIsCreateToken={setIsCreateToken} isCreateToken={isCreateToken}/>
            : <span
                className={"modal-message-required-login"}>{resources.Requirement}</span>;
    }, [isCreateToken, store.customer.isLogin, store.merchant.customerIdentification, translate]);

    return (
        <>
            <Tags.FormikForm fields={CardFields} onSubmit={onSubmit}>
                <Components.TokenForm store={store} tokens={tokens} setIsUseToken={setIsUseToken} isLocalCard={true}/>
                <div className={`model-card-input-cardNumber padding-0 ${isUseToken ? "invisible" : ""}`}>
                    <div className={"padding-12"}>
                        <Tags.Box>
                            <Tags.Row spacing={2}>
                                <Tags.Column xs={9}>
                                    <Tags.CardNumberField {...CardFields.CardNumber}/>
                                </Tags.Column>
                                <Tags.Column xs={3}>
                                    {cardBrandLogo}
                                </Tags.Column>
                            </Tags.Row>
                        </Tags.Box>
                        <div className="model-text-field-input">
                            <Tags.TextField {...CardFields.CardHolderName}/>
                        </div>
                        <div className="model-text-field-input">
                            <Tags.DateTextField {...CardFields.CardExpireDate}/>
                        </div>
                        {cardConsent}
                    </div>
                    {errorMessage}
                </div>
                <Tags.Button id="btn-next-create-payment" className={"invisible"} type={ButtonTypeOption.Submit}/>
            </Tags.FormikForm>
        </>
    );
}


export {DomesticCardForm};