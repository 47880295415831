import {Functions, ModelData, StoreActionOption} from "Core/References";


interface IReducer<T>
{
    get InitialState(): T,

    get SetState(): (action: string, currentState: T, newState: T) => T
}

interface IActionMap<T extends ModelData>
{
    [action: string]: (currentState: T, newState: T) => T
}

class BaseReducer<T extends ModelData> implements IReducer<T>
{
    protected initialState: T;
    protected actionMap: IActionMap<T> = {};

    public constructor(initialState?: T)
    {
        this.initialState = initialState ?? new ModelData() as T;

        this.registerAction(StoreActionOption.Clean, this.clean);
        this.registerAction(StoreActionOption.Overwrite, this.overwrite);
        this.registerAction(StoreActionOption.Reset, this.reset);
        this.registerAction(StoreActionOption.Update, this.update);
    }

    public get InitialState(): T { return this.initialState; };

    public get SetState()
    {
        return (action: string, currentState: T, newState: T): T =>
        {
            const method = this.actionMap[action];
            if (method)
            {
                return method(currentState, newState);
            }

            return currentState;
        }
    }

    public registerAction = (action: string, method: (currentState: T, newState: T) => T): void =>
    {
        this.actionMap[action] = method;
    };

    protected clean = (): T =>
    {
        return new ModelData() as T;
    };

    protected overwrite = (currentState: T, newState: T): T =>
    {
        return {...newState};
    };

    protected reset = (): T =>
    {
        return this.InitialState;
    };

    protected update = (currentState: T, newState: T): T =>
    {
        return Functions.merge(currentState, newState) as T;
    };
}


export type {IReducer};
export {BaseReducer};