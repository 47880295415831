import {Material} from "Core/Components/Themes/Material/MaterialPackage";
import {Functions, ITheme, VariantOption} from "Core/References";

import React from "react";


interface IAppTheme
{
    theme: ITheme,
    children?: React.ReactNode,
}

const AppTheme = (properties: IAppTheme) =>
{
    const appTheme = properties.theme;
    const buttonStyle = appTheme.styles?.button!;
    const inputStyle = appTheme.styles?.input!;
    const labelStyle = appTheme.styles?.label!;

    const options: Material.ThemeOptions = {
        palette: {},
        components: {
            MuiButton: {
                defaultProps: {
                    variant: getButtonVariant(buttonStyle.variant),
                    fullWidth: buttonStyle.fullWidth,
                    style: {
                        cursor: "pointer"
                    }
                },
                styleOverrides: {
                    root: {
                        ...buttonStyle.style
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {}
                }
            },
            MuiAutocomplete: {
                defaultProps: {
                    fullWidth: inputStyle.fullWidth,
                }
            },
            MuiSelect: {
                defaultProps: {
                    fullWidth: inputStyle.fullWidth,
                    disableUnderline: inputStyle.disableUnderline
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: getTextVariant(inputStyle.variant),
                    fullWidth: inputStyle.fullWidth,
                    style: {
                        ...inputStyle.style
                    }
                }
            },
            MuiInput: {
                defaultProps: {
                    disableUnderline: inputStyle.disableUnderline
                },
                styleOverrides: {
                    root: {
                        ".css-15e8ec1-MuiInputBase-input-MuiInput-input:invalid": {
                            ...inputStyle.focusStyle,
                        },
                        ".css-15e8ec1-MuiInputBase-input-MuiInput-input": {
                            ...inputStyle.valueStyle,
                        },
                    },
                    underline: {
                        ':after': {
                            ...inputStyle.focusStyle,
                        },
                    },
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-notchedOutline": {
                            ...inputStyle.style,
                        },
                        ":hover .MuiOutlinedInput-notchedOutline": {
                            ...inputStyle.hoverStyle,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            ...inputStyle.focusStyle,
                        },
                        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                            ...inputStyle.errorStyle,
                        },
                    },
                }
            },
            MuiFilledInput: {
                defaultProps: {
                    disableUnderline: inputStyle.disableUnderline
                },
                styleOverrides: {
                    root: {
                        ...inputStyle.style,
                        "&:hover": {
                            ...inputStyle.hoverStyle
                        },
                        "&.Mui-focused": {
                            ...inputStyle.focusStyle
                        },
                        "&.Mui-error": {
                            ...inputStyle.errorStyle
                        },
                        "& .MuiInputBase-input": {
                            paddingLeft: "10px",
                        },
                    },
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        ...labelStyle.style,
                        "&.Mui-focused": {
                            ...labelStyle.focusStyle
                        },
                        "&.Mui-error": {
                            ...labelStyle.errorStyle
                        }
                    },
                    asterisk: {
                        ...labelStyle.style,
                        "&.Mui-focused": {
                            ...labelStyle.focusStyle
                        },
                        "&.Mui-error": {
                            ...labelStyle.errorStyle
                        },
                        color: "#EC2029"
                    }
                }
            }
        }
    };

    const theme = Material.createTheme(options);
    return (
        <Material.ThemeProvider theme={theme} children={properties.children}/>
    );
}

const getButtonVariant = (variant: VariantOption | undefined): "outlined" | "contained" | "text" | undefined =>
{
    return Functions.getButtonVariant(variant);
};

const getTextVariant = (variant: VariantOption | undefined): any =>
{
    return Functions.getTextVariant(variant);
}


export {AppTheme};