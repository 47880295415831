import {
    AppResources,
    Components,
    Functions,
    React,
    StateEvents,
    Tags,
    TransactionData,
    TransactionStages,
} from "Universal/Packages";

interface IController
{
    processCloseAsync: () => Promise<void>
}

declare global
{
    interface Window
    {
        callbackOnCloseModal(): void;
    }
}

interface IHeaderProperties
{
    store: TransactionData,
    controller: IController
    onClose?: () => void
}

const GetTitle = (transaction?: TransactionData) =>
{
    const translate = Functions.translate();
    const currentState = transaction?.processingStage;

    if (Functions.isNullOrWhiteSpace(currentState))
    {
        return translate(AppResources.Pages.Checkout.Header.Title);
    }

    return translate(AppResources.Messages.StageTitle, {stage: currentState})
};

const ButtonBack = (properties: any) =>
{
    return (
        <div className="model-button-back">
            <Tags.Button onClick={properties.onClick}>
                <Tags.Icons.ArrowBackIosNewOutlined style={{color: "white"}}/>
            </Tags.Button>
        </div>
    );
};

const ButtonClose = (properties: any) =>
{
    return (
        <div className="model-button-cancel">
            <Tags.Button onClick={properties.onClick}>
                <Tags.Icons.Close style={{color: "white"}}/>
            </Tags.Button>
        </div>
    );
}

const CheckoutHeader = (properties: IHeaderProperties) =>
{
    const store = properties.store;
    const title = GetTitle(store);
    const onClose = async () =>
    {
        if (properties.onClose)
        {
            properties.onClose();
        }

        // Post event message
        const currentState = TransactionStages.Close;
        Functions.publishEvent(StateEvents.StateChanged, currentState, title, {});
    };

    const closeButton = (onClick: () => void) =>
    {
        const isMobile = Functions.isMobile();
        const isRender =
            properties.onClose ||
            (store?.merchant?.isModalCheckout
                && !store.processingStage);

        return isRender
            ? isMobile ? <ButtonBack onClick={onClick}/> : <ButtonClose onClick={onClick}/>
            : <Tags.Empty/>;
    };

    const isComplete = properties?.store?.ipn;
    const buttonClose = isComplete
        ? <Tags.Empty/>
        : <Components.SimpleCancelDialog onCancel={onClose} cancelButton={closeButton}/>

    return (
        <div className="model-header">
            <span className="model-header-title">{title.toUpperCase()}</span>
            {buttonClose}
        </div>
    );
};

export {CheckoutHeader};