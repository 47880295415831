import {useState} from "react";
import {
    CheckoutController,
    Components,
    IPageProperties,
    PreOrderData,
    React,
    ResponseCodeOption,
    SourceData,
    Tags
} from "Universal/Packages";


const CheckoutInternationalPage = (properties: IPageProperties) =>
{
    const controller = new CheckoutController();
    const [isLoading, setIsLoading] = useState(true);
    const [response, setResponse] = useState<any>(undefined);
    const [sourceInfo, SetSourceInfo] = useState<SourceData | undefined>(undefined);
    const preorder = controller.PreOrder;

    const onSuccess = (_: PreOrderData) =>
    {
        setIsLoading(false);
    };

    const onSubmit = async (source: SourceData) =>
    {
        setIsLoading(true);
        const result = await controller.createPaymentAsync(source);
        if (result?.responseCode !== ResponseCodeOption.Success)
        {
            SetSourceInfo(source);
            setResponse(result);
            setIsLoading(false);
        }
    }

    const onAlertClose = controller.closeOnCreatePaymentFailure;
    controller.queryCheckout({onSuccess: onSuccess});
    const body = isLoading
        ? <Tags.Empty/>
        : <Components.InternationalCardForm store={preorder}
                                            controller={controller}
                                            onSubmit={onSubmit}
                                            source={sourceInfo}/>
    const alertDialog = response
        ? <Components.AlertDialog onClose={onAlertClose(response)} message={response.responseMessage}/>
        : <Tags.Empty/>;

    return (
        <controller.Page
            skin={properties.skin}
            footer={Tags.Empty}
            store={preorder}
            controller={controller}
            isLoading={isLoading}
        >
            {body}
            {alertDialog}
        </controller.Page>
    );
};


export {CheckoutInternationalPage};