import {AppResources, Functions, IInvoiceData, Images, PreOrderData, React, Tags,} from "Universal/Packages";


interface IOrderProperties
{
    store?: PreOrderData,
    order?: IInvoiceData,
    isShowSecure?: boolean
}

const CheckoutOrder = (properties: IOrderProperties) =>
{
    const preorder = properties.store;
    if (!preorder)
    {
        return <Tags.Empty/>;
    }

    const order = properties.order ?? preorder.order;
    const translate = Functions.translate();
    const secureInfo = properties.isShowSecure === false
        ? <Tags.Empty/>
        : <div className="model-footer-secure">
            <Tags.Image src={Images.Icons.secure} style={{width: "24px", height: "24px"}}/>
            <span className="model-footer-secure-content">
                        {translate(AppResources.Pages.Checkout.Footer.Secure)}
                </span>
        </div>;

    return (
        <div className={"modal-order-secure"}>
            <div className="model-order">
                <div>
                <span>
                    <b>{translate(AppResources.Pages.Checkout.Order.Title)}</b>
                </span>
                    <div className="model-order-item margin-top-12">
                        <span>{translate(AppResources.Pages.Checkout.Fields.OrderNetAmount)}</span>
                        <span className="model-order-amount">
                        {Functions.formatCurrency(order.orderNetAmount, order.orderCurrency)}
                    </span>
                    </div>
                    <div className="model-order-item">
                        <span>{translate(AppResources.Pages.Checkout.Fields.OrderDiscountAmount)}</span>
                        <span className="model-order-amount-promotion">
                        {Functions.formatCurrency(order.orderDiscountAmount, order.orderCurrency)}
                    </span>
                    </div>
                    <div className="model-order-item">
                        <span>{translate(AppResources.Pages.Checkout.Fields.OrderFeeAmount)}</span>
                        <span className="model-order-amount">
                        {Functions.formatCurrency(order.orderFeeAmount, order.orderCurrency)}
                    </span>
                    </div>
                    <div className="model-order-line"></div>
                    <div className="model-order-total-amount">
                        <span>{translate(AppResources.Pages.Checkout.Fields.OrderAmount)}</span>
                        <span className="model-order-amount">
                        {Functions.formatCurrency(order.orderAmount, order.orderCurrency)}
                    </span>
                    </div>
                </div>
            </div>
            {secureInfo}
        </div>
    );
};

export {CheckoutOrder};