import * as React from "react";
import {useEffect} from "react";
import {
    AppResources,
    CheckoutController,
    Components,
    Functions,
    IPageProperties,
    LanguageOption,
    Tags,
    TransactionStages
} from "Universal/Packages";


const CheckoutFinishPage = (properties: IPageProperties) =>
{
    const controller = new CheckoutController();
    const store = controller.PreOrder;
    const translate = Functions.translate();

    const onClose = async () =>
    {
        await controller.processCloseAsync();
    };

    useEffect(() =>
    {
        const currentState: string = TransactionStages.Result;
        const title = translate(AppResources.Messages.StageTitle, {stage: currentState})
        const result: any = {
            providerCode: store.payment?.providerCode,
            language: store.order?.language ?? LanguageOption.English
        };
        controller.changeState(currentState, title, result);

        // eslint-disable-next-line
    }, []);

    const closeButton = window.top
        ? <Tags.Button
            className="modal-dialog-button-close margin-t-20"
            autoFocus
            onClick={onClose}
            textResource={AppResources.Actions.SendBack}
        />
        : <Tags.Empty/>;

    return (
        <controller.Page skin={properties.skin}
                         controller={controller}
                         store={store}
                         isLoading={false}>
            <Components.ResultForm store={store}/>
            {closeButton}
        </controller.Page>
    );
}


export {CheckoutFinishPage};