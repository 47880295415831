import {
    AppResources,
    Components,
    Functions,
    LanguageOption,
    PageRoutes,
    React,
    ResultCodes,
    Tags
} from "Universal/Packages";
import {IApplePayProperties} from "Universal/Pages/Payment/Methods/ApplePay/IApplePayProperties";

const ApplePaySelfPage = (properties: IApplePayProperties) =>
{
    const browser: any = window.self;
    const applePaySession = browser.ApplePaySession;
    const isAvailable = properties.isApplePayAvailable;
    const request = properties.applePayRequest;
    const controller = properties.controller;
    const onValidateMerchant = properties.onValidateMerchant;
    const onConfirmPayment = properties.onConfirmPayment;
    const onCancel = properties.onCancel;
    const onApplePayCancel = properties.onApplePayCancel;
    const setIsLoading = properties.setIsLoading;
    const store = properties.store;
    const translate = Functions.translate();
    const cancelButton = <Components.SimpleCancelDialog 
        cancelButton={Components.WalletCancelButton} 
        onCancel={onCancel}/>
    const getFingerprint = properties.getFingerprint;

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onPayClick = () =>
    {
        let session: any;
        try
        {
            session = new applePaySession(3, request);

            session.onvalidatemerchant = async (event: any) =>
            {
                const domain = window.location.host;
                const result = await onValidateMerchant(event.validationURL, domain);

                const fingerprint = result.payment.appleInfo.fingerprint.htmlContent;
                const fragment = document
                    .createRange()
                    .createContextualFragment(fingerprint);
                getFingerprint!.current.append(fragment);  
                
                const merchantSession = result.payment.appleInfo.appleInfo;
                session.completeMerchantValidation(merchantSession);
            };

            session.onpaymentauthorized = async (event: any) =>
            {
                const payment = event.payment;
                const paymentToken = JSON.stringify(payment);
                const result = await onConfirmPayment(paymentToken);
                const resultCode = result.payment.captureInfo?.resultCode ?? ResultCodes.Error;
                const isSuccess = resultCode === ResultCodes.Success;
                if (isSuccess === false)
                {
                    session.abort();
                }
                else
                {
                    const status = isSuccess === true ? "0" : "1";
                    const errors = isSuccess
                        ? undefined
                        : [{
                            code: "unknown"
                        }];

                    const result = {
                        "status": status,
                        "errors": errors
                    };
                    session.completePayment(result);
                }

                // Go to result page
                setTimeout(() =>
                {
                    controller.queryPaymentAsync().then(() =>
                    {
                        controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                    });
                }, 3000);
            };

            session.oncancel = async (_: any) =>
            {
                if (onApplePayCancel)
                {
                    onApplePayCancel(_);
                }

                setIsLoading(false);
            }

            setIsLoading(true);
            session.begin();
        }
        catch (exception: any)
        {
            Functions.logError(exception);
            setIsLoading(true);
            
            // New thread to avoid lost request
            setTimeout(() => {
                controller.processFailureAsync(exception.message);
            }, 1);
            
            if (session)
            {
                session.abort();
            }
        }
    }

    const applePayButton = (() =>
    {
        // China validation
        const isAvailableForChina = () =>
        {
            let offset = new Date().getTimezoneOffset();
            let language = navigator.language.toLowerCase().substring(0, 2);
            const isChina = (offset / 60) === -8 || language === "zh";
            if (isChina === false)
            {
                return true;
            }

            let version = 0;
            const agent = navigator.userAgent;
            const start = agent.indexOf("OS");
            if ((agent.indexOf("iPhone") > -1 || agent.indexOf("iPad") > -1) && start > -1)
            {
                version = window.Number(agent.substring(start + 3, 3).replace("_", "."));
            }

            return version >= 11.2;
        }

        if (isAvailable)
        {
            const locale = store?.order.language === LanguageOption.Vietnamese
                ? "vi-VN"
                : "en-US";

            const appleButton = React.createElement("apple-pay-button", {
                type: "pay",
                buttonstyle: "black",
                locale: locale,
                lang: locale
            });

            return (
                <>
                    <div onClick={onPayClick} className={"apple-pay-container"}>
                        <div className={"apple-pay-fake-button"}></div>
                        {appleButton}
                    </div>
                    {cancelButton}
                </>
            );
        }
        else
        {
            return (
                <div className={"modal-align-center-button-apple"}>
                    <p>{translate(AppResources.Pages.Payment.ApplePayUnavailable)}</p>
                    {cancelButton}
                </div>
            );
        }
    })();

    const orderInfo = isAvailable
        ? <Components.WalletOrderInfo store={store} onTimeout={onTimeout}/>
        : <Tags.Empty/>

    return (
        <>
            {orderInfo}
            {applePayButton}
        </>
    );
}


export {ApplePaySelfPage};