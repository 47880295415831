import {AppResources, Functions, Images, IPageProperties, PageController, React, Tags} from "Universal/Packages";


interface IErrorPageProperties extends IPageProperties
{
    errorCode?: string
}

interface IErrors
{
    [key: string]: string;
}

const ErrorPage = (properties: IErrorPageProperties) =>
{
    const translate = Functions.translate();
    const controller = new PageController();

    const errorCode = properties.errorCode ?? "404";
    const errors: IErrors = AppResources.Errors;

    return (
        <controller.Page skin={properties.skin}>
            <Tags.Container className={"error-container"}>
                <Tags.Row>
                    <Tags.Column className="text-align-center">
                        <Tags.Image src={Images.Errors.PageNotFound}/>
                    </Tags.Column>
                    <Tags.Column>
                        <div className={"error-code"}>{errorCode}</div>
                        <div className={"error-name"}>
                            {translate(errors[`HTTP_${errorCode}_Name`])}
                        </div>
                        <div className={"error-message"}>
                            {translate(errors[`HTTP_${errorCode}_Message`])}
                        </div>
                    </Tags.Column>
                </Tags.Row>
            </Tags.Container>
        </controller.Page>
    );
};


export {ErrorPage};