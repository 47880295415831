import {Functions} from "Core/Functions/Functions";
import React from "react";


interface IOTPFormProperties
{
    length: number;
    onSubmit: (otp: string) => any;
    disabled?: boolean;

    className?: string;
    style?: React.CSSProperties;
    inputClassName?: string;
    inputStyle?: React.CSSProperties;
}

const OTPFormComponent = (properties: IOTPFormProperties) =>
{
    const {
        length,
        disabled,
        onSubmit,
        ...rest
    } = properties;

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [otpValues, setOTPValues] = React.useState(new Array<string>(length).fill(""));

    const getValue = React.useCallback(
        (value: string) =>
        {
            return !value || /\d/.test(value) ? value : '';
        },
        []
    );

    const updateSelectedIndex = React.useCallback(
        (index: number) =>
        {
            index = Math.max(Math.min(length - 1, index), 0);
            setSelectedIndex(index);
        },
        [length]
    );

    const updateValue = React.useCallback(
        (value: string) =>
        {
            const values = [...otpValues];
            values[selectedIndex] = (value[0] || '').replace(/\D/g, "");
            const otpValue = values.join('');
            setOTPValues(values);

            if (otpValue.length === length)
            {
                onSubmit(otpValue);
            }
        },
        [selectedIndex, length, onSubmit, otpValues]
    );

    const onFocus = React.useCallback(
        (index: number) => () =>
        {
            updateSelectedIndex(index);
        },
        [updateSelectedIndex]
    );

    const movePrevious = React.useCallback(() =>
    {
        updateSelectedIndex(selectedIndex - 1);
    }, [selectedIndex, updateSelectedIndex]);

    const moveNext = React.useCallback(() =>
    {
        updateSelectedIndex(selectedIndex + 1);
    }, [selectedIndex, updateSelectedIndex]);


    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
        {
            const value = getValue(e.currentTarget.value);
            if (!value)
            {
                e.preventDefault();
                return;
            }

            updateValue(value);
            moveNext();
        },
        [updateValue, moveNext, getValue]
    );

    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) =>
        {
            switch (e.key)
            {
                case "Backspace":
                case "Delete":
                {
                    e.preventDefault();
                    if (otpValues[selectedIndex])
                    {
                        let values = [...otpValues];
                        values.splice(selectedIndex, 1);
                        values = Object.assign(Array<string>(6).fill(""), values);

                        setOTPValues(values);
                    }
                    movePrevious();
                    break;
                }
                case "ArrowLeft":
                {
                    e.preventDefault();
                    movePrevious();
                    break;
                }
                case "ArrowRight":
                case "Tab":
                {
                    e.preventDefault();
                    const value = otpValues[selectedIndex] || "";
                    if (value !== "")
                    {
                        moveNext();
                    }
                    break;
                }
                case ' ':
                {
                    e.preventDefault();
                    break;
                }
                default:
                    if (e.key === otpValues[selectedIndex])
                    {
                        e.preventDefault();
                        moveNext();
                    }
                    break;
            }
        },
        [selectedIndex, moveNext, movePrevious, otpValues]
    );

    const onPaste = React.useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) =>
        {
            e.preventDefault();
            const data = e.clipboardData
                .getData('text/plain')
                .trim()
                .slice(0, length - selectedIndex)
                .split('');

            if (data)
            {
                let nextFocusIndex = 0;
                const values = [...otpValues];
                values.forEach((val, index) =>
                {
                    if (index >= selectedIndex)
                    {
                        const changedValue = getValue(data.shift() ?? val);
                        if (changedValue)
                        {
                            values[index] = changedValue;
                            nextFocusIndex = index;
                        }
                    }
                });
                setOTPValues(values);
                setSelectedIndex(Math.min(nextFocusIndex + 1, length - 1));
            }
        },
        [selectedIndex, getValue, length, otpValues]
    );


    const getActiveClass = (index: number) =>
    {
        return selectedIndex === index || Functions.isNullOrEmpty(otpValues[index]) === false
            ? "modal-input-pin-active"
            : "";
    };

    return (
        <div {...rest}>
            {otpValues
                .map((_, index) => (
                    <SingleOTPInput
                        key={`SingleInput-${index}`}
                        focus={selectedIndex === index}
                        value={otpValues && otpValues[index]}
                        onFocus={onFocus(index)}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onPaste={onPaste}
                        type={"text"}
                        className={`modal-input-pin ${getActiveClass(index)}`}
                        disabled={properties.disabled}
                    />
                ))}
        </div>
    );
}


interface SingleOTPInputProperties
    extends React.InputHTMLAttributes<HTMLInputElement>
{
    focus?: boolean;
}

const SingleOTPInputComponent = (properties: SingleOTPInputProperties) =>
{
    const {focus, ...rest} = properties;
    const inputRef = React.useRef<HTMLInputElement>(null);
    const prevFocus = usePrevious(!!focus);

    React.useLayoutEffect(() =>
    {
        if (inputRef.current)
        {
            if (focus)
            {
                inputRef.current.focus();
            }
            if (focus && focus !== prevFocus)
            {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }
    }, [focus, prevFocus]);

    return <input type="text" ref={inputRef} {...rest} maxLength={1} inputMode={"numeric"} />;
}

function usePrevious<T>(value?: T)
{
    const ref = React.useRef<T>();

    // Store current value in ref
    React.useEffect(() =>
    {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const SingleOTPInput = React.memo(SingleOTPInputComponent);
const OTPForm = React.memo(OTPFormComponent);
export {OTPForm};