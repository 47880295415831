import React, {useRef, useState} from "react";
import {
    Components,
    IPageProperties,
    PageRoutes,
    PaymentController,
    SourceData,
    SourceOfFunds,
    Tags,
    TransactionData
} from "Universal/Packages";


const InternationalPage = (properties: IPageProperties) =>
{
    const setupIFrame = useRef<any>();
    const controller = new PaymentController();
    const [isLoading, setIsLoading] = useState(true);
    const [transaction, setTransaction] = useState<TransactionData>(controller.Transaction);

    const initPayment = async (source: SourceData) =>
    {
        setIsLoading(true);
        const transaction = await controller.initPaymentAsync(source);
        setupPayment(transaction);
    };

    const setupPayment = (transaction: TransactionData) =>
    {
        if (!transaction.payment.internationalInfo ||
            !transaction.payment.internationalInfo.setupInfo)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Finish);
            return;
        }

        // Register global function to window
        (window as any).submitCheckEnrollment = () =>
        {
            controller.checkEnrollmentAsync().then(() =>
            {
                controller.navigateWithCurrentParams(PageRoutes.Payment.InternationalAuthenticate);
            });
        }

        const setupForm = transaction.payment.internationalInfo.setupInfo.htmlContent;
        const fragment = document
            .createRange()
            .createContextualFragment(setupForm);
        setupIFrame.current.append(fragment);
    }

    const onSuccess = (transaction: TransactionData) =>
    {
        if (transaction.payment.isHostedMerchant)
        {
            const source = new SourceData();
            source.sourceOfFund = SourceOfFunds.None;
            initPayment(source).then();
            return;
        }

        setTransaction(transaction)
        setIsLoading(false);
    };

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const isPaymentOption = transaction.payment.isPaymentOption === true;
    const onClose = () =>
    {
        controller.navigateWithCurrentParams(PageRoutes.PayWithOption.Index);
    };

    controller.queryPayment({onSuccess: onSuccess});
    const body = isLoading
        ? <Tags.Empty/>
        : <Components.InternationalCardForm store={transaction} controller={controller} onSubmit={initPayment}/>;

    return (
        <>
            <div hidden ref={setupIFrame}/>
            <controller.Page
                skin={properties.skin}
                footer={Tags.Empty}
                store={transaction}
                controller={controller}
                isLoading={isLoading}
                onClose={isPaymentOption ? onClose : undefined}
            >
                <Components.Timeout store={transaction} 
                                    onTimeout={onTimeout} 
                                    isHideCountdown={true}/>
                {body}
            </controller.Page>
        </>
    );
};

export {InternationalPage};