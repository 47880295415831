import {Functions, ISocket, ISocketData, Settings, SocketBusiness} from "Universal/Packages";


class IPNBusiness
{
    public registerSocket = (
        requestID: string,
        transactionID: string,
        onMessageReceive: (...args: any[]) => void) =>
    {
        const onSocketConnected = () => socket.send("register", requestID, transactionID);

        const socketData: ISocketData = {
            socketURL: Functions.getServiceURL(Settings.ServiceURL) + "/Hubs/DataHub",
            onStart: onSocketConnected,
            onReconnect: onSocketConnected,
            onMessageReceive: {
                sync: (transactionID: string) =>
                {
                    onMessageReceive(transactionID);
                }
            }
        };
        
        const socket: ISocket = new SocketBusiness().register(socketData);
    };
}


export {IPNBusiness};