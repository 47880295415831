class PatternOption
{
    // yyyyMMdd
    public static Date: string = "yyyyMMdd";
    
    // yyyyMMddHHmmss
    public static DateTime: string = "yyyyMMddHHmmss";
    
    // yyMMdd
    public static ShortDate: string = "yyMMdd";
    
    // dd/MM/yyyy HH:mm:ss
    public static DateTimeDisplay: string = "dd/MM/yyyy HH:mm:ss";
    
    // dd/MM/yyyy HH:mm:ss.fff zzz
    public static DateTimeLog: string = "dd/MM/yyyy HH:mm:ss.fff zzz";
}

export {PatternOption};