import {Functions, ModelData} from "Core/Core";
import {SendBackData} from "Universal/Packages";


class OrderData
{
    public requestID!: string;
    public rawRequestID!: string;
    public traceParent!: string;

    public orderID!: string;
    public orderNumber!: string;
    public orderNetAmount!: number;
    public orderFeeAmount!: number;
    public orderDiscountAmount!: number;
    public orderAmount!: number;
    public orderOriginalAmount!: string;
    public orderCurrency!: string;
    public orderDateTime!: string;
    public orderDescription!: string;
    public language!: string;
}

class MerchantData
{
    public merchantID!: string;
    public merchantName!: string;
    public customerIdentification!: string;
    public logoURL!: string;
    public scriptURL!: string;
    public styleURL!: string;
    public isModalCheckout!: boolean;
    public isMobileApplication!: boolean;
}

class CustomerData
{
    public customerID?: string;
    public isLogin!: boolean;
}

class PaymentData
{
    public apiOperation!: string;

    public providerCode!: string;
    public providerLogo?: string;

    public paymentMethod!: string;
    public paymentSource!: string;
    public paymentChannel!: string;

    public isHostedMerchant!: boolean;

    public isUseQR!: boolean;
    public isUseMobileQR!: boolean;
    public isSupportDeepLink!: boolean;
    public isSupportRedirectLink!: boolean;
    public isSupportDownloadQR!: boolean;
    public isPaymentOption!: boolean;

    public supportBrands!: string[];

    public sourceInfo?: SourceOfFundData;

    public qrInfo?: QRData;
    public hubInfo?: HubData;
    public appleInfo?: any;
    public samsungInfo?: any;
    public googleInfo?: any;
    public payLaterInfo?: PayLaterData;
    public domesticInfo?: DomesticData;
    public internationalInfo?: InternationalData;
    public captureInfo?: CaptureData;
    public tokens!: Array<TokenData>;
}

class TokenData
{
    public providerCode!: string;
    public tokenID!: string;
    public token!: string;
    public accountBrand!: string;
    public accountSource!: string;
    public accountType!: string;
    public accountNumber!: string;
    public accountName!: string;
}

class SourceOfFundData
{
    public sourceType!: string;

    public cardNumber?: string;
    public cardHolderName?: string;
    public cardExpireDate?: string;
    public cardVerificationValue?: string;

    public bankCode?: string;
    public accountBrand?: string;
    public accountNumber?: string;
    public accountName?: string;
    public identificationNumber?: string;
}

class QRData
{
    public payUrl?: string;
    public deeplink?: string;
    public qrCodeData?: string;

    public isPayWithPasscode!: boolean;
    public resultCode!: string;
    public messageCode!: string;
}

class HubData
{
    public payUrl!: string;
    public contentType!: string;
    public htmlContent!: string;
    public bodyContent!: string;
    public messageContent!: string;
    public isOrderInfo!: boolean;
    public isCancel!: boolean;
}

class PayLaterData
{
    public payUrl!: string;
}

class DomesticData
{
    public htmlContent!: string;
}

class InternationalData
{
    public setupInfo?: SetupData;
    public checkEnrollmentInfo?: CheckEnrollmentData;
}

class SetupData
{
    public referenceID!: string;
    public htmlContent!: string;
}

class CheckEnrollmentData
{
    public isRequireAuthentication!: boolean;
    public isAuthorized!: boolean;
    public htmlContent!: string;
}

class CaptureData
{
    public status!: string;
    public message!: string;
    public retryNumber!: string;

    public isTokenInvalid!: boolean;
    public resultCode!: string;
    public messageCode!: string;
}

class PaymentMethodData
{
    public paymentMethod!: string;
    public isSupportRedirectLink!: boolean;
    public providerCode!: string;
    public isUseQR!: boolean;
    public isUseMobileQR!: boolean;
    public supportBrands!: Array<string>;
    public groupCode!: string;
    public groupContent!: string;
    public groupImage!: string;
}

class IPNData
{
    public resultCode!: string;
    public transactionStatus!: string;
    public transactionDescription!: string;
    public data!: string;
    public signature!: string;
}

interface ErrorData
{
    errorCode: string | undefined;
    errorDescription?: string;
}


class TransactionData extends ModelData
{
    public error?: ErrorData;
    public responseCode?: string;
    public responseMessage?: string;

    public transactionID!: string;
    public transactionState!: string;
    public transactionStep!: string;

    public processingStage!: string;
    public sessionKey!: string;
    public dataKey!: string;

    public order!: OrderData;
    public merchant!: MerchantData;
    public customer!: CustomerData;
    public payment!: PaymentData;
    public paymentMethods!: Array<PaymentMethodData>;

    public ipn?: IPNData;
    public dateTimeExpire!: number;
    public dateTimeUpdate!: number;
    public dateTimeSync!: number;
    public remainSeconds!: number;

    public sendBackInfo?: SendBackData;

    public constructor(transactionID?: string)
    {
        super();
        this.transactionID = Functions.escape(transactionID);
    }
}


export {TransactionData, IPNData, PaymentMethodData};