import {Functions} from "Core/References";
import * as React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";


interface IRouteProperties
{
    id?: string,
    name: string,
    element?: React.JSX.Element,
    routes?: Array<IRouteProperties>
}

interface IRouterProperties
{
    routes: Array<IRouteProperties>,
    default: string,
    fallback?: string
}


const renderRoutes = (routes: Array<IRouteProperties>): Array<React.JSX.Element> =>
{
    const names = new Array<string>();
    const elements = new Array<React.JSX.Element>();

    for (const route of routes)
    {
        if (names.includes(route.name))
        {
            // Skip Duplicated Routes
            continue;
        }

        let element: React.JSX.Element;
        if (Array.isArray(route.routes))
        {
            const children = renderRoutes(route.routes);
            if (route.element)
            {
                // Index Route
                const indexRoute = <Route key={Functions.getGUID()} index={true} element={route.element}/>;
                children.push(indexRoute);
            }

            // Nested Routes
            element = <Route key={Functions.getGUID()} path={route.name} children={children}/>;
        }
        else
        {
            // Single Routes
            element = <Route key={Functions.getGUID()} path={route.name} element={route.element}/>;
        }

        names.push(route.name);
        elements.push(element);
    }

    return elements;
};

const renderRoute = (path: string, element: React.JSX.Element): React.JSX.Element =>
{
    return <Route path={path} element={element}/>;
};

const getRouteElement = (routes: Array<IRouteProperties>, name: string): React.JSX.Element =>
{
    for (const route of routes)
    {
        if (route.name === name &&
            Array.isArray(route.element) === false)
        {
            return route.element as React.JSX.Element;
        }
    }

    return <></>;
};

const AppRouter = (properties: IRouterProperties): React.JSX.Element =>
{
    const fallbackName = properties.fallback ?? properties.default;
    const routes = renderRoutes(properties.routes);
    const defaultRoute = renderRoute("/", getRouteElement(properties.routes, properties.default));
    const fallbackRoute = renderRoute("*", getRouteElement(properties.routes, fallbackName));

    return (
        <BrowserRouter>
            <Routes>
                {routes}
                {defaultRoute}
                {fallbackRoute}
            </Routes>
        </BrowserRouter>
    );
};


export type {
    IRouteProperties,
    IRouterProperties
};
export {AppRouter};