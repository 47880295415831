import {ISkin, React, Tags} from "Universal/Packages";
import {DefaultSkin} from "./DefaultSkin";


const NoHeaderSkin = (properties: ISkin): JSX.Element =>
{
    const header = Tags.Empty;
    const footer = properties.footer;

    return (
        <DefaultSkin
            className={properties.className}
            isLoading={properties.isLoading}
            header={header}
            footer={footer}
            body={properties.body}/>
    );
};


export {NoHeaderSkin};