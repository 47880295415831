import {
    AppController,
    AppRouter,
    AppTheme,
    Functions,
    IRouterProperties,
    IStoreProperties,
    ITheme,
    LoadingScreen,
    InternetScreen,
    ReduxStore,
    SplashScreen,
    Theme
} from "Core/References";

import React from "react";
import ReactDOM from "react-dom/client";


interface IAppProperties
{
    theme?: ITheme,
    router: IRouterProperties,
    store: IStoreProperties,
    onStartUp?: () => void
}

let GlobalTheme: ITheme;
let ThemeContext: React.Context<ITheme>;

class App
{
    public static start = (properties: IAppProperties): void =>
    {
        this.init(properties);
        this.render(properties);
    };

    private static init = (properties: IAppProperties): void =>
    {
        Functions.logInformation(`Environment = ${Functions.getEnvironmentName()}`);
        new AppController().onStart(properties);
    };

    private static render = (properties: IAppProperties): void =>
    {
        GlobalTheme = Functions.merge(Theme, properties.theme);
        ThemeContext = React.createContext<ITheme>(GlobalTheme);

        const root = ReactDOM.createRoot(
            document.getElementById("root") as HTMLElement
        );

        root.render(
            <SplashScreen>
                <ReduxStore {...properties.store}>
                    <AppTheme theme={GlobalTheme}>
                        <ThemeContext.Provider value={GlobalTheme}>
                            <LoadingScreen/>
                            <InternetScreen/>
                            <div id={"app-page"}>
                                <AppRouter {...properties.router}/>
                            </div>
                        </ThemeContext.Provider>
                    </AppTheme>
                </ReduxStore>
            </SplashScreen>
        );
    };
}


export {GlobalTheme, ThemeContext};
export type {IAppProperties};
export {App};