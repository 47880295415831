import {IContainerProperties} from "Core/Core";
import {Material} from "../MaterialPackage";


const Card = (properties: IContainerProperties) =>
{
    return (
        <Material.Card
            id={properties.id}
            className={properties.className}
        >
            {properties.children}
        </Material.Card>
    );
}


export {Card};