class OrderData
{
    public language!: string;
    public transactionID!: string;

    public orderID!: string;
    public orderNumber!: string;
    public orderDateTime!: string;
    public orderDescription!: string;

    public orderCurrency!: string;
    public orderOriginalAmount!: string;

    public orderNetAmount!: number;
    public orderFeeAmount!: number;
    public orderDiscountAmount!: number;
    public orderAmount!: number;
}

class MerchantData
{
    public merchantID!: string;
    public merchantName!: string;
    public customerIdentification!: string;
    public logoURL!: string;
    public scriptURL!: string;
    public styleURL!: string;
    public isModalCheckout!: boolean;
    public isMobileApplication!: boolean;
    
    public failureURL!: string;
}

class CustomerData
{
    public customerID?: string;
    public isLogin!: boolean;
}

class PaymentData
{
    public paymentMethod!: string;
    public paymentSource!: string;
    public providerCode!: string;
    public dateTimeExpire!: string;
    public supportBrands!: Array<string>;
    public tokens!: Array<TokenData>;
    public serviceFees?: Array<ServiceFeeData>;
    
    public isPaymentOption!: boolean;
}

class TokenData
{
    public providerCode!: string;
    public tokenID!: string;
    public token!: string;
    public accountBrand!: string;
    public accountSource!: string;
    public accountType!: string;
    public accountNumber!: string;
    public accountName!: string;
    
    public logo?: string;
}

class ServiceFeeData
{
    public serviceCode!: string;
    public accountBrand!: string;
    public feeAmount!: number;
}

class TransactionData
{
    public endpoint!: string;
}

class PreOrderData
{
    public transactionID!: string;
    public dateTimeUpdate!: number;
    public dateTimeSync!: number;

    public responseCode?: string;
    public responseMessage?: string;

    public sessionKey!: string;
    public dataKey!: string;

    public order!: OrderData;
    public merchant!: MerchantData;
    public customer!: CustomerData;
    public payment!: PaymentData;

    public transaction?: TransactionData;
}


export {PreOrderData};