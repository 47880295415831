import {useEffect, useMemo, useRef, useState} from "react";
import {createRoot} from "react-dom/client";
import {AppResources, Components, Functions, ISkin, Merchants, PreOrderData, React, Tags,} from "Universal/Packages";


interface ILoading
{
    merchant?: any
}

let LoadingComponent: JSX.Element | undefined = undefined;
let MerchantComponent: any = undefined;

const Loading = (properties: ILoading) =>
{
    const merchant = properties.merchant;
    const translate = Functions.translate();
    const container = useRef<any>(null);
    const [isUseMerchantStyle, SetIsUseMerchantStyle] = useState(false);

    useEffect(() =>
    {
        if (MerchantComponent)
        {
            return;
        }

        if (merchant)
        {
            let isApplyMerchantStyle = false;
            if (merchant.styleURL)
            {
                isApplyMerchantStyle = true;
                Functions.importStyle(Merchants.Root + "/" + merchant.styleURL, merchant.styleURL);
            }

            if (merchant.scriptURL)
            {
                isApplyMerchantStyle = true;
                Functions.importScript(Merchants.Root + "/" + merchant.scriptURL, merchant.scriptURL);
            }

            if (isApplyMerchantStyle)
            {
                SetIsUseMerchantStyle(true);
            }
            else
            {
                const loading = <Tags.ImageLoading/>;
                MerchantComponent = loading;
                
                const root = createRoot(container.current);
                root.render(loading);
            }
        }
    }, [merchant]);

    useEffect(() =>
    {
        if (isUseMerchantStyle === false)
        {
            return;
        }

        const div = container.current;
        const renderLoading = () =>
        {
            // @ts-ignore
            if (window.renderMerchantLoading)
            {
                // @ts-ignore
                const loading = window.renderMerchantLoading(Merchants.Root);
                div.innerHTML = "";
                div.append(loading);

                MerchantComponent = <div dangerouslySetInnerHTML={{__html: loading.outerHTML}}/>;
            }
            else
            {
                setTimeout(renderLoading, 100);
            }
        }

        // @ts-ignore
        if (window.renderMerchantLoading)
        {
            renderLoading();
        }
        else
        {
            setTimeout(() =>
            {
                renderLoading();
            }, 100)
        }
    }, [isUseMerchantStyle]);

    return (
        <Tags.Container className={"loading-screen"}>
            <div ref={container} style={{marginTop: "-4%"}}>
                {MerchantComponent || <></>}
            </div>
            <div className="loading-message">
                {translate(AppResources.Messages.Processing)}
            </div>
        </Tags.Container>
    );
}

const CheckoutSkin = (properties: ISkin): React.JSX.Element =>
{
    const Footer: React.ElementType = properties.footer ?? Components.CheckoutFooter;
    const Header: React.ElementType = properties.header ?? Components.CheckoutHeader;

    const store = properties.store as PreOrderData;
    const merchant = store?.merchant;
    const iframeClass: string = Functions.isIframe() ? "checkout-modal" : "checkout";
    const className = properties.className ?? "";

    const loading = useMemo(() =>
    {
        if (LoadingComponent)
        {
            return LoadingComponent;
        }
        
        if (!merchant)
        {
            return <Tags.ImageLoading/>;
        }

        LoadingComponent = <Loading merchant={merchant}/>;
        return LoadingComponent;
    }, [merchant]);

    const isLoading = properties.isLoading ?? false;
    const body = isLoading ? loading : properties.body;

    return (
        <>
            <div className={`model-controller ${iframeClass}`}>
                <Header store={properties.store} onClose={properties.onClose}/>
                <div className={`model-body ${className}`}>
                    {body}
                </div>
                <Footer store={properties.store}/>
            </div>
        </>
    );
};


export {CheckoutSkin};