const Providers = {
    Napas: "NAPAS",
    Cybersource: "CYBS",
    MasterCard: "MPGS",
    MoMo: "MOMO",
    Zalo: "ZALOPAY",
    Viettel: "VIETTEL",
    GalaxyPay: "GALAXYPAY",
    Alipay: "ALIPAY",
    QRPay: "QRPAY",
    Hub2c2p: "2C2P",
    HubPoli: "POLI",
    Kredivo: "KREDIVO",
    Apple: "APPLE",
    Samsung: "SAMSUNG",
    Google: "GOOGLE",
    Azupay: "AZUPAY"
}


export {Providers};