import {Settings} from "../Enum/Settings";


const AssetFolder = process.env[Settings.AssetURL];
const ImageFolder = `${AssetFolder}/images`;

const Backgrounds = {
    QRPage: `${ImageFolder}/backgrounds/background-qr.png`,
    CardPage: `${ImageFolder}/backgrounds/background-card.png`,
    DrawerHeader: `${ImageFolder}/backgrounds/header_select_field.png`
};

const Brands = {
    InternationalCard: `${ImageFolder}/logos/brands/{0}.png`,
    DomesticCard: `${ImageFolder}/logos/banks/{0}`,
    DomesticBank: `${ImageFolder}/logos/banks/{0}`
}

const Errors = {
    PageNotFound: `${ImageFolder}/errors/404.png`,
}

const Icons = {
    exclamationMark: `${ImageFolder}/icons/exclamation_mark.png`,
    success: `${ImageFolder}/icons/success.png`,
    failure: `${ImageFolder}/icons/failure.png`,
    warning: `${ImageFolder}/icons/warning.png`,
    website: `${ImageFolder}/icons/website.png`,
    secure: `${ImageFolder}/icons/secure.png`,
    search: `${ImageFolder}/icons/search.png`,
    info: `${ImageFolder}/icons/info.png`,
    alertTriangle: `${ImageFolder}/icons/alert_triangle.png`
};

const Logos = {
    Logo: "/assets/images/logo.png",
    Provider: `${ImageFolder}/logos/providers/{0}.png`,
    AppSupport: {
        VietjetAir: `${ImageFolder}/logos/app_support/VietjetAir.png`,
        GalaxyPay: `${ImageFolder}/logos/app_support/GalaxyPay.png`,
        SkyJoy: `${ImageFolder}/logos/app_support/SkyJoy.png`,
    },
    PayOptions: `${ImageFolder}/logos/options`
};

const Buttons = {
    SamsungPay: `${ImageFolder}/buttons/SamsungPay.png`
};

const Misc = {
    pci: `${ImageFolder}/misc/pci.png`,
    certificate: `${ImageFolder}/misc/certificate.png`,
};

export const Images = {
    Backgrounds,
    Brands,
    Errors,
    Icons,
    Logos,
    Buttons,
    Misc
};