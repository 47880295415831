import {AppResources, FieldController, FieldTypeOption, Functions, IFieldProperties, IFormik} from "Core/References";

import {useFormikContext} from "formik";
import React from "react";
import {Material} from "../MaterialPackage";


const TextField = (properties: IFieldProperties) =>
{
    const translate = Functions.translate();
    const formik = useFormikContext() as IFormik<any>;
    const controller = new FieldController(properties, formik);
    const elementProps = {
        autoComplete: properties.autoComplete === true ? "on" : "off",
        maxLength: properties.maxLength,
        readOnly: properties.readonly,
        onInput: properties.onInput,
        onKeyUp: properties.onKeyUp,
        onKeyDown: properties.onKeyDown,
        onKeyPress: properties.onKeyPress,
        ...properties.inputProps
    };
    const validityProps = controller.getCustomValidity(translate(AppResources.Validations.InputValidity));
    const inputProps = controller.Functions.merge(elementProps, validityProps);
    const type = properties.type === FieldTypeOption.Password ? FieldTypeOption.Password : FieldTypeOption.Text;

    const onChange = (event: object) =>
    {
        if (properties.onChange)
        {
            properties.onChange(event);
        }

        formik.handleChange(event);
    };

    const onBlur = (event: object) =>
    {
        if (properties.onBlur)
        {
            properties.onBlur(event);
        }

        formik.handleBlur(event);
    };

    const className = `${properties.className} ${properties.visible === false ? "invisible" : ""}`;
    return (
        <Material.TextField
            id={controller.Id}
            name={properties.name}
            value={controller.Value}
            onFocus={properties.onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onClick={properties.onClick}
            type={type}
            variant={Functions.getTextVariant(properties.variant)}
            className={className}
            style={properties.style}
            required={properties.required}
            label={controller.Text}
            placeholder={controller.PlaceHolder}
            helperText={controller.HelpText}
            error={controller.IsHasError}
            inputProps={inputProps}
            InputProps={{endAdornment: properties.endIcon}}
            inputMode={properties.inputMode}
        />
    );
}


export {TextField};