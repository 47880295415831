import {AppResources, Functions, Images, Tags} from "Universal/Packages";


const Header = (properties: any) =>
{
    const translate = Functions.translate();

    return (
        <div id={properties.id}>
            <div className="header-message">
                <Tags.Image src={Images.Icons.exclamationMark}/>
                <div className="header-title">
                    {translate(AppResources.Pages.Payment.SessionWarning)}
                </div>
            </div>
        </div>
    )
};


export {Header};