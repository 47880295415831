import {useState} from "react";
import {
    AppResources,
    ButtonTypeOption,
    Components,
    Functions,
    PaymentMethodData,
    PayOptionData,
    React,
    Tags
} from "Universal/Packages";


const PaymentOptionForm = (properties: any) =>
{
    const translate = Functions.translate();
    const title = translate(AppResources.Messages.PaymentMethods);
    const transaction = properties.store;
    const paymentMethods = transaction.paymentMethods;
    const defaultMethod: PaymentMethodData = paymentMethods[0];
    const [selectedMethod, setSelectedMethod] = useState(defaultMethod);
    const onSubmit = () =>
    {
        const method = selectedMethod;
        const option = new PayOptionData();
        option.providerCode = method.providerCode;
        option.paymentMethod = method.paymentMethod;
        option.supportBrands = method.supportBrands;
        option.isUseQR = method.isUseQR;
        option.isUseMobileQR = method.isUseMobileQR;
        option.isSupportRedirectLink = method.isSupportRedirectLink;
        properties.onSubmit(selectedMethod);
    };

    return (
        <div className={"full-width-100 margin-top-8"}>
            <Tags.FormikForm onSubmit={onSubmit}>
                <Components.CheckoutOrder store={transaction} isShowSecure={false}/>
                <div className="model-provider">
                    <span className="model-provider-content">{title}</span>
                </div>
                <Components.PaymentOptionList
                    paymentMethods={paymentMethods}
                    setPaymentMethod={setSelectedMethod}/>
                <Tags.Button id="btn-next-create-payment" className={"invisible"} type={ButtonTypeOption.Submit}/>
            </Tags.FormikForm>
        </div>
    );
}


export {PaymentOptionForm};