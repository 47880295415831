import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

import {Functions, RequestData, ResponseCodeOption, ResponseData} from "Core/References";
import {Settings} from "Universal/Options/Enum/Settings";


interface IHeader
{
    [field: string]: any
}

class BaseService
{
    private static RequestToken: string | undefined = undefined;
    private static CookieToken: string | undefined = undefined;
    private static TokenURL: string = Functions.getServiceURL(Settings.ServiceURL) + "/antiforgery/token";

    protected Headers: IHeader = {};
    protected Timeout: number = 30 * 1000;
    protected RequestID: string;
    protected TraceParent: string;

    public constructor(requestID?: string, traceParent?: string)
    {
        this.RequestID = requestID || Functions.getGUID();
        this.TraceParent = traceParent || "";

        this.setHeader("traceparent", this.TraceParent);
    }

    protected get ServiceName(): string
    {
        return "BaseService"
    };

    public get = async <T>(url: string): Promise<T | undefined> =>
    {
        Functions.logInformation(
            this.RequestID,
            `Request to ${this.ServiceName}`,
            `GET ${url}`);

        const config = this.getRequestConfig();
        return await axios
            .get<T>(url, config)
            .then(this.onSuccess<T>)
            .catch(this.onError);
    }

    public post = async <T>(url: string, content: object): Promise<ResponseData<T>> =>
    {
        const request = this.createRequest(content);
        Functions.logInformation(
            this.RequestID,
            `Request to ${this.ServiceName}`,
            `POST ${url}`,
            request);

        await this.refreshAntiforgeryToken();
        const config = this.getRequestConfig();
        return await axios
            .post<ResponseData<T>>(url, request, config)
            .then(this.onSuccess<ResponseData<T>>)
            .catch(this.onRequestError<T>);
    }


    protected createRequest = (requestData?: object): RequestData =>
    {
        return new RequestData(this.RequestID, requestData);
    };

    protected getRequestConfig = (): AxiosRequestConfig =>
    {
        return {
            headers: this.Headers,
            timeout: this.Timeout,
            withCredentials: true
        };
    }


    protected onSuccess = <T>(result: AxiosResponse<T, any>): T =>
    {
        const response: any = result.data;
        Functions.logInformation(
            this.RequestID,
            `Response from ${this.ServiceName}`,
            `HTTPCode=${result.status}, ResponseCode=${response.responseCode}`,
            response);

        return response;
    }

    protected onError = (reason: any): undefined =>
    {
        Functions.logError(this.RequestID, `Request Error: ${reason.code}-${reason.message}`, reason);
        return undefined;
    }

    protected onRequestError = <T>(reason: any): ResponseData<T> =>
    {
        this.onError(reason);

        const response = new ResponseData<T>();
        response.requestID = this.RequestID;
        response.responseMessage = reason.message;

        if (reason.response)
        {
            response.responseCode = reason.response.status
        }
        else
        {
            switch (reason.code)
            {
                case "ECONNABORTED":
                    response.responseCode = ResponseCodeOption.Timeout;
                    break;

                default:
                    response.responseCode = ResponseCodeOption.BadRequest;
                    break;
            }
        }

        return response;
    }


    protected setHeader = (field: string, content: any): void =>
    {
        this.Headers[field] = content;
    };

    protected getHeader = (field: string): any =>
    {
        return this.Headers[field];
    }

    protected refreshAntiforgeryToken = async () =>
    {
        if (!BaseService.RequestToken)
        {
            const response = await this.get<ResponseData<any>>(BaseService.TokenURL);
            if (response?.responseCode === ResponseCodeOption.Success &&
                response.responseData)
            {
                BaseService.CookieToken = response.responseData.cookieToken;
                BaseService.RequestToken = response.responseData.requestToken;
            }
        }

        this.setHeader("X-XSRF-TOKEN", BaseService.RequestToken);
        this.setHeader("X-XSRF-COOKIE", BaseService.CookieToken);
    }
}


export {BaseService};