import {ImageLoading} from "Core/Components/Loadings/Loadings";
import {AppResources} from "Core/Controllers/Controllers";
import {Functions, Tags} from "Core/References";

import ReactDOM from "react-dom/client";
import React from "react";


interface ILoadingScreenProperties
{
    seconds?: number,
    isUseResources?: boolean,
    contents?: Array<Array<string>>,
    loadingComponent?: React.JSX.Element
}

let LoadingContainer: ReactDOM.Root;
const DefaultProperties: ILoadingScreenProperties = {};
const Elements = {
    loading: "page-loading",
    loadingComponent: "page-loading-component",
    loadingMessage: "page-loading-message"
};

const useLoading = (properties: ILoadingScreenProperties = DefaultProperties) =>
{
    const translate = Functions.translate();

    return (event: object, asyncMethod: (event: object) => Promise<any>) =>
    {
        const loadingAsync = async () =>
        {
            const seconds: number = properties.seconds ?? 10;
            const isUseResources: boolean = properties.isUseResources ?? false;
            const loadingComponent: React.JSX.Element = properties.loadingComponent ?? <ImageLoading/>;
            const contents: Array<Array<string>> =
                properties.contents ?? translate(AppResources.Messages.Loadings.toString(), {returnObjects: true});

            const loading: any = document.getElementById(Elements.loading);
            if (loading)
            {
                if (!LoadingContainer)
                {
                    const container: any = document.getElementById(Elements.loadingComponent);
                    LoadingContainer = ReactDOM.createRoot(container)
                }

                LoadingContainer.render(loadingComponent);
                loading.style.display = "block";
                const message: any = document.getElementById(Elements.loadingMessage);
                for (let messages of contents)
                {
                    if (loading.style.display === "none")
                    {
                        break;
                    }

                    const index = Functions.random(messages.length);
                    const text = messages[index];
                    message.innerHTML = isUseResources ? translate(text) : text;

                    await Functions.sleep(seconds);
                }
            }
        };

        asyncMethod(event).finally(() => { closeLoading(); });
        loadingAsync().then();
    };
};

const closeLoading = (): void =>
{
    const loading: any = document.getElementById(Elements.loading);
    if (loading)
    {
        loading.style.display = "none";
    }
};

const LoadingScreen = () =>
{
    return (
        <div id={Elements.loading} className="loading">
            <div className="loading-overlay"></div>
            <Tags.Container className="loading-content">
                <div id="page-loading-component" className="loading-component"></div>
                <div id="page-loading-message" className="loading-message"></div>
            </Tags.Container>
        </div>
    );
};

export type {ILoadingScreenProperties}
export
{
    LoadingScreen,
    useLoading,
    closeLoading
};