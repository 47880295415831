import * as signalR from "@microsoft/signalr";

import {Functions} from "Core/Functions/Functions";


interface ISocket
{
    start: () => void,
    stop: () => void,
    send: (methodName: string, ...args: any[]) => void
}

interface ISocketData
{
    socketURL: string,
    onStart?: (...args: any[]) => any
    onStop?: (...args: any[]) => any
    onReconnect?: (...args: any[]) => any
    onMessageReceive?: {
        [name: string]: (...args: any[]) => any
    }
}

class SignalR implements ISocket
{
    private socket: ISocketData;
    private connection: signalR.HubConnection;

    public constructor(socket: ISocketData)
    {
        this.socket = socket;
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(socket.socketURL)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: _ =>
                {
                    return 5 * 1000;
                }
            })
            .build();

        for (let methodName in socket.onMessageReceive)
        {
            this.connection.on(methodName, socket.onMessageReceive[methodName]);
        }

        this.connection.onclose(() =>
        {
            this.start();
        });

        this.connection.onreconnected(() =>
        {
            Functions.logInformation("Socket reconnected.");
            if (this.socket.onReconnect)
            {
                this.socket.onReconnect();
            }
        });
    }

    public start = (): void =>
    {
        this.connection
            .start()
            .then(() =>
            {
                if (this.socket.onStart)
                {
                    this.socket.onStart();
                }
            })
            .catch(error => Functions.logError(error));
    };

    public stop = (): void =>
    {
        this.connection
            .stop()
            .then(() =>
            {
                if (this.socket.onStop)
                {
                    this.socket.onStop();
                }
            })
            .catch(error => Functions.logError(error));
    };

    public send = (methodName: string, ...args: any[]): void =>
    {
        this.connection
            .invoke(methodName, ...args)
            .catch(error => Functions.logError(error));
    }
}


export type {ISocket, ISocketData};
export {SignalR};