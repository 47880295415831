import {
    AppResources,
    Functions,
    Images,
    IPageProperties,
    PaymentController,
    ResponseCodeOption,
    Tags,
    TransactionData
} from "Universal/Packages";


interface ITransactionStatus
{
    icon: string,
    color: string,
    message: string
}

const Colors = {
    success: "#4FA800",
    failure: "#EB2629",
    warning: "#FDB913"
}

const getTransactionResult = (transaction: TransactionData): ITransactionStatus =>
{
    let icon = Images.Icons.warning;
    let color = Colors.warning;
    let message;

    const translate = Functions.translate();
    if (transaction.ipn)
    {
        const ipn = transaction.ipn;
        message = ipn.resultCode
            ? translate(AppResources.Messages.ResultCode, {resultCode: ipn.resultCode})
            : translate(AppResources.Pages.Exception.Message, {errorCode: ipn.transactionStatus});

        switch (ipn.transactionStatus)
        {
            case ResponseCodeOption.Success:
                icon = Images.Icons.success;
                color = Colors.success;
                break;

            case ResponseCodeOption.Expired:
            case ResponseCodeOption.ClientCancel:
                icon = Images.Icons.warning;
                color = Colors.warning;
                break;

            default:
                icon = Images.Icons.failure;
                color = Colors.failure;
                break;
        }
    }
    else
    {
        const errorCode = transaction.error?.errorCode ?? transaction.responseCode ?? ResponseCodeOption.BadRequest;
        message = translate(AppResources.Pages.Exception.Message, {errorCode: errorCode});
    }

    return {
        icon: icon,
        color: color,
        message: message
    };
};

const FinishPage = (properties: IPageProperties) =>
{
    const translate = Functions.translate();
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const result = getTransactionResult(transaction);

    return (
        <controller.Page skin={properties.skin}
                         store={controller.Transaction}
                         controller={controller}
                         isLoading={false}>
            <Tags.Box className={"result-container"}>
                <Tags.Card className="card">
                    <Tags.Image className="image" src={result.icon}/>
                    <Tags.CardContent className="content">
                        <Tags.Typography
                            color={result.color}
                            className={"status"}
                            component="div">
                            {result.message}
                        </Tags.Typography>
                        <Tags.Typography marginBottom="1.5rem" marginTop="1.5rem">
                        <span className={"text-gray"}>{translate(AppResources.Fields.TransactionID)}: {transaction?.transactionID}</span>
                        </Tags.Typography>
                    </Tags.CardContent>
                </Tags.Card>
            </Tags.Box>
        </controller.Page>
    );
}


export {FinishPage};