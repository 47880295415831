import {IDialogProperties, Tags} from "Core/Core";
import React from "react";
import {Material} from "../MaterialPackage";


const Dialog = (properties: IDialogProperties) =>
{
    const isOpen = properties.isOpen ?? false;
    const closeButton = properties.closeButton;
    const confirmButton = properties.confirmButton ?? <Tags.Empty/>;

    return (
        <Material.Dialog
            open={isOpen}
            onClose={properties.onClose}
        >
            <div className="dialog">
                <Tags.Container className={"dialog-content"}>
                    <Tags.Row>
                        <Tags.Column>
                            {properties.content}
                        </Tags.Column>
                    </Tags.Row>
                </Tags.Container>
                <Material.DialogActions className="dialog-action">
                    {closeButton}
                    {confirmButton}
                </Material.DialogActions>
            </div>
        </Material.Dialog>
    );
};


export {Dialog};