import {ISocket, ISocketData, SignalR} from "Core/Components/Sockets/Base/SignalR";


interface ISockets
{
    [url: string]: ISocket;
}

class SocketBusiness
{
    private static Sockets: ISockets = {};

    public register = (socketData: ISocketData): ISocket =>
    {
        const route = socketData.socketURL;
        let socket: ISocket = SocketBusiness.Sockets[route];
        if (!socket)
        {
            socket = new SignalR(socketData);
            SocketBusiness.Sockets[route] = socket;
            socket.start();
        }

        return socket;
    }
}


export {SocketBusiness};