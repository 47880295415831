const Empty = (properties: any) =>
{
    return (
        <>
            {properties.children}
        </>
    );
}


export {Empty};