import CountryData from "assets/data/Countries.json";
import {useMemo, useState} from "react";
import {
    ApiOperations,
    AppResources,
    ButtonTypeOption,
    Components,
    DataTypeOption,
    FieldTypeOption,
    Functions, ICardConsent,
    ICardData,
    IFields,
    IInvoiceData,
    Providers,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


const CountryCodes = {
    US: "US",
    CA: "CA"
}

const loadCountries = (language: string) =>
{
    const countries: Array<any> = [];
    const isUseVietnamese = language === "vi";

    Object.values(CountryData.countries).map(item =>
    {
        const text = item.countryCode + " - " + (isUseVietnamese ? item.localName : item.countryName);
        const country = {
            text: text,
            value: item.countryCode,
            searchText: Functions.removeVietnamese(text).toLowerCase()
        }

        countries.push(country);
        return null;
    });

    return countries;
}

const loadStates = (countryCode: any) =>
{
    const states: Array<any> = [];
    if (countryCode !== CountryCodes.US &&
        countryCode !== CountryCodes.CA)
    {
        return states;
    }

    const items: any = CountryData.states;
    Object.values(items[countryCode]).map((item: any) =>
    {
        const text = item.stateCode + " - " + item.stateName;
        const state = {
            text: text,
            value: item.stateCode,
            searchText: Functions.removeVietnamese(text).toLowerCase()
        }

        states.push(state);
        return null;
    });

    return states;
}

let Countries: Array<any> = [];
let States: Array<any> = [];


const InternationalCardForm = (properties: any) =>
{
    const store = properties.store;
    const controller = properties.controller;
    const translate = Functions.translate();
    const paymentSource = store.payment.paymentSource;
    const tokens = useMemo(
        () => controller.filterTokens(store.payment.tokens, false),
        [controller, store.payment.tokens]);
    const isHasToken = tokens.length > 0;
    const isUseBilling = store.payment.providerCode === Providers.Cybersource;

    let sourceInfo = store.payment.sourceInfo || properties.source;
    let internationalCard: ICardData = controller.DefaultCardInfo;
    let cardNumber = undefined;
    let cvv = undefined;
    let billCountry = undefined;
    let billState = undefined;
    let billCity = undefined;
    let billPostalCode = undefined;
    let billAddress = undefined;
    if (sourceInfo)
    {
        cardNumber = Functions.decrypt(store.sessionKey, sourceInfo.cardNumber) || sourceInfo.cardNumber;
        cvv = Functions.decrypt(store.sessionKey, sourceInfo.cardVerificationValue) || sourceInfo.cardVerificationValue;
        internationalCard = controller.getInternationalCard(cardNumber, paymentSource) || controller.DefaultCardInfo;
        billCountry = sourceInfo.country;
        billState = sourceInfo.state;
        billCity = sourceInfo.city;
        billPostalCode = sourceInfo.postalCode;
        billAddress = sourceInfo.address01;
    }
    else
    {
        sourceInfo = {};
    }

    const ValidationMessages = useMemo(() =>
    {
        return {
            UnsupportedBrand: translate(AppResources.Messages.UnavailableBrand)
        }
    }, [translate]);
    const [country, setCountry] = useState(billCountry || "VN");
    const [isUseToken, setIsUseToken] = useState(isHasToken);
    const [isCreateToken, setIsCreateToken] = useState(false);
    const [cardInfo, setCardInfo] = useState(internationalCard);
    const [invoiceInfo, setInvoiceInfo] = useState<IInvoiceData>();
    const cardBrandLogo = useMemo(() => controller.renderInternationalCard(cardInfo), [controller, cardInfo]);
    Countries = useMemo(() => loadCountries(store.order.language), [store.order.language]);

    const applyFee = (cardBrand: ICardData) =>
    {
        let fee = {
            serviceCode: "",
            feeAmount: 0
        };

        if (cardBrand?.brandCode
            && store.payment.serviceFees)
        {
            const feeService = store.payment.serviceFees.find((item: any) => item.accountBrand === cardBrand.brandCode);
            if (feeService)
            {
                fee = feeService
            }
        }

        const invoice: IInvoiceData = {
            orderCurrency: store.order.orderCurrency,
            orderNetAmount: store.order.orderNetAmount,
            orderFeeAmount: store.order.orderFeeAmount + fee.feeAmount,
            orderDiscountAmount: store.order.orderDiscountAmount,
            orderAmount: store.order.orderAmount + fee.feeAmount,
            serviceCode: fee.serviceCode
        };

        setInvoiceInfo(invoice);
    }

    const onSelectToken = (tokenID?: string) =>
    {
        let cardBrand: ICardData = cardInfo;
        if (Functions.isNullOrWhiteSpace(tokenID) === false)
        {
            const tokenInfo = tokens.find((item: any) => item.tokenID === tokenID);
            if (tokenInfo)
            {
                cardBrand = {
                    logo: "",
                    brandCode: tokenInfo.accountBrand,
                    isHasLength19: false,
                    isUseExpireDate: true
                }
            }
        }

        applyFee(cardBrand);
    }

    const onSubmit = (values: any) =>
    {
        const source = new SourceData();
        source.sourceOfFund = isUseToken ? SourceOfFunds.Token : SourceOfFunds.Card;
        source.apiOperation = isCreateToken ? ApiOperations.PayWithCreateToken : ApiOperations.Pay;
        source.address01 = values.Address;
        source.city = values.City;
        source.country = values.Country;
        source.state = values.State;
        source.postalCode = values.PostalCode;

        if (invoiceInfo)
        {
            source.serviceCode = invoiceInfo.serviceCode;
        }

        if (isUseToken)
        {
            source.token = values.Token;
        }
        else
        {
            source.cardNumber = values.CardNumber.replace(/\D/g, "");
            source.cardHolderName = values.CardHolderName;
            source.cardExpireDate = values.CardExpireDate;
            source.cardVerificationValue = values.CardVerificationValue.replace(/\D/g, "");
        }

        properties.onSubmit(source);
    }

    const isUseState = country === CountryCodes.US || country === CountryCodes.CA;
    const fields: IFields = {
        CardNumber: {
            name: "CardNumber",
            textResource: AppResources.Fields.CardNumber,
            placeHolderResource: AppResources.Fields.CardNumberPlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: cardNumber,
            minLength: 13,
            maxLength: 23,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const cardBrand = controller.getInternationalCard(element.value, paymentSource);
                setCardInfo(cardBrand || controller.DefaultCardInfo);
                applyFee(cardBrand);
            },
            validator: (value, context) =>
            {
                if (isUseToken)
                {
                    return true;
                }

                if (Functions.isInvalidCard(value))
                {
                    return false;
                }

                const cardBrand = controller.getInternationalCard(value, paymentSource);
                if (cardBrand &&
                    store.payment.supportBrands.indexOf(cardBrand.brandCode) === -1)
                {
                    return context.createError({message: ValidationMessages.UnsupportedBrand});
                }

                return cardBrand;
            },
        },
        CardHolderName: {
            name: "CardHolderName",
            textResource: AppResources.Fields.CardHolderName,
            placeHolderResource: AppResources.Fields.CardHolderNamePlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo?.cardHolderName,
            maxLength: 26,
            autoComplete: false,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                const value = element.value
                    .replace(/\d+/g, "")
                    .replace(/  +/g, " ");
                element.value = Functions.removeVietnamese(value).toUpperCase();
            }
        },
        CardExpireDate: {
            name: "CardExpireDate",
            textResource: AppResources.Fields.CardExpireDate,
            placeHolderResource: AppResources.Fields.CardExpireDatePlaceholder,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: sourceInfo.cardExpireDate,
            minLength: 5,
            maxLength: 5,
            inputProps: {
                inputMode: "numeric"
            },
            language: store.order.language,
            validator: (value) =>
            {
                return isUseToken || controller.isInvalidDateRange(value) === false;
            },
        },
        CardVerificationValue: {
            name: "CardVerificationValue",
            textResource: AppResources.Fields.CardVerificationValue,
            required: isUseToken === false,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: cvv,
            inputProps: {
                inputMode: "numeric"
            },
            minLength: 3,
            maxLength: 4,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                element.value = element.value.replace(/\D/g, "");
            }
        },
        Address: {
            name: "Address",
            textResource: AppResources.Fields.Address,
            required: isUseBilling,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            maxLength: 60,
            value: billAddress,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                element.value = element.value.replace(/[<>!\-?+#$%&^*]/g, "");
            }
        },
        City: {
            name: "City",
            textResource: AppResources.Fields.City,
            required: isUseBilling,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            maxLength: 50,
            value: billCity,
            onChange: (event: any) =>
            {
                const element = event.target as HTMLInputElement;
                element.value = element.value.replace(/[<>!\-?+#$%&^*\d]/g, "");
            }
        },
        Country: {
            name: "Country",
            textResource: AppResources.Fields.Country,
            placeHolderResource: AppResources.Fields.CountryPlaceholder,
            required: isUseBilling,
            readonly: true,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            language: store.order.language,
            value: billCountry,
            onChange: (option: any) =>
            {
                let value = option?.value;
                if (Functions.isNullOrEmpty(value))
                {
                    value = "VN";
                }

                States = loadStates(value);
                setCountry(value);
            }
        },
        State: {
            name: "State",
            textResource: AppResources.Fields.State,
            required: isUseState && isUseBilling,
            readonly: true,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            value: billState
        },
        PostalCode: {
            name: "PostalCode",
            textResource: AppResources.Fields.PostalCode,
            required: isUseState && isUseBilling,
            type: FieldTypeOption.Text,
            dataType: DataTypeOption.String,
            maxLength: country === CountryCodes.US ? 9 : 6,
            value: billPostalCode,
            onChange: (event: any) =>
            {
                if (country !== CountryCodes.US &&
                    country !== CountryCodes.CA)
                {
                    return;
                }

                const element = event.target as HTMLInputElement;
                element.value = Functions
                    .removeVietnamese(element.value)
                    .replace(country === CountryCodes.US ? /\D/gi : /[^A-Za-z\d]/gi, "")
                    .toUpperCase();
            },
            validator: (value) =>
            {
                if (country !== CountryCodes.US)
                {
                    return true;
                }

                // Only check for US, CA always true
                return /\D/gi.test(value) === false;
            },
        },
        Token: {
            name: "Token",
            text: "",
            value: isUseToken ? tokens[0].tokenID : "",
            required: false
        }
    };

    const billingAddress = isUseBilling
        ? <div style={{marginTop: 20}}>
            <span className={"model-provider-content"}>{translate(AppResources.Messages.BillingAddress)}</span>
            <div className="model-card-input-cardNumber" style={{marginTop: 8, paddingBottom: 30}}>
                <div>
                    <Tags.TextField {...fields.Address}/>
                </div>
                <div className="model-text-field-input">
                    <Tags.Row spacing={2}>
                        <Tags.Column xs={6}>
                            <Tags.TextField {...fields.City}/>
                        </Tags.Column>
                        <Tags.Column xs={6}>
                            <Tags.DrawerSelectField {...fields.Country}
                                                    options={Countries}
                            />
                        </Tags.Column>
                    </Tags.Row>
                </div>
                <div className={`model-text-field-input ${isUseState ? "" : "invisible"}`}
                     style={{marginTop: "20px"}}>
                    <div>
                        <Tags.Row spacing={2}>
                            <Tags.Column xs={6}>
                                <Tags.DrawerSelectField {...fields.State}
                                                        options={States}
                                />
                            </Tags.Column>
                            <Tags.Column xs={6}>
                                <Tags.TextField {...fields.PostalCode}/>
                            </Tags.Column>
                        </Tags.Row>
                    </div>
                </div>
            </div>
        </div>
        : <Tags.Empty/>;

    const errorMessage = store.responseCode === ResponseCodeOption.PaymentSourceNotAvailable
        ? <Components.ErrorMessage message={store.responseMessage}/>
        : <></>;
    const cardConsent = useMemo(() => {
        const identification = store.merchant.customerIdentification;
        if (Functions.isNullOrWhiteSpace(identification))
        {
            return <Tags.Empty/>;
        }

        const resources: ICardConsent =
            translate(`Messages.CardConsent.${identification}`, {returnObjects: true});
        
        return  store.customer.isLogin
            ? <Components.CardConsent resources={resources} setIsCreateToken={setIsCreateToken} isCreateToken={isCreateToken}/>
            : <span
                className={"modal-message-required-login"}
                style={{marginTop: "24px"}}>{resources.Requirement}</span>;
    }, [isCreateToken, store.customer.isLogin, store.merchant.customerIdentification, translate]);
        
    return (
        <>
            <div className="full-width-100">
                <Tags.FormikForm fields={fields} onSubmit={onSubmit}>
                    <Components.TokenForm store={store}
                                          tokens={tokens}
                                          setIsUseToken={setIsUseToken}
                                          onChange={onSelectToken}
                                          isLocalCard={false}/>
                    <div className={`model-card-input-cardNumber ${isUseToken ? "invisible" : ""}`}>
                        <div>
                            <Tags.Row spacing={2}>
                                <Tags.Column xs={9}>
                                    <Tags.CardNumberField {...fields.CardNumber}/>
                                </Tags.Column>
                                <Tags.Column xs={3}>
                                    {cardBrandLogo}
                                </Tags.Column>
                            </Tags.Row>
                        </div>
                        <div className="model-text-field-input">
                            <Tags.TextField {...fields.CardHolderName}/>
                        </div>
                        <div className="model-text-field-input margin-bottom-30">
                            <Tags.Row spacing={2}>
                                <Tags.Column xs={6}>
                                    <Tags.DateTextField {...fields.CardExpireDate}/>
                                </Tags.Column>
                                <Tags.Column xs={6}>
                                    <Tags.PasswordField {...fields.CardVerificationValue}/>
                                </Tags.Column>
                            </Tags.Row>
                        </div>
                        {cardConsent}
                        {errorMessage}
                    </div>
                    {billingAddress}
                    <Tags.Button id="btn-next-create-payment" className={"invisible"} type={ButtonTypeOption.Submit}/>
                </Tags.FormikForm>
                <Components.CheckoutOrder store={properties.store} order={invoiceInfo}/>
            </div>
            <Components.CheckoutFooter store={properties.store} order={invoiceInfo}/>
        </>
    );
};


export {InternationalCardForm};