const PaymentMethods = {
    Domestic: "DOMESTIC",
    International: "INTERNATIONAL",
    QR: "QR",
    Hub: "HUB",
    PayLater: "BNPL",
    
    Wallet: "WALLET",
    QRPay: "QRPAY"
};


export {PaymentMethods};