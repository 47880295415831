import React from "react";
import {AppResources, Functions, PaymentMethods, Providers, Tags, TransactionData} from "Universal/Packages";


interface IProviderProperties
{
    store: TransactionData,
    images?: string[]
}

const GetTitle = (paymentMethod: string, providerCode: string) =>
{
    const translate = Functions.translate();
    switch (paymentMethod)
    {
        case PaymentMethods.Domestic:
            return translate(AppResources.Pages.Payment.Methods.Domestic);

        case PaymentMethods.International:
            return translate(AppResources.Pages.Payment.Methods.International);

        default:
            if (providerCode === Providers.GalaxyPay)
            {
                return translate(AppResources.Pages.Payment.Methods.GALAXYPAY, {providerCode: providerCode});
            }

            if (providerCode === Providers.QRPay)
            {
                return translate(AppResources.Pages.Payment.Methods.QRPay);
            }

            // return translate(AppResources.Pages.Payment.Methods.QR, {providerCode: providerCode});
            return translate(AppResources.Pages.Payment.Methods.Wallet, {providerCode: providerCode});
    }
};

const ProviderInfo = (properties: IProviderProperties) =>
{
    const transaction = properties.store;
    if (!transaction)
    {
        return <Tags.Empty/>;
    }

    const title = GetTitle(transaction.payment.paymentMethod, transaction.payment.providerCode);

    return (
        <div className="provider-container">
            <span className="provider-title">
                {title}
            </span>
        </div>
    );
};


export {ProviderInfo};