import {Material} from "../MaterialPackage";


const FormControlLabel = (properties: any) =>
{
    return (
        <Material.FormControlLabel {...properties}/>
    );
}


export {FormControlLabel};