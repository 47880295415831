import {IFormik, Tags} from "Core/Core";
import {IFieldProperties} from "Core/Interfaces/Properties/IProperties";
import {useFormikContext} from "formik";
import {useEffect} from "react";


const DateTextField = (properties: IFieldProperties) =>
{
    const formik = useFormikContext() as IFormik<any>;
    const onInput = (event: any) =>
    {
        const field = (event.target as HTMLInputElement);
        let position = field.selectionEnd ?? 0;
        const length = field.value.length;
        field.value = format(field.value);
        field.selectionEnd = position +
            (
                length + 1 ||
                (field.value.charAt(position - 1) === ' ' && field.value.charAt(length - 1) === ' ')
                    ? 1
                    : 0
            );
    };

    const onBlur = (event: any) =>
    {
        const element = (event.target as HTMLInputElement);
        const value = format(element.value);
        formik.setFieldValue(properties.name, value);
    };

    const format = (value: string) =>
    {
        let result = value.replace(/\D/g, "");
        if (result.length >= 3)
        {
            return `${result.slice(0, 2)}/${result.slice(2, 4)}`;
        }
        return result
    };

    let value = undefined;
    if (properties.value)
    {
        value = format(properties.value);
    }

    useEffect(() =>
    {
        if (properties.value)
        {
            formik.setFieldValue(properties.name, format(properties.value));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Tags.TextField {...properties}
                        value={value}
                        onBlur={onBlur}
                        onInput={onInput}/>
    );
}


export {DateTextField};