class PayOptionData
{
    public providerCode!: string;
    public paymentMethod!: string;
    public isUseQR!: boolean;
    public isUseMobileQR!: boolean;
    public isSupportRedirectLink!: boolean;
    public supportBrands!: Array<string>;
}


export {PayOptionData};