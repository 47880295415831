import {ISkin, React, Tags} from "Universal/Packages";
import {DefaultSkin} from "./DefaultSkin";


const EmptySkin = (properties: ISkin): JSX.Element =>
{
    return (
        <DefaultSkin
            className={properties.className}
            isLoading={properties.isLoading}
            header={Tags.Empty}
            footer={Tags.Empty}
            body={properties.body}/>
    );
};


export {EmptySkin};