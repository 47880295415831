import {
    AppResources,
    Button,
    ButtonTypeOption,
    Column,
    Container,
    DataTypeOption,
    FieldTypeOption,
    Functions,
    IFieldProperties,
    IFields,
    IFormProperties,
    RadioField,
    Row,
    SelectField,
    TextField
} from "Core/References";

import {Formik} from "formik";
import {FormikProps} from "formik/dist/types";
import React from "react";
import * as Yup from "yup";


const getValidator = (
    field: IFieldProperties,
    name: string,
    translate: any): any =>
{
    const message = translate("Invalid", {name: name});
    switch (field.dataType)
    {
        case DataTypeOption.Number:
            return Yup.number();

        case DataTypeOption.String:
            return Yup.string();

        case DataTypeOption.Email:
            return Yup.string().email(message);

        default:
            return Yup.mixed();
    }
};

const generateField = (properties: IFieldProperties): React.JSX.Element =>
{
    let field: React.JSX.Element;
    switch (properties.type)
    {
        case FieldTypeOption.Select:
            field = <SelectField {...properties}/>
            break;

        case FieldTypeOption.Checkbox:
            field = <RadioField {...properties}/>;
            break;

        default:
            field = <TextField {...properties}/>
            break;
    }

    return (
        <Column key={Functions.getGUID()}
                xs={properties.xs}
                sm={properties.sm}
                md={properties.md}
                lg={properties.lg}
                xl={properties.xl}>
            {field}
        </Column>
    )
};

const generateForm = (fields?: IFields): React.JSX.Element =>
{
    const children = new Array<React.JSX.Element>();
    for (const key in fields)
    {
        const properties: IFieldProperties = fields[key];
        const field = generateField(properties);
        children.push(field);
    }

    return (
        <Container>
            <Row>
                {children}
                <Column>
                    <Button type={ButtonTypeOption.Submit} textResource={AppResources.Actions.Submit}/>
                </Column>
            </Row>
        </Container>
    );
};


const FormikForm = (properties: IFormProperties): React.JSX.Element =>
{
    let message: string = "";
    const translate = Functions.translate({keyPrefix: "Validations"});
    const initialValues: any = {};
    const validateOptions: any = {};

    for (const key in properties.fields)
    {
        const field: IFieldProperties = properties.fields[key];
        const fieldName: string = field.name;
        const fieldLabel: string = field.label ?? fieldName;
        let validator = getValidator(field, fieldLabel, translate);

        if (field.required === true)
        {
            message = translate("Required", {name: fieldLabel});
            validator = validator.required(message);
        }

        if (!Functions.isNullOrUndefined(field.minLength))
        {
            message = translate("Min", {length: field.minLength});
            validator = validator.min(field.minLength, message);
        }

        if (!Functions.isNullOrUndefined(field.maxLength))
        {
            message = translate("Max", {length: field.maxLength});
            validator = validator.max(field.maxLength, message);
        }

        if (!Functions.isNullOrUndefined(field.pattern))
        {
            message = translate("Invalid", {name: fieldLabel});
            validator = validator.matches(field.pattern, message);
        }

        if (!Functions.isNullOrUndefined(field.validator))
        {
            message = translate("Invalid", {name: fieldLabel});
            validator = validator.test(Functions.getGUID(), message, field.validator);
        }

        initialValues[fieldName] = field.value ?? "";
        validateOptions[fieldName] = validator;
    }

    const validationSchema = Yup.object().shape(validateOptions)
    const formik = {
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values: any) =>
        {
            properties.onSubmit(values);
        }
    };

    const children: React.ReactNode = properties.autoGenerated
        ? generateForm(properties.fields)
        : properties.children;

    const form = ({handleSubmit}: FormikProps<any>): React.JSX.Element => (
        <form
            autoComplete={"off"}
            action={properties.action}
            onSubmit={handleSubmit}>
            {children}
        </form>
    );

    return (
        <Formik
            initialValues={formik.initialValues}
            validationSchema={formik.validationSchema}
            onSubmit={formik.onSubmit}
            children={form}/>
    );
}


export {FormikForm};