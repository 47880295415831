import {Components, TransactionData} from "Universal/Packages";


interface ITimeoutProperties
{
    isHideCountdown?: boolean,
    store: TransactionData,
    onTimeout: () => void,
    cssClass?: string
}

const Timeout = (properties: ITimeoutProperties) =>
{
    const store: TransactionData = properties.store;
    const onTimeout = () =>
    {
         properties.onTimeout();
    };

    const isHideCountdown = properties.isHideCountdown ?? false;
    const timerClass = isHideCountdown ? "invisible" : `${properties.cssClass ?? ""}`;

    return (
        <Components.Timer store={store}
                          onTimerElapsed={onTimeout}
                          classTimer={`model-countdown-time ${timerClass}`}/>
    );
};


export {Timeout}