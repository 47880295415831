import {
    CheckoutController,
    Functions,
    IPageProperties,
    PageRoutes,
    PaymentMethods,
    PreOrderData,
    Providers,
    React, TransactionStages
} from "Universal/Packages";


const CheckoutPage = (properties: IPageProperties) =>
{
    const onSuccess = (preorder: PreOrderData) =>
    {
        let route: string;
        switch (preorder.payment.paymentMethod)
        {
            case PaymentMethods.Domestic:
                route = PageRoutes.Checkout.Domestic;
                break;

            case PaymentMethods.International:
                route = PageRoutes.Checkout.International;
                break;

            case PaymentMethods.Wallet:
            case PaymentMethods.QRPay:
                route = PageRoutes.Checkout.Wallet;
                break;

            case PaymentMethods.Hub:
                let isPayPlatform = Functions.isInArray(
                    preorder.payment.paymentSource,
                    Providers.Apple,
                    Providers.Samsung,
                    Providers.Google);
                
                if (preorder.merchant.isMobileApplication === true &&
                    isPayPlatform &&
                    window.top &&
                    Functions.isIframe())
                {
                    const result: any = {};
                    controller.changeState(TransactionStages.Redirect, "", result);
                    return;
                }

                route = PageRoutes.Checkout.Hub;
                break;

            case PaymentMethods.PayLater:
                route = PageRoutes.Checkout.PayLater;
                break;

            default:
                route = PageRoutes.Payment.Finish;
                break;
        }

        controller.navigateWithCurrentParams(route);
    };

    const controller = new CheckoutController();
    controller.queryCheckout({onSuccess: onSuccess});

    return (
        <controller.Page skin={properties.skin}
                         controller={properties.controller}
                         isLoading={true}/>
    );
};


export {CheckoutPage};