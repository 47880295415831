enum FontWeightOption
{
    Light = 300,
    Normal = 400,
    Medium = 500,
    SemiBold = 600,
    Bold = 700
}


export {FontWeightOption};