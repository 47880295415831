import {BaseController} from "Core/Controllers/Base/BaseController";
import {IInputProperties} from "Core/References";


class InputController extends BaseController
{
    protected InputProperties: IInputProperties;

    public constructor(properties: IInputProperties)
    {
        super();
        this.InputProperties = properties;
    }

    public get Id(): string { return this.InputProperties.id ?? this.Functions.getGUID(); }

    public get Text(): string
    {
        return this.InputProperties.text ?? this.getResource(this.InputProperties.textResource) ?? "";
    }
}


export {InputController};