import {AppResources, Functions, Images, Tags} from "Universal/Packages";


const ErrorMessage = (properties: any) =>
{
    const translate = Functions.translate();
    const message = translate(AppResources.Messages.ResultCode, {resultCode: properties.message}) || properties.message;

    return (
        <div className={"modal-error-message-card-payment"}>
            <Tags.Image src={Images.Icons.alertTriangle} style={{height: "20px", marginRight: "10px"}}/>
            <span>{message}</span>
        </div>
    )
};


export {ErrorMessage};