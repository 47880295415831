import {AppResources, Tags} from "../../../Core/References";
import {React} from "../../Packages";

const WalletCancelButton = (onClick: () => void) =>
{
    return <Tags.Button className={"modal-button-cancel-wallet-pay"}
                        onClick={onClick}
                        textResource={AppResources.Actions.SendBack}/>;
}

export {WalletCancelButton};