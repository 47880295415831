import {useState} from "react";
import {AppResources, Tags} from "Universal/Packages";


interface IAlertDialog
{
    message: string,
    onClose?: () => void
}

const AlertDialog = (properties: IAlertDialog) =>
{
    const [isOpen, setIsOpen] = useState(true);
    const onClose = () =>
    {
        if(properties.onClose)
        {
            properties.onClose();
        }
        
        setIsOpen(false);
    };

    const content = <div className="modal-dialog-content">{properties.message}</div>;
    const closeButton = <Tags.Button
        className="modal-dialog-button-close"
        autoFocus
        onClick={onClose}
        textResource={AppResources.Actions.Close}
    />

    return (
        <Tags.Dialog
            isOpen={isOpen}
            content={content}
            closeButton={closeButton}
        />
    )
};


export {AlertDialog};