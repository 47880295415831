import {InputController} from "Core/Controllers/Components/Inputs/InputController";
import {IButtonProperties} from "Core/References";


class ButtonController extends InputController
{
    protected ButtonProperties: IButtonProperties;

    public constructor(iButtonProperties: IButtonProperties)
    {
        super(iButtonProperties);
        this.ButtonProperties = iButtonProperties;
    }
}


export {ButtonController};