const TransactionStages = {
    Init: "INIT",
    Processing: "PROCESSING",
    Authenticate: "AUTHENTICATE",
    Result: "RESULT",
    Finish: "FINISH",
    
    Close: "CLOSE",
    Redirect: "REDIRECT"
};


export {TransactionStages};