import React, {useEffect} from "react";


const enableSplashScreen = () =>
{
    const splashScreen = document.getElementById("splash-screen")
    if (splashScreen)
    {
        splashScreen.style.setProperty("display", "flex")
    }
};

const disableSplashScreen = () =>
{
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen)
    {
        splashScreen.style.setProperty("display", "none");
    }
};

const SplashScreen = (properties: any): React.JSX.Element =>
{
    enableSplashScreen();
    useEffect(() =>
    {
        setTimeout(disableSplashScreen, 500);
    }, []);

    return (
        <>
            {properties.children}
        </>
    );
};


export {SplashScreen};