import {
    Functions,
    IPageProperties,
    PageRoutes,
    PaymentController,
    PaymentMethods,
    Providers,
    React,
    TransactionData,
    TransactionStages
} from "Universal/Packages";


const PaymentPage = (properties: IPageProperties) =>
{
    const onSuccess = (transaction: TransactionData) =>
    {
        let route: string;
        switch (transaction.payment.paymentMethod)
        {
            case PaymentMethods.Domestic:
                route = PageRoutes.Payment.Domestic;
                break;

            case PaymentMethods.International:
                route = PageRoutes.Payment.International;
                break;

            case PaymentMethods.QR:
                route = PageRoutes.Payment.QR;
                break;

            case PaymentMethods.Hub:
                if (transaction.merchant.isMobileApplication === true &&
                    window.top &&
                    Functions.isIframe() &&
                    Functions.isInArray(
                        transaction.payment.paymentSource,
                        Providers.Apple,
                        Providers.Samsung,
                        Providers.Google))
                {
                    const result: any = {};
                    controller.changeState(TransactionStages.Redirect, "", result);
                    return;
                }
                
                route = PageRoutes.Payment.Hub;
                break;

            case PaymentMethods.PayLater:
                route = PageRoutes.Payment.PayLater;
                break;

            default:
                route = PageRoutes.Payment.Finish;
                break;
        }

        controller.navigateWithCurrentParams(route);
    };

    const controller = new PaymentController();
    controller.queryPayment({onSuccess: onSuccess});

    return (
        <controller.Page skin={properties.skin}
                         store={controller.Transaction}
                         controller={controller}
                         isLoading={true}/>
    );
};


export {PaymentPage};