import {IContainerProperties} from "Core/References";
import {Material} from "../MaterialPackage";


const Column = (properties: IContainerProperties) =>
{
    const xs = properties.xs ?? 12;
    return (
        <Material.Grid item
                       id={properties.id}
                       className={properties.className}
                       style={properties.style}
                       xs={xs}
                       sm={properties.sm}
                       md={properties.md}
                       lg={properties.lg}
                       xl={properties.xl}
                       onClick={properties.onClick}
        >
            {properties.children}
        </Material.Grid>
    );
};


export {Column};