import {useState} from "react";
import {AppResources, Functions, IPageProperties, React, Tags} from "Universal/Packages";


const Loading = () =>
{
    const translate = Functions.translate();

    return (
        <Tags.Container className={"loading-screen"}>
            <Tags.ImageLoading/>
            <div className="loading-message">
                {translate(AppResources.Messages.Processing)}
            </div>
        </Tags.Container>
    );
}

const PendingPage = (properties: IPageProperties) =>
{
    useState(() =>
    {
        window.parent.postMessage("galaxy-pay-iframe-close", window.location.origin);
    });

    return (
        <div className={"model-loading"}>
            <Loading/>
        </div>
    );
}


export {PendingPage};