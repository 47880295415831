import {AppResources, Functions, ICardConsent, Images, React, Tags} from "Universal/Packages";


interface IConsentProperties
{
    setIsCreateToken: React.Dispatch<React.SetStateAction<boolean>>
    isCreateToken: boolean,
    resources: ICardConsent
}

const CardConsent = (properties: IConsentProperties) =>
{
    const translate = Functions.translate();
    const [state, setState] = React.useState(false);
    const resources = properties.resources;

    const onChange = (event: any) =>
    {
        const isChecked = event.currentTarget.checked;
        properties.setIsCreateToken(isChecked);
    };

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
    {
        if (event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        )
        {
            return;
        }
        setState(open);
    };

    const toggleContent = () => (
        <Tags.Box className="model-save-card-info">
            <div className="model-save-card-info-header"
                 style={{backgroundImage: `url(${Images.Backgrounds.DrawerHeader})`}}>
                
                    <span className="model-save-card-info-header-title font-jambono">
                        {resources.Header}
                    </span>

                <div className="model-save-card-info-header-close">
                    <Tags.Button onClick={toggleDrawer(false)}>
                        <Tags.Icons.Close style={{color: "#000000"}}/>
                    </Tags.Button>
                </div>
            </div>
            <div className="model-save-card-info-content text-align-justify">
                <div>
                    <b>
                        {resources.Title}
                    </b>
                </div>
                <div>
                    <p style={{whiteSpace: "pre-line"}}>
                        {resources.Content}
                    </p>
                </div>
                <div>
                    <b>
                        {resources.Footer}
                    </b>
                </div>
            </div>
            <div className="model-save-card-info-content-btn-close">
                <Tags.Button className="model-footer-secure-button"
                             onClick={toggleDrawer(false)}
                >
                    {translate(AppResources.Actions.Close)}
                </Tags.Button>
            </div>
        </Tags.Box>
    );

    return (
        <div className="model-confirm-save-card-info">
            <input id={"chkCardConsent"} className="model-confirm-save-card-info-checkbox" type="checkbox"
                   onChange={onChange} checked={properties.isCreateToken}/>
            <div className="model-confirm-save-card-info-content">
                <label htmlFor={"chkCardConsent"} className="model-confirm-save-card-info-content-title">
                    {resources.ShortContent}
                </label>
                <Tags.Button className="model-confirm-save-card-info-content-view"
                             onClick={toggleDrawer(true)}
                >
                    {translate(AppResources.Actions.ViewDetail)}
                </Tags.Button>
                <Tags.SwipeableDrawer
                    anchor="bottom"
                    open={state}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    PaperProps={{sx: {borderRadius: "8px 8px 0 0"}}}
                >
                    {toggleContent()}
                </Tags.SwipeableDrawer>
            </div>
        </div>
    );
};

export {CardConsent};