import {Components, ISkin, PreOrderData, React, Tags} from "Universal/Packages";
import {CheckoutSkin} from "./CheckoutSkin";


const CheckoutSkinWithOrder = (properties: ISkin): React.JSX.Element =>
{
    const className = `${properties.isLoading ? "" : "flex-justify-content-flex-start"} ${properties.className ?? ""}`;
    const Header = properties.header;
    const Footer = properties.isLoading ? Tags.Empty : properties.footer;
    const store = properties.store as PreOrderData;
    const Body =
        <>
            <Components.CheckoutProvider store={store}/>
            {properties.body}
            <Components.CheckoutOrder store={store}/>
        </>;

    return (
        <CheckoutSkin
            className={className}
            header={Header}
            footer={Footer}
            body={Body}
            store={properties.store}
            controller={properties.controller}
            onClose={properties.onClose}
            isLoading={properties.isLoading}/>
    );
};


export {CheckoutSkinWithOrder};