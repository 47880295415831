const DefaultContainer = (properties: any): JSX.Element =>
{
    return (
        <div className={properties.className}>
            {properties.children}
        </div>
    );
};


export {DefaultContainer};