import {Functions, IOption, ISelectProperties, Tags,} from "Core/References";
import React from "react";
import {Material} from "../MaterialPackage";


const RadioGroupField = (properties: ISelectProperties) =>
{
    const defaultOption = properties.options?.find(obj =>
    {
        return obj.value === properties.value;
    });

    const onChange = (event: any, option: any) =>
    {
        if (properties.onChange)
        {
            properties.onChange(option);
        }
    };

    const invisible = properties.visible === false;
    const defaultValue = defaultOption?.value;
    const elements = new Array<React.ReactNode>();
    if (properties.options)
    {
        properties.options.forEach((option: IOption) =>
        {
            const item =
                properties.renderOption
                    ? properties.renderOption(properties, option, {})
                    : <Tags.FormControlLabel
                        key={Functions.getGUID()}
                        value={option.value}
                        control={<Tags.RadioField/>}
                        label={
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div style={{width: option.icon ? 54 : 0}}>
                                    <Tags.Image src={option.icon}/>
                                </div>
                                <span style={{fontSize: "14px", marginLeft: "5px"}}>{option.text}</span>
                            </div>
                        }/>;

            elements.push(item);
        });
    }

    return (
        <div className={invisible ? "invisible" : ""}>
            <Material.RadioGroup
                defaultValue={defaultValue}
                name={properties.name}
                onChange={onChange}
            >
                {elements}
            </Material.RadioGroup>
        </div>
    );
}


export {RadioGroupField};