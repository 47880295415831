import {getWebInstrumentations, initializeFaro} from "@grafana/faro-web-sdk";
import {TracingInstrumentation} from "@grafana/faro-web-tracing";

import ResourceChinese from "assets/resources/cn.json";
import ResourceEnglish from "assets/resources/en.json";
import ResourceJapanese from "assets/resources/ja.json";
import ResourceKorean from "assets/resources/ko.json";
import ResourceRussian from "assets/resources/ru.json";
import ResourceThailand from "assets/resources/th.json";
import ResourceTaiwan from "assets/resources/tw.json";
import ResourceVietnamese from "assets/resources/vi.json";

import {Functions} from "Core/Functions/Functions";
import {LanguageOption} from "Core/Options/Base/LanguageOption";
import {IAppProperties} from "Core/References";

import i18next from "i18next";
import {initReactI18next} from "react-i18next";


const resources = {
    en: {
        translation: ResourceEnglish
    },
    ja: {
        translation: ResourceJapanese
    },
    ko: {
        translation: ResourceKorean
    },
    ru: {
        translation: ResourceRussian
    },
    th: {
        translation: ResourceThailand
    },
    vi: {
        translation: ResourceVietnamese
    },
    "zh-CN": {
        translation: ResourceChinese
    },
    "zh-TW": {
        translation: ResourceTaiwan
    }
};

let AppResources = ResourceEnglish;
AppResources = Functions.getResourceDefinition(AppResources);

const AppDefaultLanguage = LanguageOption.Vietnamese;

class AppController
{
    public onStart = (properties: IAppProperties): void =>
    {
        this.registerEvents();
        this.registerFaro();
        this.initResources();
        this.setLanguage();

        if (properties.onStartUp)
        {
            properties.onStartUp();
        }
    };

    private initResources = (defaultLanguage: string = AppDefaultLanguage): void =>
    {
        i18next
            .use(initReactI18next)
            .init({
                lng: defaultLanguage,
                fallbackLng: {
                    "zh-cn": ["zh-CN"],
                    "zh-tw": ["zh-TW"],
                    default: [defaultLanguage]
                },
                debug: Functions.isUseConsoleLog(),
                resources,
            })
            .then();
    };

    private registerEvents = () =>
    {
        window.onerror = function (
            event,
            source,
            line,
            column,
            error)
        {
            Functions.logError(event, source, line, column, error);
        };
        
        // Disable History Back
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };

        // Online/Offline Status
        const internetScreen = "internet-loading";
        const page = "app-page";
        window.addEventListener("offline", (event: any) =>
        {
            document.getElementById(internetScreen)!.style.display = "flex";
            document.getElementById(page)!.style.display = "none";
        });
        window.addEventListener("online", (event: any) =>
        {
            document.getElementById(internetScreen)!.style.display = "none";
            document.getElementById(page)!.style.display = "flex";
        });
    }

    private registerFaro = () =>
    {
        const faroUrl = Functions.getSetting("REACT_APP_FARO_URL");
        const faroAPIKey = Functions.getSetting("REACT_APP_FARO_API_KEY");

        initializeFaro({
            url: faroUrl,
            apiKey: faroAPIKey,
            app: {
                name: "uat-upc-frontend",
                version: "1.0.0"
            },
            instrumentations: [
                // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
                ...getWebInstrumentations(),

                // Initialization of the tracing package.
                // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
                new TracingInstrumentation(),
            ],
        });
    }

    private setLanguage = () =>
    {
        const urlParams = new URLSearchParams();
        const language =
            urlParams.get("language") ??
            window.location.href.split("/").pop() ??
            AppDefaultLanguage;

        Functions.changeLanguage(language);
    }
}


export {AppResources};
export {AppController};