import {
    AppResources,
    BaseRequest,
    CaptureRequest,
    CheckEnrollmentRequest,
    EnrollmentData, FailureRequest,
    Functions,
    InitRequest,
    IPNData, LanguageOption,
    PatternOption,
    PaymentChannels,
    PaymentMethods,
    PaymentService,
    PayOptionData,
    ResponseCodeOption,
    ResponseData,
    SendBackData,
    Settings,
    SourceData,
    SourceOfFunds,
    StoreActionOption,
    Stores,
    TransactionData,
    TransactionStages,
    UpdateRequest,
    useDispatch
} from "Universal/Packages";
import {UPCBusiness} from "../Base/UPCBusiness";


class PaymentBusiness extends UPCBusiness
{
    private TimeToLive: number = Functions.getNumberSetting(Settings.CacheLiveTime) // seconds
    private Dispatch = useDispatch();
    private Translate = Functions.translate();


    public getVersionAsync = async () =>
    {
        return await new PaymentService().getVersionAsync();
    }


    public getPaymentAsync = async (request: BaseRequest, store: TransactionData): Promise<TransactionData> =>
    {
        if (this.isInvalidTransaction(request))
        {
            const transaction = new TransactionData(request.transactionID);
            transaction.transactionState = TransactionStages.Finish;
            transaction.processingStage = TransactionStages.Finish;
            transaction.error = {
                errorCode: ResponseCodeOption.Unauthorized,
                errorDescription: "Transaction is invalid."
            }

            this.setStore(request.currentState, transaction);
            return transaction;
        }

        return await this.getStoreAsync(request, store);
    };

    public queryPaymentAsync = async (request: BaseRequest) =>
    {
        const response = await new PaymentService().queryPaymentAsync(request);
        return this.getResponse(request, response);
    };

    public initPaymentAsync = async (request: InitRequest, source: SourceData) =>
    {
        request.paymentChannel = Functions.isMobile() ? PaymentChannels.Mobile : PaymentChannels.Web;
        request.paymentMethod = source.paymentMethod;
        request.sourceOfFund = source.sourceOfFund;

        request.address01 = source.address01;
        request.city = source.city;
        request.country = source.country;
        request.state = source.state;
        request.postalCode = source.postalCode;

        if (source.sourceOfFund === SourceOfFunds.Card)
        {
            request.cardNumber = Functions.encrypt(source.sessionKey, source.cardNumber);
            request.cardHolderName = source.cardHolderName;
            request.cardExpireDate = source.cardExpireDate;

            if (source.paymentMethod === PaymentMethods.International)
            {
                request.cardVerificationValue = Functions.encrypt(source.sessionKey, source.cardVerificationValue);
            }
        }
        else if (source.sourceOfFund === SourceOfFunds.Account)
        {
            request.bankCode = source.bankCode;
            request.accountBrand = source.accountBrand;
            request.accountNumber = source.accountNumber;
            request.accountName = source.accountName;
            request.identificationNumber = source.identificationNumber;
        }

        request.validationURL = source.validationURL;
        request.validationHost = source.validationHost;

        const response = await new PaymentService().initPaymentAsync(request);
        return this.getResponse(request, response);
    };

    public updatePaymentAsync = async (request: UpdateRequest, optionData: PayOptionData) =>
    {
        request.providerCode = optionData.providerCode;
        request.paymentMethod = optionData.paymentMethod;
        request.isUseQR = optionData.isUseQR;
        request.isUseMobileQR = optionData.isUseMobileQR;
        request.isSupportRedirectLink = optionData.isSupportRedirectLink;
        request.supportBrands = optionData.supportBrands;

        const response = await new PaymentService().updatePaymentAsync(request);
        return this.getResponse(request, response);
    };

    public checkEnrollmentAsync = async (request: CheckEnrollmentRequest, enrollment?: EnrollmentData) =>
    {
        if (enrollment)
        {
            // TODO For Future Implement
        }

        const response = await new PaymentService().checkEnrollmentAsync(request)
        return this.getResponse(request, response);
    };

    public capturePaymentAsync = async (
        request: CaptureRequest, 
        securityCode: string, 
        sessionKey: string,
        accountVerified?: boolean,
        cardHolderAuthenticated?: boolean) =>
    {
        request.securityCode = Functions.encrypt(sessionKey, securityCode);
        request.accountVerified = accountVerified;
        request.cardHolderAuthenticated = cardHolderAuthenticated;
        const response = await new PaymentService().capturePaymentAsync(request)
        return this.getResponse(request, response);
    };


    // Process result (success or failure)
    public processResultAsync = async (request: BaseRequest, transaction: TransactionData) =>
    {
        const response = await new PaymentService().processResultAsync(request);
        return await this.updateTransaction(request, response, transaction, false);
    }

    // Process cancel
    public processCancelAsync = async (request: BaseRequest, transaction: TransactionData) =>
    {
        const response = await new PaymentService().processCancelAsync(request);
        return await this.updateTransaction(request, response, transaction, true);
    }

    // Process timeout
    public processTimeoutAsync = async (request: BaseRequest, transaction: TransactionData) =>
    {
        const response = await new PaymentService().processTimeoutAsync(request);
        return await this.updateTransaction(request, response, transaction, true);
    }

    // Process end with client exception
    public processFailureAsync = async (request: FailureRequest, transaction: TransactionData) =>
    {
        const response = await new PaymentService().processFailureAsync(request);
        return await this.updateTransaction(request, response, transaction, true);
    }

    // Mark transaction as FINISH
    public finishTransaction = (transaction: TransactionData) =>
    {
        const currentState = transaction.processingStage;
        transaction.transactionState = TransactionStages.Finish;
        transaction.processingStage = TransactionStages.Finish;
        this.setStore(currentState, transaction);
    }

    private updateTransaction = async (
        request: BaseRequest,
        response: ResponseData<SendBackData>,
        transaction: TransactionData,
        isHoldRequest: boolean) =>
    {
        const store: TransactionData = Object.assign(transaction);

        // Mark transaction as ERROR
        if (!response ||
            !response.responseData ||
            response.responseCode !== ResponseCodeOption.Success)
        {
            // Transaction has been completed => Reload
            if (response.responseCode === ResponseCodeOption.Created)
            {
                const transaction = await this.queryPaymentAsync(request);
                return transaction.sendBackInfo;
            }

            store.error = {
                errorCode: response.responseCode || ResponseCodeOption.BadRequest
            }
            store.responseCode = response.responseCode;
            store.responseMessage = response.responseMessage;
            this.finishTransaction(store);

            return undefined;
        }

        // Update IPN if not exist
        if (!store.ipn)
        {
            const ipn = response.responseData;

            store.ipn = new IPNData();
            store.ipn.data = ipn.data;
            store.ipn.signature = ipn.signature;
            store.ipn.resultCode = ipn.resultCode;
            store.ipn.transactionStatus = ipn.transactionStatus;
        }

        // Store send back info for cancel & timeout & error
        if (isHoldRequest === true)
        {
            const previousStage = store.processingStage;
            store.sendBackInfo = response.responseData;
            store.processingStage = TransactionStages.Result;
            this.setStore(previousStage, store);
        }
        else
        {
            this.finishTransaction(store);
        }

        return response.responseData;
    }

    private isInvalidTransaction = (request: BaseRequest) =>
    {
        const signature = request.signature;
        const transactionID = request.transactionID;
        const dateTimeExpire = request.dateTimeExpire;

        if (!signature ||
            signature.length !== 64)
        {
            return true;
        }

        if (dateTimeExpire === 0)
        {
            return true;
        }

        if (!transactionID ||
            transactionID.length !== 25 ||
            Functions.isNumber(transactionID) === false)
        {
            return true;
        }

        const value = Number(transactionID);
        const date: Date = Functions.getLastMonth();
        return value <= Functions.getDateTime(date, PatternOption.ShortDate) * 10000000000000000000;
    };

    private isExpireTransaction = (request: BaseRequest) =>
    {
        const expireDateTime = request.dateTimeExpire || 0;
        return expireDateTime <= Functions.getCurrentDateTime();
    };

    private getStoreAsync = async (request: BaseRequest, store: TransactionData) =>
    {
        const transactionID = request.transactionID;
        const currentDateTime = Functions.getCurrentDateTime();

        if (store &&
            store.transactionID === transactionID)
        {
            // Transaction has been finished
            if (store.ipn)
            {
                return store;
            }

            // Transaction is update to date
            if (store.dateTimeSync >= currentDateTime)
            {
                return store;
            }
        }

        // Transaction has been out of date and has to refresh.
        return await this.queryPaymentAsync(request);
    };

    private getResponse = (
        request: BaseRequest,
        response: ResponseData<TransactionData>) =>
    {
        let transaction = response.responseData;
        if (!transaction)
        {
            transaction = new TransactionData(request.transactionID);
            transaction.transactionState = TransactionStages.Finish;
            transaction.processingStage = TransactionStages.Finish;
            transaction.error = {
                errorCode: response.responseCode || ResponseCodeOption.NotFound
            }
        }

        transaction.responseCode = response.responseCode;
        transaction.responseMessage = response.responseMessage;
        this.setStore(request.currentState, transaction);
        
        return transaction;
    };

    private setStore = (previousState: string, transaction: TransactionData): boolean =>
    {
        if (Functions.isNullOrUndefined(transaction))
        {
            return false;
        }

        if (Functions.isNullOrWhiteSpace(transaction.sessionKey) &&
            Functions.isNullOrWhiteSpace(transaction.dataKey) === false)
        {
            const secretKey = Functions.getSetting(Settings.SecretKey);
            transaction.sessionKey = Functions.decrypt(secretKey, transaction.dataKey);
        }

        // Notify state changed
        const currentState = transaction.processingStage;
        if (previousState !== currentState)
        {
            const title = this.Translate(AppResources.Messages.StageTitle, {stage: currentState})
            const ipn = transaction.ipn;
            const result: any = {
                transactionID: transaction.transactionID,
                providerCode: transaction.payment?.providerCode,
                language: transaction.order?.language ?? LanguageOption.English
            };
            if (ipn &&
                currentState === TransactionStages.Finish)
            {
                result.transactionStatus = ipn.transactionStatus;
                result.data = ipn.data;
                result.signature = ipn.signature;
            }

            this.changeState(currentState, title, result);
        }

        transaction.dateTimeUpdate = Functions.getCurrentDateTime();
        transaction.dateTimeSync = Functions.getFutureDateTime(this.TimeToLive);

        const payload = {
            storeName: Stores.Transaction,
            action: StoreActionOption.Overwrite,
            store: transaction
        }

        this.Dispatch(payload);
        return true;
    };
}


export {PaymentBusiness};