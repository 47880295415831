import {IResultMessage, UPCBusiness} from "Universal/Business/Base/UPCBusiness";
import {
    AppResources,
    BaseRequest,
    CheckoutService,
    CreatePaymentRequest,
    Functions,
    PatternOption,
    PaymentMethods,
    PreOrderData,
    QueryRequest,
    ResponseCodeOption,
    ResponseData,
    Settings,
    SourceData,
    SourceOfFunds,
    StoreActionOption,
    Stores,
    TransactionStages,
    useDispatch
} from "Universal/Packages";


class CheckoutBusiness extends UPCBusiness
{
    private TimeToLive: number = Functions.getNumberSetting(Settings.CacheLiveTime) // seconds
    private Dispatch = useDispatch();
    private Translate = Functions.translate();


    public getCheckoutAsync = async (request: BaseRequest, store: PreOrderData): Promise<PreOrderData> =>
    {
        if (this.isInvalidTransaction(request))
        {
            Functions.logWarning(request.requestID, "Invalid Transaction");

            const preorder = new PreOrderData();
            preorder.responseCode = ResponseCodeOption.Unauthorized;
            preorder.responseMessage = "Checksum Failure."
            this.setStore(preorder);

            return preorder;
        }

        return await this.getStoreAsync(request, store);
    };

    public cancelCheckoutAsync = async (request: QueryRequest) =>
    {
        const response = await new CheckoutService().cancelCheckout(request);
        if (response.responseCode === ResponseCodeOption.Success)
        {
            const currentState: string = TransactionStages.Finish;
            const title = this.Translate(AppResources.Messages.StageTitle, {stage: currentState})
            const result: IResultMessage = {
                transactionID: request.transactionID!,
                transactionStatus: ResponseCodeOption.ClientCancel
            }

            this.changeState(currentState, title, result);
        }
    };

    public queryCheckoutAsync = async (request: QueryRequest) =>
    {
        const response = await new CheckoutService().queryCheckout(request);
        return this.getResponse(request, response);
    };

    public createPaymentAsync = async (request: CreatePaymentRequest, source: SourceData, store: PreOrderData) =>
    {
        this.createPaymentRequest(request, source);
        const response = await new CheckoutService().createPaymentAsync(request);
        const responseCode = response.responseCode ?? "";

        if (store.payment.paymentMethod !== PaymentMethods.Hub &&
            Functions.isInArray(responseCode, ResponseCodeOption.ServiceUnavailable) === true)
        {
            // Booking failure => Retry
            return response;
        }

        // End request
        if (response.responseCode !== ResponseCodeOption.Success)
        {
            const failureResponse = new ResponseData<PreOrderData>(
                response.requestID!,
                response.responseCode!,
                response.responseDateTime!,
                response.responseMessage!,
                store);
            this.getResponse(request, failureResponse);
            return response;
        }

        return response;
    };

    private getStoreAsync = async (request: BaseRequest, store: PreOrderData) =>
    {
        const transactionID = request.transactionID;
        const currentDateTime = Functions.getCurrentDateTime();

        if (store &&
            store.transactionID === transactionID)
        {
            // Transaction is update to date
            if (store.dateTimeSync >= currentDateTime)
            {
                return store;
            }
        }

        // Transaction has been out of date and has to refresh.
        return await this.queryCheckoutAsync(request);
    };

    private isInvalidTransaction = (request: BaseRequest) =>
    {
        const signature = request.signature;
        const transactionID = request.transactionID;
        const dateTimeExpire = request.dateTimeExpire;

        if (!signature ||
            signature.length !== 64)
        {
            return true;
        }

        if (dateTimeExpire === 0)
        {
            return true;
        }

        if (!transactionID ||
            transactionID.length !== 25 ||
            Functions.isNumber(transactionID) === false)
        {
            return true;
        }

        const value = Number(transactionID);
        const date: Date = Functions.getLastMonth();
        return value <= Functions.getDateTime(date, PatternOption.ShortDate) * 10000000000000000000;
    };

    private getResponse = (
        request: BaseRequest,
        response: ResponseData<PreOrderData>) =>
    {
        let preorder = response.responseData;
        if (!preorder)
        {
            preorder = new PreOrderData();
            preorder.transactionID = request.transactionID!;
        }

        preorder.responseCode = response.responseCode;
        preorder.responseMessage = response.responseMessage;

        this.setStore(preorder);
        return preorder;
    };

    private createPaymentRequest = (request: CreatePaymentRequest, source: SourceData) =>
    {
        request.paymentMethod = source.paymentMethod;
        request.sourceOfFund = source.sourceOfFund;
        request.serviceCode = source.serviceCode;

        switch (source.sourceOfFund)
        {
            case SourceOfFunds.Card:
                request.cardNumber = Functions.encrypt(source.sessionKey, source.cardNumber);
                request.cardHolderName = source.cardHolderName;
                request.cardExpireDate = source.cardExpireDate;
                request.apiOperation = source.apiOperation;

                if (request.paymentMethod === PaymentMethods.International)
                {
                    request.address01 = source.address01
                    request.city = source.city
                    request.country = source.country
                    request.state = source.state
                    request.postalCode = source.postalCode
                }

                if (source.cardVerificationValue)
                {
                    request.cardVerificationValue = Functions.encrypt(source.sessionKey, source.cardVerificationValue);
                }
                break;

            case SourceOfFunds.Account:
                request.bankCode = source.bankCode;
                request.accountBrand = source.accountBrand;
                request.accountNumber = source.accountNumber;
                request.accountName = source.accountName;
                request.identificationNumber = source.identificationNumber;
                break;

            case SourceOfFunds.Token:
                request.token = source.token;

                if (request.paymentMethod === PaymentMethods.International)
                {
                    request.address01 = source.address01
                    request.city = source.city
                    request.country = source.country
                    request.state = source.state
                    request.postalCode = source.postalCode
                }
                break;
        }
    }

    private setStore = (preorder: PreOrderData): boolean =>
    {
        if (Functions.isNullOrUndefined(preorder))
        {
            return false;
        }

        let store: PreOrderData = Object.assign(preorder);
        if (Functions.isNullOrWhiteSpace(store.sessionKey) &&
            Functions.isNullOrWhiteSpace(store.dataKey) === false)
        {
            const secretKey = Functions.getSetting(Settings.SecretKey);
            store.sessionKey = Functions.decrypt(secretKey, store.dataKey);
        }

        store.dateTimeUpdate = Functions.getCurrentDateTime();
        store.dateTimeSync = Functions.getFutureDateTime(this.TimeToLive);

        const payload = {
            storeName: Stores.PreOrder,
            action: StoreActionOption.Overwrite,
            store: store
        }

        this.Dispatch(payload);
        return true;
    };
}


export {CheckoutBusiness};