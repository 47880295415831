import {Images, Tags} from "Universal/Packages";


const Footer = (properties: any) =>
{
    return (
        <div id={properties.id} className={"footer"}>
            <Tags.Container>
                <Tags.Row>
                    <Tags.Column xs={12} md={12} className="footer-display">
                        <div className="footer-left">
                            <div className="footer-item">
                                <span>Hotline: </span>
                                <span>1900638021</span>
                            </div>
                            <div className="footer-item">
                                <span>Copyright © 2022 Galaxy Pay</span>
                            </div>
                        </div>
                        <div className="footer-right">
                            <div className="footer-item">
                                <img src={Images.Misc.certificate} alt="" style={{width: "67px"}}/>
                            </div>
                            <div className="footer-item">
                                <a href="https://certificate.crossbowlabs.com/291ec14f-6fca-4a2d-bf86-15fa72678042#gs.bfl6i8"
                                   target="_blank"
                                   rel="noreferrer">
                                    <img src={Images.Misc.pci} alt="" style={{width: "67px", borderRadius: "6px"}}/>
                                </a>
                            </div>
                            <div className="footer-item">
                                <a href="https://www.galaxypay.vn/" target="_blank" rel="noreferrer">
                                    <img src={Images.Icons.website} alt="" style={{width: "26px"}}/>
                                </a>
                            </div>
                        </div>
                    </Tags.Column>
                </Tags.Row>
            </Tags.Container>
        </div>
    )
};


export {Footer};