import {Material} from "../MaterialPackage";


const SwipeableDrawer = (properties: any) =>
{
    return (
        <Material.SwipeableDrawer {...properties}/>
    );    
}


export {SwipeableDrawer}