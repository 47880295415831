import {useFormikContext} from "formik";
import React, {useEffect} from "react";
import {AppResources, Functions, IFormik, Images, IOption, PreOrderData, Tags} from "Universal/Packages";


interface ITokenFormProperties
{
    store: PreOrderData,
    tokens: Array<any>,
    isLocalCard: boolean,
    setIsUseToken: React.Dispatch<React.SetStateAction<boolean>>,
    onChange?: (tokenID?: string) => void
}

const TokenForm = (properties: ITokenFormProperties) =>
{
    const options = new Array<IOption>();
    const formikContext = useFormikContext() as IFormik<any>;
    const translate = Functions.translate();

    if (properties.store)
    {
        properties.tokens.forEach((token: any) =>
        {
            const item: IOption = {
                text: Functions.right(token.accountNumber, 4),
                value: token.tokenID,
                icon: properties.isLocalCard
                    ? Functions.format(Images.Brands.DomesticCard, token.logo ?? token.accountBrand)
                    : Functions.format(Images.Brands.InternationalCard, token.accountBrand)
            };

            options.push(item);
        });
    }

    // New Card
    const newCardItem: IOption = {
        text: translate(AppResources.Fields.PaymentByNewCard) ?? "",
        value: ""
    };
    options.push(newCardItem)

    const onChange = (value: any) =>
    {
        formikContext.setFieldValue("Token", value);

        const isUseToken = Functions.isNullOrEmpty(value) === false;
        properties.setIsUseToken(isUseToken);

        // Calculate Fee for selected card brand
        if (properties.onChange)
        {
            properties.onChange(value);
        }
    };

    const isShow = options.length > 1;

    useEffect(() =>
    {
        if (isShow && properties.onChange)
        {
            properties.onChange(options[0].value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Tags.RadioGroupField
                name={"Token"}
                value={options[0].value}
                options={options}
                onChange={onChange}
                visible={isShow}/>
        </>
    );
}


export {TokenForm};