import {ButtonController, ButtonTypeOption, Functions, IButtonProperties, useLoading} from "Core/References";

import React from "react";
import {Material} from "../MaterialPackage";


const Button = (properties: IButtonProperties) =>
{
    const controller = new ButtonController(properties);
    const type = properties.type ?? ButtonTypeOption.Button;
    const showLoading = useLoading();

    let onClick = properties.onClick ?? function () {};
    const onClickAsync = properties.onClickAsync;
    if (onClickAsync)
    {
        onClick = (event: any) =>
        {
            showLoading(event, onClickAsync);
        }
    }

    let handleClick = onClick;
    if (properties.preventDoubleClick === true)
    {
        handleClick = (event: any) =>
        {
            const button = event.currentTarget;
            button.disabled = true;
            onClick(event);

            setTimeout(function ()
            {
                button.disabled = false;
            }, 3000);
        }
    }

    return (
        <Material.Button
            id={controller.Id}
            variant={Functions.getButtonVariant(properties.variant)}
            startIcon={properties.startIcon}
            name={properties.name}
            type={type}
            className={properties.className}
            style={properties.style}
            onClick={handleClick}
            autoFocus={properties.autoFocus}
        >
            {controller.Text}
            {properties.children}
        </Material.Button>
    );
}


export {Button};