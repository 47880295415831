import {useState} from "react";
import {
    CheckoutController,
    Components,
    IPageProperties,
    PreOrderData,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


const CheckoutWalletPage = (properties: IPageProperties) =>
{
    const controller = new CheckoutController();
    const preorder = controller.PreOrder;
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = useState<any>(undefined);

    const onSuccess = (_: PreOrderData) =>
    {
        setIsLoading(false);
    };

    const onSubmit = async () =>
    {
        setIsLoading(true);

        const source = new SourceData();
        source.sourceOfFund = SourceOfFunds.None;
        const result = await controller.createPaymentAsync(source);
        if (result?.responseCode !== ResponseCodeOption.Success)
        {
            setResponse(result);
            setIsLoading(false);
        }
    }

    const onAlertClose = controller.closeOnCreatePaymentFailure;
    controller.queryCheckout({onSuccess: onSuccess});
    const alertDialog = response
        ? <Components.AlertDialog onClose={onAlertClose(response)} message={response.responseMessage}/>
        : <Tags.Empty/>;

    return (
        <controller.Page
            skin={properties.skin}
            store={preorder}
            controller={controller}
            isLoading={isLoading}
        >
            {alertDialog}
            <Tags.Button id="btn-next-create-payment" style={{display: 'none'}} text={""} onClick={onSubmit}/>
        </controller.Page>
    );
}


export {CheckoutWalletPage};