interface IEventHandler
{
    [event: string]: Array<(...args: any[]) => void>
}

abstract class ObServerBusiness
{
    private static EventHandlers: IEventHandler = {};

    public static subscribe(event: string, handler: (...args: any[]) => void): void
    {
        this.EventHandlers[event] = [...this.EventHandlers[event] || [], handler]
    }

    public static publish(event: string, ...args: any[])
    {
        this.EventHandlers[event].forEach((handler) => handler(...args));
    }
}


export {ObServerBusiness};