import {InputController} from "Core/Controllers/Components/Inputs/InputController";
import {IFieldProperties, IFormik, Types} from "Core/References";

import React from "react";


class FieldController extends InputController
{
    protected FieldProperties: IFieldProperties;
    protected FormikContext: IFormik<any>

    public constructor(properties: IFieldProperties, formikContext: IFormik<any>)
    {
        super(properties);
        this.FieldProperties = properties;
        this.FormikContext = formikContext;
    }

    public get Language(): string { return this.FieldProperties.language ?? "vi" };

    public get Name(): string { return this.FieldProperties.name };

    public get Value() { return this.FormikContext.values[this.Name]; }

    public get IsTouched(): boolean { return this.FormikContext.touched[this.Name] === true; }

    public get IsHasError(): Types.Boolean
    {
        return this.IsTouched && Boolean(this.FormikContext.errors[this.Name]);
    }

    public get ErrorText(): string
    {
        return this.FormikContext.errors[this.Name] as string;
    }

    public get PlaceHolder(): string
    {
        return this.getResource(this.FieldProperties.placeHolderResource) ??
            this.FieldProperties.placeHolder ??
            this.Text;
    }

    public get HelpText(): string
    {
        return this.IsTouched
            ? this.ErrorText ?? this.getHelpText()
            : this.getHelpText();
    }

    public getCustomValidity = (message: string) =>
    {
        return {
            onInvalid: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            {
                const target = event.target as HTMLInputElement;
                target.setCustomValidity(message);
            },
            onBlur: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            {
                if (this.IsHasError === false)
                {
                    const target = event.target as HTMLInputElement;
                    target.setCustomValidity("");
                }
            }
        };
    }

    private getHelpText = (): string =>
    {
        return this.getResource(this.FieldProperties.helpTextResource) ?? this.FieldProperties.helpText ?? "";
    }
}


export {FieldController};