import {useRef, useState} from "react";
import {Components, IPageProperties, PageRoutes, PaymentController, React, TransactionData} from "Universal/Packages";


const DomesticAuthenticatePage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const authenticationForm = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);
    const transaction = controller.Transaction;

    const onTimeout = async () =>
    {
        const napasBackDrop: any = document.getElementsByClassName("modal-backdrop")[0];
        if (napasBackDrop)
        {
            napasBackDrop.style.display = "none";
        }

        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onSuccess = (transaction: TransactionData) =>
    {
        if (!transaction.payment.domesticInfo)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
            return;
        }

        const domestic = transaction.payment.domesticInfo;
        const fragment = document
            .createRange()
            .createContextualFragment(domestic.htmlContent);
        authenticationForm.current.append(fragment);
    };

    controller.queryPayment({onSuccess: onSuccess});

    return (
        <controller.Page skin={properties.skin}
                         store={controller.Transaction}
                         controller={controller}
                         isLoading={isLoading}>
            <Components.Timeout store={transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
            <div ref={authenticationForm} style={{marginTop: "10px", width: "100%"}}></div>
        </controller.Page>
    );
};


export {DomesticAuthenticatePage}