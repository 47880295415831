import * as React from "react";
import {AppResources, Functions, Tags, VariantOption} from "Universal/Packages";


const DialogContent = () =>
{
    const translate = Functions.translate();

    return (
        <div className="modal-dialog-content">
            {translate(AppResources.Messages.DialogCancel.Title)}
        </div>
    );
};

interface IDialogProperties
{
    onCancel: () => void,
    cancelButton?: (onClick: () => void) => React.JSX.Element
}

const SimpleCancelDialog = (properties: IDialogProperties) =>
{
    const [isOpen, setIsOpen] = React.useState(false);

    const onClick = () =>
    {
        setIsOpen(true);
    };

    const onClose = () =>
    {
        setIsOpen(false);
    };

    const onConfirm = () =>
    {
        setIsOpen(false);
        properties.onCancel();
    };

    const content = <DialogContent/>;
    const closeButton = <Tags.Button
        className="modal-dialog-button-close"
        autoFocus
        onClick={onClose}
        textResource={AppResources.Actions.No}
    />
    const confirmButton = <Tags.Button
        className="modal-dialog-button-confirm"
        variant={VariantOption.Filled}
        onClick={onConfirm}
        textResource={AppResources.Actions.Yes}
    />

    const cancelButton = properties.cancelButton
        ? properties.cancelButton(onClick)
        : <Tags.Button className={"modal-button-cancel"} onClick={onClick} textResource={AppResources.Actions.Cancel}/>

    return (
        <>
            {cancelButton}
            <Tags.Dialog
                isOpen={isOpen}
                onClose={onClose}
                content={content}
                closeButton={closeButton}
                confirmButton={confirmButton}
            />
        </>
    )
};


export {SimpleCancelDialog};