import {useState} from "react";
import {
    CheckoutController,
    Components,
    IPageProperties, PageRoutes,
    PreOrderData,
    Providers,
    React,
    ResponseCodeOption,
    SourceData,
    SourceOfFunds,
    Tags
} from "Universal/Packages";


const CheckoutHubPage = (properties: IPageProperties) =>
{
    const controller = new CheckoutController();
    const preorder = controller.PreOrder;
    const [isLoading, setIsLoading] = React.useState(true);
    const [response, setResponse] = useState<any>(undefined);

    const onSuccess = (result: PreOrderData) =>
    {
        if (result.payment.providerCode === Providers.Apple || 
            result.payment.providerCode === Providers.Samsung ||
            result.payment.providerCode === Providers.Google)
        {
            onSubmit().catch();
        }
        else
        {
            setIsLoading(false);
        }
    };

    const onSubmit = async () =>
    {
        setIsLoading(true);

        const source = new SourceData();
        source.sourceOfFund = SourceOfFunds.None;
        const result = await controller.createPaymentAsync(source);
        if (result?.responseCode !== ResponseCodeOption.Success)
        {
            if (preorder.payment.providerCode === Providers.Apple ||
                preorder.payment.providerCode === Providers.Samsung ||
                preorder.payment.providerCode === Providers.Google)
            {
                controller.navigateWithCurrentParams(PageRoutes.Checkout.Finish);
                return;
            }

            setResponse(result);
            setIsLoading(false);
        }
    }

    const onAlertClose = controller.closeOnCreatePaymentFailure;
    controller.queryCheckout({onSuccess: onSuccess});
    const alertDialog = response
        ? <Components.AlertDialog onClose={onAlertClose(response)} message={response.responseMessage}/>
        : <Tags.Empty/>;

    return (
        <controller.Page
            skin={properties.skin}
            store={preorder}
            controller={controller}
            isLoading={isLoading}
        >
            {alertDialog}
            <Tags.Button id="btn-next-create-payment" style={{display: 'none'}} text={""} onClick={onSubmit}/>
        </controller.Page>
    );
}


export {CheckoutHubPage};