import {combineReducers, configureStore, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {Functions, IReducer} from "Core/References";

import React from "react";
import * as Redux from "react-redux";
import {persistReducer, persistStore} from "redux-persist"
import {PersistGate} from "redux-persist/integration/react";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from "redux-persist/lib/storage/session"


interface IReducers
{
    [store: string]: IReducer<any>,
}

interface IStoreMap
{
    [store: string]: any
}

interface IStorePayload
{
    action: string,
    storeName: string,
    store: any
}

interface IStoreProperties
{
    children?: React.ReactNode,
    reducers?: IReducers
}

let Action: any;
const ReducerMap: IReducers = {};
const ReduxStore = (properties: IStoreProperties): React.JSX.Element =>
{
    let storeMap: IStoreMap = {};
    for (const store in properties.reducers)
    {
        const reducer = properties.reducers[store];
        if (!reducer)
        {
            Functions.logDebug(`Reducer for ${store} is undefined.`);
            continue;
        }

        storeMap[store] = reducer.InitialState;
        ReducerMap[store] = reducer;
    }

    const appReducer = createSlice({
        name: "app",
        initialState: storeMap,
        reducers: {
            set: (storeMap: IStoreMap, reduxAction: PayloadAction<IStorePayload>) =>
            {
                const payload = reduxAction.payload;
                const store = payload.storeName;
                const reducer = ReducerMap[store];
                if (!reducer)
                {
                    Functions.logDebug(`Reducer for ${store} not found.`)
                    return storeMap;
                }

                const action = payload.action;
                const currentState = storeMap[store];
                const newState = payload.store;
                storeMap[store] = reducer.SetState(action, currentState, newState);

                return storeMap;
            }
        }
    });

    let {set} = appReducer.actions;
    Action = set;

    const persistConfig = {
        key: "root",
        storage,
        stateReconciler: autoMergeLevel2
    }
    const rootReducer: any = combineReducers({
        app: appReducer.reducer
    });
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const appStore = configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false
        })
    });
    const persistedStore = persistStore(appStore);

    return (
        <Redux.Provider store={appStore}>
            <PersistGate persistor={persistedStore}>
                {properties.children}
            </PersistGate>
        </Redux.Provider>
    );
};

const useDispatch = () =>
{
    const dispatch = Redux.useDispatch();
    return (payload: IStorePayload) =>
    {
        Functions.logDebug("SET STORE", payload.action, payload.storeName, payload.store);
        dispatch(Action(payload));
    }
}

const useStore = <T extends any>(storeName: string): T =>
{
    const stores = Redux.useSelector((state: any) => state.app);
    const store = stores[storeName];

    Functions.logDebug("GET STORE", storeName, store);
    return {...store};
};


export type
{
    IReducers,
    IStoreMap,
    IStoreProperties,
    IStorePayload
};
export
{
    ReduxStore, useStore, useDispatch
};