import "./assets/css/index.css";
import "./assets/css/font.css";
import "./assets/css/app.css";
import "./assets/css/app-mobile.css";
import "./assets/css/modal.css";
import "./assets/css/modal-mobile.css";

import {App} from "Core/Core";
import UniversalApp from "Universal/UniversalApp";


App.start(UniversalApp);