const Settings =
    {
        AssetURL: "REACT_APP_ASSET_URL",
        ServiceURL: "REACT_APP_SERVICE_URL",
        FaroURL: "REACT_APP_FARO_URL",
        SamsungSDK: "REACT_APP_SAMSUNG_SDK",
        SecretKey: "REACT_APP_SECRET_KEY",

        CacheLiveTime: "REACT_APP_CACHE_LIVE_TIME",
        RequestTimeout: "REACT_APP_REQUEST_TIMEOUT",

        ConsoleLog: "REACT_APP_CONSOLE_LOG",
        DeviceDetector: "REACT_APP_DEVICE_DETECTOR"
    }


export {Settings};