import {useRef, useState} from "react";
import {Components, IPageProperties, PageRoutes, PaymentController, React, TransactionData} from "Universal/Packages";


const InternationalAuthenticatePage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const authenticationIFrame = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);
    const transaction = controller.Transaction;

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onSuccess = (transaction: TransactionData) =>
    {
        if (!transaction.payment.internationalInfo ||
            !transaction.payment.internationalInfo.checkEnrollmentInfo)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Finish);
            return;
        }

        const enrollment = transaction.payment.internationalInfo.checkEnrollmentInfo;
        if (enrollment.isRequireAuthentication)
        {
            if (enrollment.isAuthorized)
            {
                controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
            }
            else
            {
                const fragment = document
                    .createRange()
                    .createContextualFragment(enrollment.htmlContent);
                authenticationIFrame.current.append(fragment);

                const onSocketReceive = () =>
                {
                    setIsLoading(true);
                    controller.queryPaymentAsync()
                        .then(() =>
                        {
                            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                        })
                        .catch(() => {});
                };

                controller.registerSocket(transaction, onSocketReceive);

                window.addEventListener("message", (event) =>
                {
                    if (event.origin === window.location.origin &&
                        typeof event.data === "string" &&
                        event.data === "galaxy-pay-iframe-close")
                    {
                        setIsLoading(true);
                        const iframeContainer = document.getElementById("iframe-container");
                        if (iframeContainer) {
                            iframeContainer.remove();
                        }
                        
                        setTimeout(() => {
                            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                        }, 10000);
                    }
                }, false)
            }
        }
    };

    controller.queryPayment({onSuccess: onSuccess});

    return (
        <controller.Page skin={properties.skin}
                         store={controller.Transaction}
                         controller={controller}
                         className={"full-width"}
                         isLoading={isLoading}>
            <Components.Timeout store={transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
            <div id={"formAuthenticate"} ref={authenticationIFrame}/>
        </controller.Page>
    );
};


export {InternationalAuthenticatePage}