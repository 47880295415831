import React, {useCallback, useMemo} from "react";
import {
    AppResources,
    FormControlLabel,
    Functions,
    Images,
    IOption,
    PaymentMethodData,
    PaymentMethods,
    Tags
} from "Universal/Packages";


interface IPaymentOptionListProperties
{
    paymentMethods: Array<PaymentMethodData>,
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodData>>
}

const PaymentOptionList = (properties: IPaymentOptionListProperties) =>
{
    const translate = Functions.translate();
    const paymentMethods = properties.paymentMethods;

    const getTitle = useCallback((paymentMethod: string, paymentSource: string) =>
    {
        switch (paymentMethod)
        {
            case PaymentMethods.Domestic:
                return translate(AppResources.Pages.Payment.Methods.Domestic);

            case PaymentMethods.International:
                return translate(AppResources.Pages.Payment.Methods.International);

            default:
                return translate(AppResources.Pages.Payment.Methods.QR, {providerCode: paymentSource});
        }
    }, [translate]);

    const paymentGroups = useMemo(() =>
    {
        return paymentMethods.map((group: any) =>
        {
            const title = getTitle(group.paymentMethod, group.groupCode);
            let method = {...group};
            if (method.groupContent)
            {
                method.groupContent = method.groupContent
                    .replaceAll('@GroupImageURL', `${Images.Logos.PayOptions}`)
                    .replaceAll('@GroupTitle', title);
            }
            return method;
        });
    }, [paymentMethods, getTitle]);

    const paymentOptions = useMemo(() =>
    {
        return paymentGroups.map((group: any) =>
        {
            const option: IOption = {
                text: group.groupContent,
                value: group.groupCode,
                icon: group.groupImage
            }
            return option;
        });
    }, [paymentGroups]);

    const defaultMethod = paymentGroups[0];
    const defaultValue = defaultMethod.groupCode;
    const findOption = (groupCode?: string): PaymentMethodData | undefined =>
    {
        return paymentMethods.find(obj =>
        {
            return obj.groupCode === groupCode;
        });
    };

    const onChange = (groupCode: any) =>
    {
        const paymentMethod = findOption(groupCode) ?? defaultMethod;
        properties.setPaymentMethod(paymentMethod);
    };

    const renderOption = (props: object, option: IOption) =>
    {
        return (
            <div className="custom-row-selected margin-bottom-28" key={Functions.getGUID()}>
                {
                    <FormControlLabel
                        value={option.value}
                        control={<Tags.RadioField id={option.value}/>}
                        label={
                            <div className='content-options padding-left-8'>
                                <label htmlFor={option.value} className='custom-row-selected'
                                       style={{cursor: 'pointer'}}>
                                    <div className='option-logo-width'>
                                        <Tags.Image
                                            src={`${Images.Logos.PayOptions}/${option.icon}`}
                                            style={{height: "36px"}}/>
                                    </div>
                                    <div className='custom-row-selected'
                                         dangerouslySetInnerHTML={{__html: option.text ?? ""}}></div>
                                </label>
                            </div>
                        }
                    />
                }
            </div>
        );
    };

    return (
        <>
            <div className={"margin-top-24 margin-bottom-28"}>
                <Tags.RadioGroupField name={"PaymentMethods"}
                                      options={paymentOptions}
                                      value={defaultValue}
                                      onChange={onChange}
                                      renderOption={renderOption}/>
            </div>
        </>
    );
}


export {PaymentOptionList};