import {Functions, StateEvents} from "Universal/Packages";


interface IResultMessage
{
    transactionID: string,
    transactionStatus: string,
    providerCode?: string,
    data?: string,
    signature?: string,
    language?: string
}

class UPCBusiness
{
    public changeState = (currentState: string, title: string, result: IResultMessage) =>
    {
        Functions.publishEvent(StateEvents.StateChanged, currentState, title, result);
    }
}


export type {IResultMessage};
export {UPCBusiness}
