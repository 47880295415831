class ResponseData<T>
{
    public requestID?: string;
    public responseCode?: string;
    public responseDateTime?: string;
    public responseMessage?: string;
    public responseData?: T;

    public constructor();
    public constructor(requestID?: string);
    public constructor(requestID: string, responseCode: string);
    public constructor(requestID: string, responseCode: string, responseDateTime: string);
    public constructor(
        requestID: string, 
        responseCode: string, 
        responseDateTime: string, 
        responseMessage: string);
    public constructor(
        requestID: string,
        responseCode: string,
        responseDateTime: string,
        responseMessage: string,
        responseData: T)
    public constructor(
        requestID?: string,
        responseCode?: string,
        responseDateTime?: string,
        responseMessage?: string,
        responseData?: T)
    {
        this.requestID = requestID;
        this.responseCode = responseCode;
        this.responseDateTime = responseDateTime;
        this.responseMessage = responseMessage;
        this.responseData = responseData;
    }
}


export {ResponseData};