import {BaseController} from "Core/Controllers/Base/BaseController";
import {
    Functions,
    IPageProperties,
    ISkin,
    IStorePayload,
    ModelData,
    StoreActionOption,
    useDispatch,
    useStore
} from "Core/References";

import React, {useEffect} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";


class PageController extends BaseController
{
    protected Navigate: Function = useNavigate();
    protected Location = useLocation();
    protected LocationParams = useParams();
    protected Dispatch = useDispatch();

    public Page = (properties: IPageProperties) =>
    {
        const Skin: React.ElementType<ISkin> | undefined = properties.skin ?? this.Theme.skin;

        return (
            (Skin)
                ? <Skin className={properties.className}
                        isLoading={properties.isLoading}
                        body={properties.children}
                        footer={properties.footer}
                        store={properties.store}
                        controller={properties.controller}
                        components={properties.components}
                        onClose={properties.onClose}
                />
                : <>{properties.children}</>
        );
    };

    public onPageLoad = (method: (...args: any[]) => void) =>
    {
        useEffect(() =>
        {
            method();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    };

    public navigate = (url: string, ...params: string[]) =>
    {
        Functions.logDebug(`CHANGE ROUTE: ${url}`);
        
        for (let i in params)
        {
            const param: string = params[i];
            url = `${url}/${param}`;
        }

        this.Navigate(url);
    };
    
    public redirect = (route: string) =>
    {
        if (this.getCurrentRoute() === route)
        {
            return;
        }

        const url = "/" + route;
        this.navigate(url);
    };

    public navigateWithCurrentParams = (route: string) =>
    {
        if (this.getCurrentRoute() === route)
        {
            return;
        }

        const url = "/" + route;
        const params = new Array<string>();
        Object.values(this.LocationParams).forEach(
            (value) =>
            {
                params.push(this.Functions.escape(value));
            }
        );
        this.navigate(url, ...params);
    };

    public getQueryParams = () =>
    {
        return this.LocationParams;
    }

    public getLocation = () =>
    {
        return this.Location;
    };

    public getCurrentRoute = (): string | undefined =>
    {
        const location = this.Location;
        const params = this.LocationParams;

        const currentPath = Object.values(params).reduce(
            (path, param) =>
            {
                path = this.Functions.escape(path);
                return path.replace("/" + param, "")
            },
            location.pathname
        );

        return currentPath?.replace("/", "");
    };
    
    public keepLoading = async (method: () => void) =>
    {
        method();
        await this.Functions.sleep(60); 
    };

    public getStore = <T extends ModelData>(store: string): T =>
    {
        return useStore<T>(store);
    };

    public setStore = (storePayload: IStorePayload): void =>
    {
        this.Dispatch(storePayload);
    };

    public resetStore = (storeName: string): void =>
    {
        this.Dispatch({
            action: StoreActionOption.Reset,
            storeName: storeName,
            store: {}
        });
    };
    
    public updateStore = (storeName: string, store: any): void =>
    {
        this.Dispatch({
            action: StoreActionOption.Update,
            storeName: storeName,
            store: store
        });
    };

    public overrideStore = (storeName: string, store: any): void =>
    {
        this.Dispatch({
            action: StoreActionOption.Overwrite,
            storeName: storeName,
            store: store
        });
    };
}


export {PageController};