import {AppResources, Functions, IInvoiceData, PreOrderData, React, Tags,} from "Universal/Packages";


interface IFooterProperties
{
    store: PreOrderData,
    order?: IInvoiceData,
}

const CheckoutFooter = (properties: IFooterProperties) =>
{
    const translate = Functions.translate();
    const preorder = properties.store;
    const order = properties.order ?? preorder?.order;
    if (!preorder ||
        !order)
    {
        return <Tags.Empty/>;
    }

    const btnNext = () =>
    {
        const element = document.getElementById('btn-next-create-payment');
        if (element)
        {
            element.click();
        }
    }

    return (
        <div className="model-footer">
            <div className="model-footer-action">
                <div className="model-footer-secure-totalAmount">
                    <div className={"font-size-14"}>{translate(AppResources.Pages.Checkout.Fields.OrderAmount)}</div>
                    <b className={"font-jambono"}>{Functions.formatCurrency(order.orderAmount, order.orderCurrency)}</b>
                </div>
                <div>
                    <Tags.Button
                        className="model-footer-secure-button"
                        preventDoubleClick={true}
                        onClick={btnNext}>
                        {translate(AppResources.Actions.Next)}
                    </Tags.Button>
                </div>
            </div>
        </div>
    );
};


export {CheckoutFooter};