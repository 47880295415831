import {useEffect, useRef, useState} from "react";
import {
    Functions,
    IPageProperties,
    Pages,
    PaymentController,
    React,
    SourceData,
    Tags,
    TransactionData
} from "Universal/Packages";
import {IApplePayProperties} from "Universal/Pages/Payment/Methods/ApplePay/IApplePayProperties";


const global = (window as any);
global.GalaxyPay = global.GalaxyPay || {};
global.GalaxyPay.ApplePay = {
    isValidating: false,
    isCanceled: false
};

const getApplePayCache = () =>
{
    return global.GalaxyPay.ApplePay;
};

const ApplePayPage = (properties: IPageProperties) =>
{
    const [isLoading, setIsLoading] = useState(true);
    const [isAvailable, setIsAvailable] = useState(false);
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const browser: any = window.self;
    const applePaySession = browser.ApplePaySession;
    const applePayCache = getApplePayCache();
    const getFingerprint = useRef<any>();

    const onQuerySuccess = (_: TransactionData) =>
    {
        if (Functions.isIframe())
        {
            setIsAvailable(true);
        }
        else if (typeof applePaySession !== "undefined" &&
            applePaySession.canMakePayments())
        {
            setIsAvailable(true);
        }
        else
        {
            setIsAvailable(false);
        }

        setIsLoading(false);
    };

    const onValidateMerchant = async (validationURL: string, validationHost: string) =>
    {
        const source = new SourceData();
        source.validationURL = validationURL;
        source.validationHost = validationHost;

        setIsLoading(true);
        applePayCache.isValidating = true;
        const result = await controller.initPaymentAsync(source).catch();
        applePayCache.isValidating = false;

        if (applePayCache.isCanceled === true)
        {
            controller.processCancelAsync().then();
        }

        return result;
    };

    const onConfirmPayment = async (paymentToken: any) =>
    {
        setIsLoading(true);
        return controller.capturePaymentAsync(paymentToken).catch();
    };

    const onApplePayCancel = () =>
    {
        // TODO: to be review cancel flow
        // if(applePayCache.isValidating === true)
        // {
        //     applePayCache.isCanceled = true;
        // }
    };

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    }

    // Load Apple Pay Script
    useEffect(() =>
    {
        if (Functions.isIframe())
        {
            return;
        }

        const url = "https://applepay.cdn-apple.com/jsapi/v1.1.0/apple-pay-sdk.js";
        Functions.importScript(url, "ApplePay");
    }, []);

    controller.queryPayment({onSuccess: onQuerySuccess});
    const renderBody = (() =>
    {
        const request = {
            countryCode: "VN",
            currencyCode: transaction.order.orderCurrency,
            supportedNetworks: ["visa", "masterCard", "jcb"],
            merchantCapabilities: ["supports3DS"],
            // requiredBillingContactFields: [
            //     "postalAddress"
            // ],
            total: {
                label: transaction.merchant.merchantName,
                amount: `${transaction.order.orderAmount}`
            },
        }

        const props: IApplePayProperties = {
            isApplePayAvailable: isAvailable,
            applePayRequest: request,
            controller: controller,
            onValidateMerchant: onValidateMerchant,
            onConfirmPayment: onConfirmPayment,
            onCancel: onCancel,
            onApplePayCancel: onApplePayCancel,
            setIsLoading: setIsLoading,
            store: transaction,
            getFingerprint: getFingerprint,
        };

        if (Functions.isIframe())
        {
            return <Pages.ApplePayIFramePage {...props}/>;
        }
        else
        {
            return <Pages.ApplePaySelfPage {...props}/>;
        }
    });

    const body = isLoading ? <Tags.Empty/> : renderBody();
    return (
        <>
            <div hidden ref={getFingerprint}/>
            <controller.Page skin={properties.skin}
                             store={controller.Transaction}
                             controller={controller}
                             isLoading={isLoading}>
                {body}
            </controller.Page>
        </>
    );
}


export {ApplePayPage};