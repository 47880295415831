import React, {useEffect} from "react";
import {Components, Functions, PageRoutes, ResponseCodeOption} from "Universal/Packages";
import {IGooglePayProperties} from "./IGooglePayProperties";

const win: any = (window as any);
win.isGoogelPayInitial = false;

const GooglePayMessageID = "GalaxyPay_GooglePay";
const GooglePayState = {
    ShowPayment: "ShowPayment",
    InitPayment: "InitPayment",
    ConfirmPayment: "ConfirmPayment",
    CancelPayment: "CancelPayment",
    ClosePayment: "ClosePayment",
    EndPayment: "EndPayment"
};

const GooglePayIFramePage = (properties: IGooglePayProperties) =>
{
    const isAvailable = properties.isGooglePayAvailable;
    const controller = properties.controller;
    const onInitPayment = properties.onInitPayment;
    const onConfirmPayment = properties.onConfirmPayment;
    const setIsLoading = properties.setIsLoading;
    const setupIFrame = properties.setupIFrame;
    const getFingerprint = properties.getFingerprint;

    const postMessage = (state: string, content: any) =>
    {
        const message = {
            id: GooglePayMessageID,
            state: state,
            domain: window.location.host,
            content: content
        };

        const targetOrigin = (window as any).TargetOrigin ?? "*";
        Functions.logDebug("WINDOW POST MESSAGE", targetOrigin, message);
        window.parent.postMessage(message, targetOrigin);
    };

    const onTimeout = async () =>
    {
        // await controller.processTimeoutAsync();
        // postMessage(GooglePayState.ConfirmPayment, false);
    };

    useEffect(() =>
    {
        if (win.isGoogelPayInitial)
        {
            return;
        }

        window.addEventListener("message", async (event: any) =>
        {
            const data = event.data;
            if (event.origin !== (window as any).TargetOrigin ||
                Functions.isNullOrWhiteSpace(data.id) ||
                data.id !== GooglePayMessageID)
            {
                return;
            }
            
            switch (data.state)
            {
                case GooglePayState.InitPayment:
                    const validateResult = await onInitPayment(data.content, data.domain);
                    postMessage(GooglePayState.InitPayment, validateResult.payment.googleInfo.googleInfo);

                    const fingerprint = validateResult.payment.googleInfo.fingerprint.htmlContent;
                    const fragment = document
                        .createRange()
                        .createContextualFragment(fingerprint);
                    getFingerprint!.current.append(fragment);
                    
                    setIsLoading(false);
                    break;

                case GooglePayState.ConfirmPayment:
                    const result = await onConfirmPayment(data.content);
                    if (result.responseCode === ResponseCodeOption.Created) 
                    {
                        postMessage(GooglePayState.ConfirmPayment, {});
                        setIsLoading(true);
                        if (!result.payment.internationalInfo ||
                            !result.payment.internationalInfo.setupInfo) 
                        {
                            controller.navigateWithCurrentParams(PageRoutes.Payment.Finish);
                            return;
                        }
                        
                        // Register global function to window
                        (window as any).submitCheckEnrollment = () => 
                        {
                            controller.checkEnrollmentAsync().then(() => 
                            {
                                controller.navigateWithCurrentParams(PageRoutes.Payment.InternationalAuthenticate);
                            });
                        }
                        
                        const setupForm = result.payment.internationalInfo.setupInfo.htmlContent;
                        const fragment = document
                            .createRange()
                            .createContextualFragment(setupForm);
                        setupIFrame?.current.append(fragment);
                        return;
                    }

                    setTimeout(() =>
                    {
                        controller.queryPaymentAsync().then(() =>
                        {
                            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                        });
                    }, 1000);
                    break;

                case GooglePayState.CancelPayment:
                    break;

                case GooglePayState.ClosePayment:
                    await controller.processCancelAsync();
                    break;

                case GooglePayState.EndPayment:
                    const message = data.content;
                    await controller.processFailureAsync(message);
                    postMessage(GooglePayState.ConfirmPayment, false);
                    break;
            }
        });

        win.isGoogelPayInitial = true;
        postMessage(GooglePayState.ShowPayment, null);
    }, [isAvailable, controller, onInitPayment, onConfirmPayment]);

    return (
        <>
            <Components.Timeout store={controller.Transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
        </>
    );
}


export {GooglePayIFramePage};