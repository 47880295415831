import React, {useMemo, useState} from "react";
import {
    AppResources,
    Components,
    Functions,
    Images,
    IPageProperties,
    PageRoutes,
    PaymentController,
    Providers,
    QRCode,
    SourceData,
    SourceOfFunds,
    Tags,
    TransactionData,
    VariantOption
} from "Universal/Packages";


interface IBodyProperties
{
    controller: PaymentController,
    store: TransactionData
}

/*
interface ICountdownButton
{
    providerUrl: string,
    providerCode: string
}

const CountdownButton = (properties: ICountdownButton) =>
{
    const translate = Functions.translate();
    const providerName = translate(AppResources.Messages.PaymentProvider, {providerCode: properties.providerCode})
    const message = translate(AppResources.Messages.Redirect, {siteName: providerName});
    const showLoading = useLoading({contents: [[message]]});
    const [count, setCount] = useState(3);
    const timer: any = useRef(null);

    useEffect(() =>
    {
        timer.current = setInterval(() =>
        {
            setCount((value) => value - 1);
        }, 1000);

        return () => clearInterval(timer.current);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirect = () =>
    {
        showLoading({}, async () => { await Functions.sleep(60); });
        window.location.href = properties.providerUrl;
    }

    if (count === 0)
    {
        clearInterval(timer.current);
        redirect();
    }

    return (
        <Tags.Button className={"button margin-t-20"} onClick={redirect}>
            {translate(AppResources.Actions.RedirectTo, {siteName: providerName, seconds: count})}
        </Tags.Button>
    );
};
*/

const InfoMessage = (properties: any) =>
{
    const translate = Functions.translate();
    return (
        <div className={"modal-error-message-container"}>
            <Tags.Image src={Images.Icons.info} style={{height: "24px"}}/>
            <span className={"modal-error-message"}>
                    {translate(AppResources.Messages.MessageCode, {messageCode: properties.messageCode})}
            </span>
        </div>
    )
}

const FastPayTab = (properties: any) =>
{
    return (
        <Components.FastPayForm transaction={properties.transaction} onSubmit={properties.onSubmit}/>
    );
};

const QRTab = (properties: any) =>
{
    const transaction = properties.transaction;
    const payment = transaction.payment;
    const isMobile = Functions.isMobile();
    const isWeb = isMobile === false;
    const isGenerateQR = (isWeb && payment.isUseQR) || (isMobile && payment.isUseMobileQR);

    const qrInfo = transaction.payment.qrInfo;
    const qrLogo = Functions.format(Images.Logos.Provider, transaction.payment.providerCode);
    const qrData: string = qrInfo.qrCodeData || "";

    const qrCode = useMemo(() =>
    {
        return isGenerateQR
            ? <QRCode
                data={qrData}
                image={qrLogo}
                color={"#29195c"}
                style={{borderRadius: "8px"}}
                isDownload={true}
                button={{
                    variant: VariantOption.Filled,
                    className: "modal-button-download"
                }}
            />
            : <Tags.Image src={qrLogo} style={{width: "200px"}}/>;
    }, [isGenerateQR, qrData, qrLogo]);

    return (
        <>
            {qrCode}
        </>
    );
};

const Tab = (properties: any) =>
{
    const isActive = properties.isUseQR === false;
    const setIsUseQR = properties.setIsUseQR;
    const translate = Functions.translate();

    const getClassName = (isActive: boolean) =>
    {
        return isActive
            ? "modal-tab-button-active"
            : "modal-tab-button";
    };

    return (
        <Tags.Box style={{width: "100%"}}>
            <Tags.Row spacing={2}>
                <Tags.Column xs={6}>
                    <Tags.Button className={getClassName(isActive)}
                                 onClick={() => setIsUseQR(false)}
                    >
                        {translate(AppResources.Actions.FastPay)}
                    </Tags.Button>
                </Tags.Column>
                <Tags.Column xs={6}>
                    <Tags.Button className={getClassName(isActive === false)}
                                 onClick={() => setIsUseQR(true)}
                    >
                        {translate(AppResources.Actions.ScanQRCode)}
                    </Tags.Button>
                </Tags.Column>
            </Tags.Row>
        </Tags.Box>
    );
};

interface IQRBodyProperties extends IBodyProperties
{
    onSubmit: (value: string) => void,
    onTimeout: () => void,
    onCancel: () => void
}

const Body = (properties: IQRBodyProperties) =>
{
    const [isUseQR, setIsUseQR] = React.useState(false);
    const transaction = properties.store;
    const qrInfo = transaction.payment.qrInfo;
    const captureInfo = transaction.payment.captureInfo;
    if (!qrInfo)
    {
        return <Tags.Empty/>;
    }

    const onSubmit = (value: string) =>
    {
        properties.onSubmit(value);
    };

    const isPayWithPasscode =
        transaction.payment.providerCode === Providers.GalaxyPay && qrInfo.isPayWithPasscode;
    const isTokenInvalid =
        transaction.payment.providerCode === Providers.GalaxyPay && captureInfo && captureInfo.isTokenInvalid;

    const isShowInfoMessage =
        transaction.payment.providerCode === Providers.GalaxyPay &&
        (
            (qrInfo.isPayWithPasscode === false && qrInfo.resultCode !== "00") ||
            isTokenInvalid
        );

    const infoComponent = isShowInfoMessage
        ? <InfoMessage messageCode={isTokenInvalid ? captureInfo.messageCode : qrInfo.messageCode}/>
        : <Tags.Empty/>
    const content = isPayWithPasscode && isUseQR === false && !isTokenInvalid
        ? <FastPayTab transaction={transaction} onSubmit={onSubmit}/>
        : <QRTab transaction={transaction}/>;
    const body = isPayWithPasscode && !isTokenInvalid
        ? <><Tab isUseQR={isUseQR} setIsUseQR={setIsUseQR}/>{content}</>
        : <>{infoComponent}{content}</>;

    return (
        <>
            <Components.ProviderInfo store={transaction}/>
            <Components.Timeout store={transaction} onTimeout={properties.onTimeout}/>
            <div className={"modal-qr-information"}>
                {body}
            </div>
            <Components.SimpleCancelDialog onCancel={properties.onCancel}/>
            <Components.GuideInfo store={transaction}/>
        </>
    );
};

const QRPage = (properties: IPageProperties) =>
{
    const controller = new PaymentController();
    const [isLoading, setIsLoading] = useState(true);
    const transaction = controller.Transaction;

    const generateQR = (transaction: TransactionData) =>
    {
        if (!transaction.payment.qrInfo)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
            return;
        }

        const payment = transaction.payment;
        const isMobile = Functions.isMobile();
        const isWeb = isMobile === false;
        const isGenerateQR: boolean = (isWeb && payment.isUseQR) || (isMobile && payment.isUseMobileQR);

        if (isGenerateQR)
        {
            const onSocketReceive = () =>
            {
                controller.queryPaymentAsync().then();
            };

            controller.registerSocket(transaction, onSocketReceive);
            setIsLoading(false);
        }
        else if (payment.isSupportRedirectLink)
        {
            const redirectURL = transaction.payment.qrInfo.payUrl ?? "";
            if (window.top)
            {
                window.top.location.href = redirectURL;
            }
            else
            {
                window.location.href = redirectURL;
            }
        }
    };

    const initPayment = (transaction: TransactionData) =>
    {
        const source = new SourceData();
        source.paymentMethod = transaction.payment.paymentMethod;
        source.sourceOfFund = SourceOfFunds.None;
        
        controller
            .initPaymentAsync(source)
            .then(generateQR);
    }

    const onSubmit = async (value: string) =>
    {
        setIsLoading(true);
        await controller.capturePaymentAsync(value);
        setIsLoading(false);
    }

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    }

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    }

    controller.queryPayment({onSuccess: initPayment});
    const className = isLoading ? "" : "flex-justify-content-flex-start";
    const body = isLoading
        ? <Tags.Empty/>
        : <Body controller={controller}
                store={transaction}
                onSubmit={onSubmit}
                onTimeout={onTimeout}
                onCancel={onCancel}/>

    return (
        <controller.Page
            className={className}
            skin={properties.skin}
            store={transaction}
            controller={controller}
            isLoading={isLoading}
        >
            {body}
        </controller.Page>
    );
}


export {QRPage};