import {IContainerProperties} from "Core/Core";
import {Material} from "../MaterialPackage";


const CardContent = (properties: IContainerProperties) =>
{
    return (
        <Material.CardContent
            id={properties.id}
            className={properties.className}
        >
            {properties.children}
        </Material.CardContent>
    );
}


export {CardContent};