import React from "react";


interface ILoading
{
    inputRef?: React.RefObject<any>;
}

const CssLoading = (properties: ILoading) =>
{
    return (
        <div className="loading-icon-container" ref={properties.inputRef}>
            <div className='dot loading-icon'></div>
            <div className='dot loading-icon'></div>
            <div className='dot loading-icon'></div>
            <div className='dot loading-icon'></div>
            <div className='dot loading-icon'></div>
        </div>
    );
};

export {CssLoading};