import {useEffect, useState} from "react";
import {
    AppResources,
    ButtonTypeOption,
    Functions,
    Images,
    ResponseCodeOption,
    Tags,
    TransactionData
} from "Universal/Packages";


interface IResult
{
    icon: string,
    color: string,
    status: string
    isFinish: boolean,
    isPending?: boolean,
    isHasError: boolean,
    isSendBack: boolean,
    message?: string,
    errorMessage?: string
}

interface IResultProperties
{
    store: TransactionData,
    onSchedule: () => void
    onSendBack: () => Promise<void>
}

interface ITriggerButtonProperties
{
    store?: TransactionData,
    onClickAsync: () => Promise<void>
}

const Colors = {
    success: "#4FA800",
    failure: "#EB2629",
    warning: "#FDB913"
}

const getTransactionResult = (transaction?: TransactionData): IResult =>
{
    const translate = Functions.translate();

    if (!transaction ||
        !transaction.payment)
    {
        return {
            icon: Images.Icons.failure,
            color: Colors.failure,
            isFinish: true,
            isHasError: false,
            isSendBack: false,
            status: AppResources.Pages.Result.Status.Failure
        };
    }

    const ipn = transaction.ipn;
    if (!ipn)
    {
        return {
            icon: Images.Icons.warning,
            color: Colors.warning,
            isFinish: false,
            isPending: true,
            isHasError: false,
            isSendBack: false,
            status: AppResources.Pages.Result.Status.Pending
        };
    }

    const isSuccess: boolean = ipn.transactionStatus === ResponseCodeOption.Success;
    const message = AppResources.Pages.Result.SendBackMessage;
    if (isSuccess)
    {
        return {
            icon: Images.Icons.success,
            color: Colors.success,
            isFinish: true,
            isHasError: false,
            isSendBack: true,
            status: AppResources.Pages.Result.Status.Success,
            message: message
        };
    }
    else
    {
        const errorMessage = ipn.resultCode
            ? translate(AppResources.Messages.ResultCode, {resultCode: ipn.resultCode})
            : translate(AppResources.Pages.Exception.Message, {errorCode: ipn.transactionStatus})

        return {
            icon: Images.Icons.failure,
            color: Colors.failure,
            isFinish: true,
            isHasError: true,
            isSendBack: true,
            status: AppResources.Pages.Result.Status.Failure,
            message: message,
            errorMessage: errorMessage
        };
    }
};

const TriggerButton = (properties: ITriggerButtonProperties) =>
{
    const transaction = properties.store;
    if (!transaction ||
        !transaction.ipn)
    {
        return <Tags.Empty/>;
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <Tags.Button type={ButtonTypeOption.Button}
                         id={"btnSendResult"}
                         className="model-callback-secure-button"
                         onClickAsync={properties.onClickAsync}
                         textResource={AppResources.Actions.SendBack}/>
        </div>
    );
};

const ResultInfo = (properties: IResultProperties) =>
{
    const transaction = properties.store;
    const result: IResult = getTransactionResult(transaction);
    const [counter, setCounter] = useState(10);

    const sendResult = () =>
    {
        const button = document.getElementById("btnSendResult");
        if (button)
        {
            button.click();
        }
    };

    useEffect(() =>
    {
        if (counter > 0)
        {
            setTimeout(() =>
            {
                setCounter((value) => value - 1);
            }, 1000);
        }
        else
        {
            if (result.isFinish === false)
            {
                properties.onSchedule();
            }
            else
            {
                sendResult();
            }
        }
    });

    let message: string = "";
    if (result.message)
    {
        const translateHtml = Functions.translate({interpolation: {escapeValue: false}});
        message = translateHtml(
            result.message,
            {
                merchantName: transaction?.merchant?.merchantName,
                seconds: counter
            });
    }

    const translate = Functions.translate();
    const errorMessage = result.isHasError
        ? <Tags.Typography className={"error-message"} color="#D91A21"
                           marginTop="8px">{result.errorMessage}</Tags.Typography>
        : <Tags.Empty/>;
    const sendBackMessage = result.isSendBack
        ? <Tags.Typography marginBottom="1.0rem" dangerouslySetInnerHTML={{__html: message}}></Tags.Typography>
        : <Tags.Empty/>;
    const sendBackButton = result.isSendBack
        ? <TriggerButton store={transaction} onClickAsync={properties.onSendBack}/>
        : <Tags.Empty/>;
    const pendingMessage = result.isPending
        ? <Tags.Typography
            className={"pending-message"}>{translate(AppResources.Pages.Payment.SessionWarning)}</Tags.Typography>
        : <Tags.Empty/>;

    return (
        <Tags.Box className={"result-container"}>
            <Tags.Card className="card">
                <Tags.Image className="image" src={result.icon}/>
                <Tags.CardContent className="content">
                    <Tags.Typography
                        color={result.color}
                        className={"status"}
                        component="div">
                        {translate(result.status)}
                    </Tags.Typography>
                    {pendingMessage}
                    {errorMessage}
                    <Tags.Typography marginBottom="1.5rem" marginTop="1.5rem">
                        <span className={"text-gray"}>{translate(AppResources.Fields.TransactionID)}: {transaction?.transactionID}</span>
                    </Tags.Typography>
                    {sendBackMessage}
                </Tags.CardContent>
            </Tags.Card>
            {sendBackButton}
        </Tags.Box>
    );
};


export {ResultInfo};