import {SourceOfFunds} from "Universal/Packages";


class SourceData
{
    public paymentMethod?: string;
    public sourceOfFund?: string = SourceOfFunds.None;
    public sessionKey?: string;
    public apiOperation?: string;
    
    public cardNumber?: string;
    public cardHolderName?: string;
    public cardExpireDate?: string
    public cardVerificationValue?: string;

    public bankCode?: string;
    public accountBrand?: string;
    public accountName?: string;
    public accountNumber?: string;
    public identificationNumber?: string;
    
    public token?: string;

    public streetNumber?: string;
    public address01?: string;
    public address02?: string;
    public city?: string;
    public state?: string;
    public country?: string;
    public postalCode?: string;
    
    public serviceCode?: string;

    public validationURL?: string;
    public validationHost?: string;
}


export {SourceData};