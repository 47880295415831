import {Functions, GlobalTheme, ITheme, ThemeContext, Types} from "Core/References";

import {useContext} from "react";


class BaseController
{
    public Functions = Functions;
    public Theme: ITheme;

    public constructor()
    {
        this.Theme = this.getThemeContext();
    }

    public getResource = (message: Types.String): string =>
    {
        return this.Functions.getResource(message);
    }

    private getThemeContext = (): ITheme =>
    {
        try
        {
            return useContext<ITheme>(ThemeContext);
        }
        catch
        {
            // Fallback Case
            return GlobalTheme;
        }
    }
}


export {BaseController};