import {
    BaseRequest,
    BaseService,
    CaptureRequest,
    CheckEnrollmentRequest,
    FailureRequest,
    Functions,
    InitRequest,
    SendBackData,
    Settings,
    TransactionData,
    UpdateRequest,
} from "Universal/Packages";


class PaymentService extends BaseService
{
    protected Timeout = Functions.getNumberSetting(Settings.RequestTimeout) * 1000;
    private BaseURL: string = Functions.getServiceURL(Settings.ServiceURL);
    private ServiceURL = this.BaseURL + "/api/v1.0/secure/";

    protected get ServiceName(): string
    {
        return "PaymentService"
    };

    public getVersionAsync = async () =>
    {
        const url = this.BaseURL + "/healthz";
        const version: string | undefined = await this.get<string>(url);
        return Functions.escape(version);
    }

    public queryPaymentAsync = async (request: BaseRequest) =>
    {
        const url = this.ServiceURL + "queryPayment";
        return await this.post<TransactionData>(url, request);
    };

    public initPaymentAsync = async (request: InitRequest) =>
    {
        const url = this.ServiceURL + "initPayment";
        return await this.post<TransactionData>(url, request);
    };

    public updatePaymentAsync = async (request: UpdateRequest) =>
    {
        const url = this.ServiceURL + "updatePayment";
        return await this.post<TransactionData>(url, request);
    };

    public checkEnrollmentAsync = async (request: CheckEnrollmentRequest) =>
    {
        const url = this.ServiceURL + "checkPaymentEnrollment";
        return await this.post<TransactionData>(url, request);
    };

    public capturePaymentAsync = async (request: CaptureRequest) =>
    {
        const url = this.ServiceURL + "capturePayment";
        return await this.post<TransactionData>(url, request);
    };


    public processResultAsync = async (request: BaseRequest) =>
    {
        const url = this.ServiceURL + "processResult";
        return await this.post<SendBackData>(url, request);
    };

    public processCancelAsync = async (request: BaseRequest) =>
    {
        const url = this.ServiceURL + "processCancel";
        return await this.post<SendBackData>(url, request);
    };

    public processTimeoutAsync = async (request: BaseRequest) =>
    {
        const url = this.ServiceURL + "processTimeout";
        return await this.post<SendBackData>(url, request);
    };

    public processFailureAsync = async (request: FailureRequest) =>
    {
        const url = this.ServiceURL + "processFailure";
        return await this.post<SendBackData>(url, request);
    };
}


export {PaymentService};