import React, {useEffect} from "react";
import {Components, Functions, LanguageOption} from "Universal/Packages";
import {ISamsungPayProperties} from "./ISamsungPayProperties";

const SamsungPayMessageID = "GalaxyPay_SamsungPay";
const SamsungPayState = {
    ShowPayment: "ShowPayment",
    InitPayment: "InitPayment",
    ClosePayment: "ClosePayment",
    EndPayment: "EndPayment"
};

const win: any = (window as any);
win.isSamsungPayInitial = false;

const SamsungPayIFramePage = (properties: ISamsungPayProperties) =>
{
    const controller = properties.controller;
    const onInitPayment = properties.onInitPayment;
    const getFingerprint = properties.getFingerprint;

    const postMessage = (state: string, content: any) =>
    {
        const message = {
            id: SamsungPayMessageID,
            state: state,
            domain: window.location.host,
            content: content
        };

        const targetOrigin = (window as any).TargetOrigin ?? "*";
        Functions.logDebug("WINDOW POST MESSAGE", targetOrigin, message);
        window.parent.postMessage(message, targetOrigin);
    };

    const onTimeout = async () =>
    {
        await controller.processTimeoutAsync();
        postMessage(SamsungPayState.ClosePayment, false);
    };

    useEffect(() =>
    {
        if (win.isSamsungPayInitial)
        {
            return;
        }

        window.addEventListener("message", async (event: any) =>
        {
            const data = event.data;
            if (event.origin !== (window as any).TargetOrigin ||
                Functions.isNullOrWhiteSpace(data.id) ||
                data.id !== SamsungPayMessageID)
            {
                return;
            }

            switch (data.state)
            {
                case SamsungPayState.InitPayment:
                    const result = await onInitPayment(data.content, data.domain);
                    const countryCode = result.order.language === LanguageOption.Vietnamese
                        ? "vn"
                        : "us";

                    const fingerprint = result.payment.samsungInfo.fingerprint.htmlContent;
                    const fragment = document
                        .createRange()
                        .createContextualFragment(fingerprint);
                    getFingerprint!.current.append(fragment);
                    
                    const samsungInfo = result.payment.samsungInfo.samsungInfo;
                    setTimeout(() => {
                        postMessage(SamsungPayState.InitPayment, samsungInfo);
                    }, 2000);
                    break;
                    
                case SamsungPayState.ClosePayment:
                    await controller.processCancelAsync();
                    break;
            }
        });

        win.isSamsungPayInitial = true;
        postMessage(SamsungPayState.ShowPayment, null);
    }, [controller, onInitPayment]);

    return (
        <>
            <Components.Timeout store={controller.Transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
        </>
    );
}


export {SamsungPayIFramePage};