import {Functions} from "Universal/Packages";


class BaseRequest
{
    public transactionID?: string;
    public dateTimeExpire?: number;
    public signature?: string;

    public currentState!: string;

    private _requestID?: string;
    public get requestID()
    {
        if (this._requestID === undefined)
        {
            this._requestID = this.generateRequestID();
        }

        return this._requestID;
    };

    private generateRequestID = () =>
    {
        if (this.transactionID &&
            this.transactionID.length === 25)
        {
            return Functions.getTraceID(this.transactionID);
        }

        return Functions.getGUID();
    }
}


export {BaseRequest};