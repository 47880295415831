import {FontWeightOption, VariantOption} from "Core/Options/Options";
import {ITheme} from "Core/References";


const Theme: ITheme = {
    fonts: {
        primary: {
            fontFamily: "KoHo",
            fontWeight: FontWeightOption.Normal
        },
        secondary: {
            fontFamily: "Aria",
            fontWeight: FontWeightOption.Normal
        },
        icon: {
            fontFamily: "Aria",
            fontWeight: FontWeightOption.Normal
        }
    },
    styles: {
        button: {
            variant: VariantOption.Standard
        },
        input: {
            variant: VariantOption.Standard
        }
    }
};


export {Theme};