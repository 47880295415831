import {AppResources, Components, Functions, ISkin, React, Tags} from "Universal/Packages";


const Loading = () =>
{
    const translate = Functions.translate();

    return (
        <Tags.Container className={"loading-screen"}>
            <Tags.ImageLoading/>
            <div className="loading-message">
                {translate(AppResources.Messages.Processing)}
            </div>
        </Tags.Container>
    );
}

const DefaultSkin = (properties: ISkin): JSX.Element =>
{
    const Header: React.ElementType = properties.header ?? Components.Header;
    const Footer: React.ElementType = properties.footer ?? Components.Footer;
    const isLoading = properties.isLoading ?? false;
    const body = isLoading ? <Loading/> : properties.body;

    return (
        <>
            <Header id={"page-header"}/>
            <div id={"page-body"} className={properties.className}>
                {body}
            </div>
            <Footer id={"page-footer"}/>
        </>
    );
};


export {DefaultSkin};