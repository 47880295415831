const PageRoutes = {
    Home: "home",
    Error: "error",
    Payment: {
        Index: "pay",
        Domestic: "pay/domestic",
        DomesticAuthenticate: "pay/domestic/authenticate",
        International: "pay/international",
        InternationalSetup: "pay/international/setup",
        InternationalAuthenticate: "pay/international/authenticate",
        QR: "pay/qr",
        Hub: "pay/hub",
        PayLater: "pay/pay_later",
        ApplePay: "pay/apple_pay",
        SamsungPay: "pay/samsung_pay",
        GooglePay: "pay/google_pay",

        Result: "pay/result",
        Finish: "pay/finish",
        Pending: "pay/pending"
    },
    Checkout: {
        Index: "checkout",
        Domestic: "checkout/domestic",
        International: "checkout/international",
        Wallet: "checkout/wallet",
        Hub: "checkout/hub",
        PayLater: "checkout/pay_later",
        ApplePay: "checkout/apple_pay",
        SamsungPay: "checkout/samsung_pay",
        GooglePay: "checkout/google_pay",
        
        Finish: "checkout/finish"
    },
    PayWithOption: {
        Index: "payWithOption"
    }
}


export {PageRoutes};