enum ResponseCodeOption
{
    Success = "200",
    Created = "201",

    BadRequest = "400",
    Unauthorized = "401",
    NotFound = "404",

    Expired = "408",
    ClientCancel = "499",

    Error = "500",
    BadGateway = "502",
    ServiceUnavailable = "503",
    Timeout = "504",
    PaymentSourceNotAvailable = "599"
}


export {ResponseCodeOption};