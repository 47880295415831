import * as Material from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {IFormik} from "Core/Interfaces/Libraries/IFormik";
import {AppResources, FieldController, Functions, IFieldProperties} from "Core/References";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/vi";
import updateLocale from "dayjs/plugin/updateLocale"
import {useFormikContext} from "formik";


dayjs.extend(updateLocale)
dayjs.updateLocale("vi", {
    months: [
        "Tháng 01,", "Tháng 02,", "Tháng 03,", "Tháng 04,", "Tháng 05,", "Tháng 06,", "Tháng 07,",
        "Tháng 08,", "Tháng 09,", "Tháng 10,", "Tháng 11,", "Tháng 12,"
    ],
    monthsShort: [
        "Tháng 01", "Tháng 02", "Tháng 03", "Tháng 04", "Tháng 05", "Tháng 06", "Tháng 07",
        "Tháng 08", "Tháng 09", "Tháng 10", "Tháng 11", "Tháng 12"
    ]
});

const DatePickerField = (properties: IFieldProperties) =>
{
    const formik = useFormikContext() as IFormik<any>;
    const controller = new FieldController(properties, formik);
    const translate = Functions.translate();
    const defaultValue = properties.value ? dayjs(properties.value, "MM/YY") : null;
    const onChange = (result: any) =>
    {
        let value;
        if (!result ||
            result.$d.toString() === "Invalid Date")
        {
            value = "";
        }
        else
        {
            const date = result.$d;
            const month = (date.getMonth() + 1).toString();
            const year = date.getYear().toString();
            value = Functions.padLeft(month, 2, "0") + "/" + Functions.right(year, 2);
        }

        formik.touched[properties.name] = true;
        formik.setFieldValue(properties.name, value);
        formik.validateField(properties.name);
    };

    const onFocus = () =>
    {
        formik.touched[properties.name] = true;
    }

    return (
        <div>
            <Material.LocalizationProvider dateAdapter={AdapterDayjs}
                                           adapterLocale={controller.Language}
                                           localeText={{
                                               datePickerToolbarTitle: `${translate(AppResources.Messages.DatePicker.Title)}`,
                                               cancelButtonLabel: `${translate(AppResources.Actions.Close)}`,
                                               okButtonLabel: `${translate(AppResources.Actions.Confirm)}`
                                           }}>
                <Material.DesktopDatePicker
                    views={["month", "year"]}
                    format={"MM/YY"}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    label={controller.Text}
                    minDate={dayjs("2000")}
                    slotProps={{
                        textField: {
                            required: properties.required,
                            error: controller.IsHasError,
                            helperText: controller.HelpText,
                            onFocus: onFocus,
                            inputMode: "numeric"
                        }
                    }}
                />
            </Material.LocalizationProvider>
        </div>
    );
};


export {DatePickerField}