import {IBasicProperties} from "Core/References";
import {Material} from "../MaterialPackage";
import React from "react";


const Container = (properties: IBasicProperties): React.JSX.Element =>
{
    const width = properties.width ?? "100%";
    const height = properties.height ?? "100%";

    return (
        <Material.Box
            id={properties.id}
            className={properties.className}
            style={properties.style}
            width={width}
            height={height}
            onClick={properties.onClick}
        >
            <Material.Container>
                {properties.children}
            </Material.Container>
        </Material.Box>
    );
};


export {Container};