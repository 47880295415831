import {AppResources, Functions, Images, Providers, Tags, TransactionData} from "Universal/Packages";


interface IGuideProperties
{
    store: TransactionData
}

const SupportApp = () =>
{
    const translate = Functions.translate();
    return (
        <div>
            <p className="modal-app-support-message">{translate(AppResources.Pages.Payment.SupportApp)}</p>
            <div className={"modal-app-support-content"}>
                <div className={"margin-right-40"}>
                    <Tags.Image src={Images.Logos.AppSupport.VietjetAir} style={{height: "49px"}}/>
                    <p className={"margin-top-8"}>Vietjet Air</p>
                </div>
                <div className={"margin-right-40"}>
                    <Tags.Image src={Images.Logos.AppSupport.GalaxyPay} style={{height: "49px"}}/>
                    <p className={"margin-top-8"}>Galaxy Pay</p>
                </div>
                <div>
                    <Tags.Image src={Images.Logos.AppSupport.SkyJoy} style={{height: "49px"}}/>
                    <p className={"margin-top-8"}>SkyJoy</p>
                </div>
            </div>
        </div>
    );
}

const GuideInfo = (properties: IGuideProperties) =>
{
    const transaction = properties.store;
    const body = transaction.payment.providerCode === Providers.GalaxyPay ? <SupportApp/> : <></>;

    return (
        <>
            {body}
        </>
    );
};


export {GuideInfo};