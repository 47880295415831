import {faro, LogLevel} from "@grafana/faro-web-sdk";
import {
    LanguageOption,
    LogLevelOption,
    ObServerBusiness,
    PatternOption,
    SettingOption,
    Types,
    VariantOption
} from "Core/References";
import * as Crypto from "crypto-js";

import i18next from "i18next";
import * as lodash from "lodash";
import * as device from "react-device-detect";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {v4 as guid} from "uuid";
import {Events} from "./Events";


const subscribeEvent = (event: string, handler: (...args: any[]) => void) =>
{
    ObServerBusiness.subscribe(event, handler);
};

const publishEvent = (event: string, ...args: any[]) =>
{
    ObServerBusiness.publish(event, ...args);
};

const encrypt = (base64Key?: string, clearText?: string) =>
{
    if (!base64Key ||
        !clearText)
    {
        return "";
    }

    const key = Crypto.enc.Base64.parse(base64Key);
    return Crypto.AES
        .encrypt(clearText, key, {
            mode: Crypto.mode.ECB,
            padding: Crypto.pad.Pkcs7
        })
        .toString();
};

const decrypt = (base64Key: string, encryptedText: string) =>
{
    if (!base64Key)
    {
        return "";
    }

    try
    {
        const key = Crypto.enc.Base64.parse(base64Key);
        return Crypto.AES
            .decrypt(encryptedText, key, {
                mode: Crypto.mode.ECB,
                padding: Crypto.pad.Pkcs7
            })
            .toString(Crypto.enc.Utf8);
    }
    catch
    {
        return encryptedText;
    }
}

const ChangeLanguage = (language?: string) =>
{
    const currentLanguage = i18next.language;
    if (language === currentLanguage)
    {
        return;
    }

    if (!language)
    {
        language = i18next.language === LanguageOption.Vietnamese
            ? LanguageOption.English
            : LanguageOption.Vietnamese;
    }

    i18next
        .changeLanguage(language)
        .then();
};

const getCurrentLanguage = () =>
{
    return i18next.language;
}

const coalesce = (...params: Types.String[]) =>
{
    for (let i in params)
    {
        const value: Types.String = params[i];
        if (isNullOrWhiteSpace(value) === false)
        {
            return value;
        }
    }

    return "";
};

const escape = (value: Types.String): string =>
{
    if (isNullOrEmpty(value))
    {
        return "";
    }

    return value + "";
};

const format = (source: string, ...values: string[]) =>
{
    return source.replace(/{(\d+)}/g, function (match, number)
    {
        return typeof values[number] != 'undefined'
            ? values[number]
            : match
    });
};

const formatCurrency = (amount: number, currency: string): string =>
{
    return `${new Intl.NumberFormat().format(amount)} ${currency}`;
}

const getCssKebabCase = (propertyName: string) =>
{
    const regex = new RegExp(/[A-Z]/g)
    return propertyName.replace(regex, char => `-${char.toLowerCase()}`)
};

const getCurrentDateTime = (pattern?: string): number =>
{
    return getDateTime(new Date(), pattern);
};

const getDateTime = (date: Date, pattern?: string): number =>
{
    return Number(getDateTimeToString(new Date(), pattern));
};

const getDateTimeToString = (date: Date, pattern?: string): string =>
{
    const year = date.getFullYear();
    const month = padLeft((date.getMonth() + 1).toString(), 2, "0");
    const day = padLeft(date.getDate().toString(), 2, "0");
    const hours = padLeft(date.getHours().toString(), 2, "0");
    const minutes = padLeft(date.getMinutes().toString(), 2, "0");
    const seconds = padLeft(date.getSeconds().toString(), 2, "0");
    const milliseconds = padLeft(date.getMilliseconds().toString(), 3, "0");
    const timezone = getTimezoneOffset(date);

    switch (pattern)
    {
        case PatternOption.DateTimeLog:
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds} ${timezone}`;

        case PatternOption.DateTimeDisplay:
            return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

        case PatternOption.ShortDate:
            return `${right(year.toString(), 2)}${month}${day}`;

        case PatternOption.Date:
            return `${year}${month}${day}`;

        default:
            return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }
};

const getFutureDateTime = (seconds: number, pattern?: string): number =>
{
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);

    return getDateTime(date, pattern);
};

const getLastMonth = (): Date =>
{
    const date = new Date();
    date.setDate(0);
    date.setDate(1);

    return date;
};

const getEnvironmentName = (): string =>
{
    return process.env.NODE_ENV + "";
};

const getTraceID = (transactionID: string): string =>
{
    return "0000000" + transactionID;
};

const getGUID = (): string =>
{
    return guid().replaceAll("-", "");
};

const getObjectDefinition = (source: any) =>
{
    let result: any = {};
    for (let key in source)
    {
        if (typeof source[key] !== "object")
        {
            result[key] = key;
        }
        else
        {
            let data = {...source[key]}
            data = getObjectDefinition(data);
            result[key] = data;
        }
    }
    return result;
};

const getResource = (message: Types.String): string =>
{
    if (isNullOrWhiteSpace(message))
    {
        return "";
    }

    return TranslateResource(message as string);
};

const getResourceDefinition = (source: any, extraKey: string = "") =>
{
    let result: any = {};
    for (let key in source)
    {
        if (typeof source[key] !== "object" ||
            source[key].length > 0)
        {
            result[key] = extraKey + key;
        }
        else
        {
            let data = {...source[key]}
            data = getResourceDefinition(data, `${extraKey}${key}.`);
            result[key] = data;
        }
    }
    return result;
};

const getSetting = (key: string): string =>
{
    return escape(process.env[key] as string);
};

const getNumberSetting = (key: string): number =>
{
    return toNumber(process.env[key] as string);
};

const getTimezoneOffset = (date: Date) =>
{
    const format = (value: number) =>
    {
        return padLeft(value.toString(), 2, "0");
    }

    let offset = date.getTimezoneOffset();
    const sign = offset < 0 ? '+' : '-';
    offset = Math.abs(offset);
    return sign + format(offset / 60 | 0) + ":" + format(offset % 60);
};

const getButtonVariant = (variant: VariantOption | undefined): "outlined" | "contained" | "text" | undefined =>
{
    if (!variant)
    {
        return undefined;
    }

    switch (variant)
    {
        case VariantOption.Text:
            return "text";

        case VariantOption.Filled:
            return "outlined";

        default:
            return "contained";
    }
};

const getTextVariant = (variant: VariantOption | undefined): any =>
{
    if (!variant)
    {
        return undefined;
    }

    switch (variant)
    {
        case VariantOption.Text:
            return "standard";

        case VariantOption.Filled:
            return "filled";

        default:
            return "outlined";
    }
};

const importStyle = (url: string, id?: string) =>
{
    if (!id)
    {
        id = getGUID();
    }

    if (document.getElementById(id))
    {
        return;
    }

    const style = document.createElement("link");
    style.id = id;
    style.rel = "stylesheet";
    style.href = url;
    document.body.append(style);
};

const importScript = (url: string, id?: string) =>
{
    if (!id)
    {
        id = getGUID();
    }

    if (document.getElementById(id))
    {
        return;
    }

    const script = document.createElement("script");
    script.id = id;
    script.type = "text/javascript";
    script.src = url;
    document.body.append(script);
};

const isDevelopment = (): boolean =>
{
    return true;
    // return escape(process.env.NODE_ENV).toLowerCase() !== "production";
};

const isInArray = (value: string, ...params: Array<string>): boolean =>
{
    for (const i in params)
    {
        const data = params[i];
        if (value === data)
        {
            return true;
        }
    }

    return false;
}

const isNumberInArray = (value: number, ...params: Array<number>): boolean =>
{
    for (const i in params)
    {
        const data = params[i];
        if (value === data)
        {
            return true;
        }
    }

    return false;
}

const isInvalidCard = (value: string): boolean =>
{
    if (isNullOrEmpty(value))
    {
        return false;
    }

    value = value.replace(/\s/g, "");
    if (/\D+/.test(value))
    {
        return true;
    }

    if (isNumberInArray(value.length, 13, 15, 16, 19) === false)
    {
        return true;
    }

    // Check Digit Card (lumn mod 10)
    let nCheck = 0;
    let bEven = false;
    value = value.replace(/\D/g, "");
    for (let n = value.length - 1; n >= 0; n--)
    {
        let cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);
        if (bEven && (nDigit *= 2) > 9)
        {
            nDigit -= 9;
        }
        nCheck += nDigit;
        bEven = !bEven;
    }

    return nCheck % 10 !== 0;
}

const isMobile = (): boolean =>
{
    const detector = getSetting(SettingOption.DeviceDetector).toUpperCase();
    return detector === "MOBILE"
        ? true
        : device.isMobile;
};

const isWebView = (): boolean =>
{
    // apple device only
    return /((iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(navigator.userAgent);
}

const isIframe = (): boolean =>
{
    return (window.self ? window.self : window) !== window.parent;
}

const isNumber = (value: string): boolean =>
{
    return /^\d+$/.test(value);
};

const isNullOrEmpty = (value: Types.String): boolean =>
{
    return (
        value === undefined ||
        value === null ||
        value === ""
    );
};

const isNullOrUndefined = (value: any) =>
{
    return value === null || typeof value === "undefined";
};

const isNullOrWhiteSpace = (value: Types.String): boolean =>
{
    return (
        value === undefined ||
        value === null ||
        value === "" ||
        value.match(/^ *$/) !== null
    );
};

const isObject = (item: any) =>
{
    return (item && typeof item === "object" && !Array.isArray(item));
};

const isUseConsoleLog = (): boolean =>
{
    return isDevelopment() && getSetting(SettingOption.ConsoleLog).toUpperCase() === "ON";
};


const left = (value: Types.String, length: number): string =>
{
    if (!value)
    {
        return "";
    }

    const safeLength = Math.min(length, value.length);
    return value.substring(0, safeLength);
};

const buildFaroLog = (...data: any): string =>
{
    const trace = data?.transactionID
        ? data.transactionID
        : Functions.getGUID();

    const log = {
        Message: `${getDateTimeToString(new Date(), PatternOption.DateTimeLog)} - ${trace}`,
        Content: data
    }

    return JSON.stringify(log);
}

const log = (logLevel: LogLevelOption, data: any) =>
{
    if (isUseConsoleLog() === false)
    {
        return;
    }

    let type;
    let method;
    switch (logLevel)
    {
        case LogLevelOption.Info:
            type = "INF";
            method = console.info;
            break;

        case LogLevelOption.Warning:
            type = "WRN";
            method = console.warn;
            break;

        case LogLevelOption.Error:
            type = "ERR";
            method = console.error;
            break;

        default:
            type = "DBG";
            method = console.log;
            break;
    }

    const message = `[${getDateTimeToString(new Date(), PatternOption.DateTimeLog)} ${type}]`;
    method(message, data);
};

const logDebug = (...data: any): void =>
{
    log(LogLevelOption.Debug, data);
};

const logError = (...data: any): void =>
{
    log(LogLevelOption.Error, data);

    if (faro.api)
    {
        try
        {
            faro.api.pushError(new Error(buildFaroLog(data)));
        }
        catch
        {
        }
    }
};

const logInformation = (...data: any): void =>
{
    log(LogLevelOption.Info, data);

    if (faro.api)
    {
        try
        {
            faro.api.pushLog([buildFaroLog(data)], {level: LogLevel.INFO});
        }
        catch
        {
        }
    }
};

const logWarning = (...data: any): void =>
{
    log(LogLevelOption.Warning, data);
};

const merge = (source: object, config: Types.Object): object =>
{
    return lodash.merge(source, config);
};

const padLeft = (value: string, totalLength: number, paddingChar: string) =>
{
    const result = paddingChar.repeat(totalLength) + value;
    return right(result, totalLength);
};

const padRight = (value: string, totalLength: number, paddingChar: string) =>
{
    const result = value + paddingChar.repeat(totalLength);
    return left(result, totalLength);
};

const removeVietnamese = (value: Types.String): string =>
{
    if (isNullOrEmpty(value))
    {
        return "";
    }

    value = value + "";
    return value
        .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
        .replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A")
        .replace(/[èéẹẻẽêềếệểễ]/g, "e")
        .replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E")
        .replace(/[ìíịỉĩ]/g, "i")
        .replace(/[ÌÍỊỈĨ]/g, "I")
        .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
        .replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O")
        .replace(/[ùúụủũưừứựửữ]/g, "u")
        .replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U")
        .replace(/[ỳýỵỷỹ]/g, "y")
        .replace(/[ỲÝỴỶỸ]/g, "Y")
        .replace(/đ/g, "d")
        .replace(/Đ/g, "D")
        .replace(/[[\]|\\@.,/#!$%^&*;:{}=\-+_`~()<>?]/g, "")
        // eslint-disable-next-line no-control-regex
        .replace(/[^\x00-\x7F]/g, "");
};

const random = (range: number): number =>
{
    return Math.floor(Math.random() * range);
};

const right = (value: Types.String, length: number): string =>
{
    if (!value)
    {
        return "";
    }

    const safeLength = Math.max(value.length - length, 0);
    return value.substring(safeLength);
};

const sleep = async (seconds: number) =>
{
    return await new Promise(executor => setTimeout(executor, seconds * 1000));
};

const toDate = (value: string): Date =>
{
    if (isNullOrWhiteSpace(value))
    {
        return new Date(1900, 0, 1);
    }

    const length = value.length;
    let year = Number(value.substring(0, 4));
    let month = Number(value.substring(4, 6));
    let date = Number(value.substring(6, 8));
    let hour = 0;
    let minute = 0;
    let second = 0;
    if (length === 14)    // yyyyMMddHHmmss
    {
        hour = Number(value.substring(8, 10));
        minute = Number(value.substring(10, 12));
        second = Number(value.substring(12));
    }

    return new Date(year, month - 1, date, hour, minute, second);
}

const toNumber = (value?: string, defaultValue?: number) =>
{
    defaultValue = defaultValue ?? 0;

    const result = Number(value);
    return Number.isNaN(result) ? defaultValue : result;
};

const Translate = (options?: any, namespace?: string) =>
{
    return useTranslation(namespace, options).t
};

const TranslateResource = (message: string): string =>
{
    const translate = useTranslation().t;
    return translate(message);
};

const useQueryParams = () =>
{
    return useParams();
};

const getServiceURL = (url: string) =>
{
    const serviceURL: string = getSetting(url);
    if (serviceURL === "/")
    {
        const browserURL = window.location.origin.toLowerCase();
        if (browserURL.endsWith("galaxypay.vn"))
        {
            return browserURL;
        }
    }

    return serviceURL;
}

export const Functions = {
    changeLanguage: ChangeLanguage,
    getCurrentLanguage,
    coalesce,
    escape,
    encrypt,
    decrypt,
    format,
    formatCurrency,
    getCssKebabCase,
    getCurrentDateTime,
    getDateTime,
    getDateTimeToString,
    getEnvironmentName,
    getFutureDateTime,
    getLastMonth,
    getTraceID,
    getGUID,
    getNumberSetting,
    getObjectDefinition,
    getResource,
    getResourceDefinition,
    getSetting,
    getButtonVariant,
    getTextVariant,
    importStyle,
    importScript,
    isDevelopment,
    isNumberInArray,
    isInArray,
    isInvalidCard,
    isMobile,
    isWebView,
    isIframe,
    isNullOrEmpty,
    isNullOrUndefined,
    isNullOrWhiteSpace,
    isNumber,
    isObject,
    isUseConsoleLog,
    left,
    logDebug,
    logError,
    logInformation,
    logWarning,
    merge,
    padLeft,
    padRight,
    random,
    removeVietnamese,
    right,
    sleep,
    toDate,
    toNumber,
    getServiceURL,

    // DO NOT USE INSIDE EVENT_HANDLER DUE TO RULES OF HOOK
    translate: Translate,
    useQueryParams: useQueryParams,

    // Events
    subscribeEvent,
    publishEvent,
    ...Events
}