import React from "react";
import {Material} from "../MaterialPackage";


const IconButton = (properties: any) =>
{
    return (
        <Material.IconButton {...properties}/>
    );  
};


export {IconButton};