import {useEffect, useState} from "react";
import {AppResources, Components, Functions, Images, LanguageOption, React, Tags} from "Universal/Packages";
import {ISamsungPayProperties} from "Universal/Pages/Payment/Methods/SamsungPay/ISamsungPayProperties";


const win: any = (window as any);
const SamsungPaySelfPage = (properties: ISamsungPayProperties) =>
{
    const controller = properties.controller;
    const translate = Functions.translate();
    const onCancel = properties.onCancel;
    const onInitPayment = properties.onInitPayment
    const setIsLoading = properties.setIsLoading;
    const store = properties.store;
    const cancelButton = <Components.SimpleCancelDialog
        cancelButton={Components.WalletCancelButton}
        onCancel={onCancel}/>
    const [samsungPayButton, setSamsungPayButton] = useState<any>(<Tags.Empty/>);
    const getFingerprint = properties.getFingerprint;

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onPayClick = async () =>
    {
        const domain = window.location.host;
        const result = await onInitPayment("", domain);

        const fingerprint = result.payment.samsungInfo.fingerprint.htmlContent;
        const fragment = document
            .createRange()
            .createContextualFragment(fingerprint);
        getFingerprint!.current.append(fragment);
        
        const countryCode = result.order.language === LanguageOption.Vietnamese
            ? "vn"
            : "us";
        const samsungInfo = result.payment.samsungInfo.samsungInfo;
        setTimeout(() => {
            // @ts-ignore
            SamsungPay.connect(
                samsungInfo.id,
                samsungInfo.href,
                samsungInfo.serviceID,
                samsungInfo.callbackURL,
                samsungInfo.cancelURL,
                countryCode,
                samsungInfo.publicKeyModulus,
                samsungInfo.publicKeyExponent,
                samsungInfo.privateKey);
        }, 2000);
    }

    const samsungPayAvailable = async () =>
    {
        try
        {
            // @ts-ignore
            win.SamsungPaySDK = SamsungPay;
            if (Functions.isNullOrUndefined(win.SamsungPaySDK))
            {
                return false;
            }

            if (win.SamsungPaySDK.checkMobileAndRunnable())
            {
                return await win.SamsungPaySDK.checkSamsungMobile;
            }
            else
            {
                return true;
            }
        }
        catch (e)
        {
            return false;
        }
    }

    useEffect(() =>
    {
        const samsungButton = <Tags.Button className={"modal-button-samsung-pay"} onClick={onPayClick}>
            <Tags.Image src={Images.Buttons.SamsungPay} style={{height: "40px"}}/>
        </Tags.Button>

        samsungPayAvailable().then((isSamsungPayAvailable) =>
        {
            if (isSamsungPayAvailable)
            {
                setSamsungPayButton(
                    <>
                        {samsungButton}
                        {cancelButton}
                    </>
                );
            }
            else
            {
                setSamsungPayButton(
                    <div className={"modal-align-center-button-apple"}>
                        <p>{translate(AppResources.Pages.Payment.SamsungPayUnavailable)}</p>
                        {cancelButton}
                    </div>
                );
            }
        })
    }, []);

    const orderInfo = <Components.WalletOrderInfo store={store} onTimeout={onTimeout}/>
    return (
        <>
            {orderInfo}
            {samsungPayButton}
        </>
    );
}


export {SamsungPaySelfPage};