import {AppResources} from "Core/Controllers/Controllers";
import {Functions, Tags} from "Core/References";


const Loading = () =>
{
    const translate = Functions.translate();

    return (
        <Tags.Container className={"loading-screen"}>
            <Tags.CssLoading/>
            <div className="loading-message">
                {translate(AppResources.Messages.Processing)}
            </div>
        </Tags.Container>
    );
}


export {Loading};