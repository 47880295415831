import {useEffect, useRef, useState} from "react";
import {
    Components,
    IPageProperties,
    PageRoutes,
    PaymentController,
    Providers,
    React,
    SourceData,
    SourceOfFunds,
    Tags,
    TransactionData
} from "Universal/Packages";

const HubPage = (properties: IPageProperties) =>
{
    const authenticationForm = useRef<any>();
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    const [isLoading, setIsLoading] = useState(false);

    const onTimeout = async () =>
    {
        setIsLoading(true);
        await controller.processTimeoutAsync();
    };

    const onCancel = async () =>
    {
        setIsLoading(true);
        await controller.processCancelAsync();
    }

    const onSocketReceive = () =>
    {
        controller.queryPaymentAsync().then();
    };

    const initPayment = (transaction: TransactionData) =>
    {
        switch (transaction.payment.providerCode)
        {
            case Providers.Apple:
                controller.navigateWithCurrentParams(PageRoutes.Payment.ApplePay);
                return;

            case Providers.Samsung:
                controller.navigateWithCurrentParams(PageRoutes.Payment.SamsungPay);
                return;

            case Providers.Google:
                controller.navigateWithCurrentParams(PageRoutes.Payment.GooglePay);
                return;
        }

        const source = new SourceData();
        source.paymentMethod = transaction.payment.paymentMethod;
        source.sourceOfFund = SourceOfFunds.None;

        controller
            .initPaymentAsync(source)
            .catch(() => {});
    }

    useEffect(() =>
    {
        if (transaction.payment.hubInfo)
        {
            const contentType = (transaction.payment.hubInfo.contentType + "").toUpperCase();
            switch (contentType)
            {
                case "SCRIPT":
                    // Register Socket
                    controller.registerSocket(transaction, onSocketReceive);

                    const providerContent = transaction.payment.hubInfo.bodyContent || "";
                    const messageContent = transaction.payment.hubInfo.messageContent || "";
                    const htmlContent = transaction.payment.hubInfo.htmlContent || "";
                    const fragmentContent = providerContent + messageContent + htmlContent;
                    const fragment = document
                        .createRange()
                        .createContextualFragment(fragmentContent);
                    authenticationForm.current.removeChild(authenticationForm.current.children[0]);
                    authenticationForm.current.append(fragment);
                    break;

                default:
                    const browser = window.top || window.self;
                    browser.location.href = transaction.payment.hubInfo.payUrl;
                    break;
            }
        }
    }, [transaction.payment.hubInfo]);

    controller.queryPayment({onSuccess: initPayment});

    const isHideCountdown =
        isLoading ||
        transaction.payment.hubInfo == null ||
        transaction.payment.hubInfo.isCancel === false;
    const loading = isLoading ? <Tags.Empty/> : <Tags.Loading/>;
    const btnCancel = transaction.payment.hubInfo?.isCancel
        ? <Components.SimpleCancelDialog onCancel={onCancel}/>
        : <Tags.Empty/>;

    return (
        <controller.Page
            skin={properties.skin}
            store={transaction}
            controller={controller}
            isLoading={isLoading}
        >
            <Components.Timeout store={transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={isHideCountdown}
                                cssClass="margin-top-24"/>
            <div ref={authenticationForm} style={{marginTop: "10px", width: "100%"}}>
                {loading}
            </div>
            {btnCancel}
        </controller.Page>
    );
};


export {
    HubPage
};