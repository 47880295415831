import {AppResources, FieldController, Functions, IFormik, IOption, ISelectProperties, Tags} from "Core/References";

import {useFormikContext} from "formik";
import React, {useEffect} from "react";
import {Material} from "../MaterialPackage";


const SelectField = (properties: ISelectProperties): React.JSX.Element =>
{
    const translate = Functions.translate();
    const defaultOption = {value: ""};
    const findOption = (value?: string) =>
    {
        return properties.options?.find(obj =>
        {
            return obj.value === value;
        });
    };

    const defaultValue = findOption(properties.value);
    const [selectedOption, setSelectedOption] = React.useState(defaultValue ?? defaultOption);
    const formikContext = useFormikContext() as IFormik<any>;
    const controller = new FieldController(properties, formikContext);
    const inputProps = controller.getCustomValidity(translate(AppResources.Validations.SelectValidity));
    const options = properties.options ?? [];

    const getOptionLabel = (option?: IOption) =>
    {
        if (!option)
        {
            return "";
        }

        return controller.getResource(option.textResource) ?? option.text ?? "";
    }
    const getOptionValue = (option: IOption, value: any) => option.value === value.value || value === "";
    const onBlur = () => formikContext.setTouched({[properties.name]: true});
    const onChange = (event: any, option: any) =>
    {
        formikContext.setFieldValue(properties.name, option?.value ?? "");

        if (properties.onChange)
        {
            properties.onChange(option);
        }

        setSelectedOption(option);
    };

    const renderInput = (params: any) =>
    {
        params = Functions.merge(params, inputProps);
        const selectedValue = selectedOption && properties.renderValue
            ? properties.renderValue(selectedOption)
            : <Tags.Empty/>;
        const icon = selectedOption && Functions.isNullOrWhiteSpace(selectedOption.value) === false
            ? <Tags.InputAdornment position="start">{selectedValue}</Tags.InputAdornment>
            : null;

        return (
            <Material.TextField
                {...params}
                name={properties.name}
                required={properties.required}
                label={controller.Text}
                placeholder={controller.PlaceHolder}
                helperText={controller.HelpText}
                error={controller.IsHasError}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: icon
                }}
            />
        );
    };

    useEffect(() =>
    {
        const selectedValue = formikContext.values[properties.name];
        if (Functions.isNullOrWhiteSpace(selectedValue) === false)
        {
            const currentValue = formikContext.values[properties.name];
            const option = findOption(currentValue);
            if (!option)
            {
                formikContext.setFieldValue(properties.name, "");
                setSelectedOption(defaultOption);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties.options])

    return (
        <Material.Autocomplete
            id={controller.Id}
            className={properties.className}
            style={properties.style}
            readOnly={properties.readonly}
            openOnFocus={true}
            autoHighlight={true}
            // placeholder={controller.PlaceHolder}
            onBlur={onBlur}
            onChange={onChange}
            options={options}
            renderOption={properties.renderOption}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionValue}
            renderInput={renderInput}
            value={selectedOption}
            clearOnBlur={true}
        />
    );
};


export {SelectField};