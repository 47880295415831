import {AppResources, Components, Functions, TransactionData} from "Universal/Packages";


interface IFastPayFormProperties
{
    transaction: TransactionData,
    onSubmit: (value: string) => void
}

const FastPayForm = (properties: IFastPayFormProperties) =>
{
    const translate = Functions.translate();
    const captureInfo = properties.transaction.payment.captureInfo;
    const isShowErrorMessage = captureInfo && captureInfo.status === "FAILURE" && !captureInfo.isTokenInvalid;
    let infoComponent = <></>;
    if (isShowErrorMessage)
    {
        const message = translate(AppResources.MessageCodes.GP_05, {retryNumber: captureInfo.retryNumber});
        infoComponent = <p className="modal-content-pin-message">{message}</p>
    }

    return (
        <div>
            <div className="modal-content-pin">
                <p className="modal-content-pin-title">
                    {translate(AppResources.Pages.Payment.SkyPayPIN)}
                </p>
                <Components.OTPForm
                    length={6}
                    className="modal-OTP-Content password"
                    onSubmit={otp =>
                    {
                        properties.onSubmit(otp);
                    }}
                />
                {infoComponent}
            </div>
        </div>
    );
};


export {FastPayForm};