import {AppResources, Tags, VariantOption} from "Core/Core";
import {Functions} from "Core/Functions/Functions";

import QRCodeStyling, {CornerDotType, CornerSquareType, DotType} from "qr-code-styling";
import {RequiredOptions} from "qr-code-styling/lib/core/QROptions";
import React, {CSSProperties, useEffect, useRef} from "react";


const getDefaultOptions = (): RequiredOptions =>
{
    // @ts-ignore
    return {
        type: "svg",
        shape: "square",
        width: 300,
        height: 300,
        margin: 0,
        data: "",
        qrOptions: {
            typeNumber: 0,
            mode: "Byte",
            errorCorrectionLevel: "Q"
        },
        imageOptions: {
            hideBackgroundDots: false,
            imageSize: 0.4,
            margin: 10,
            crossOrigin: "anonymous",
        },
        image: undefined,
        backgroundOptions: {
            // @ts-ignore
            round: 0,
            color: "#ffffff",
            gradient: undefined
        },
        cornersSquareOptions: {
            type: "extra-rounded",
            color: "#0087CB"
        },
        cornersDotOptions: {
            type: undefined,
            color: "#0087CB"
        },
        dotsOptions: {
            type: "extra-rounded",
            color: "#0087CB"
        }
    }
};

interface IQRCodeProperties
{
    data: string,
    image?: string,
    color?: string,
    dotType?: DotType,
    dotColor?: string,
    cornerDotType?: CornerDotType,
    cornerDotColor?: string,
    cornerSquareType?: CornerSquareType,
    cornerSquareColor?: string,
    style?: CSSProperties,
    isDownload?: boolean
    button?: {
        className: string,
        variant: VariantOption
    }
}

const qrCode = new QRCodeStyling(getDefaultOptions());
const QRCode = (properties: IQRCodeProperties): React.JSX.Element =>
{
    const optionRef: any = useRef(null);
    useEffect(() =>
    {
        const defaultOptions = getDefaultOptions();
        let options: any = {
            data: properties.data,
            image: properties.image,
            dotsOptions: {
                type: properties.dotType,
                color: properties.dotColor ?? properties.color
            },
            cornersSquareOptions: {
                type: properties.cornerSquareType,
                color: properties.cornerSquareColor ?? properties.color
            },
            cornersDotOptions: {
                type: properties.cornerDotType,
                color: properties.cornerDotColor ?? properties.color
            }
        };

        options = Functions.merge(defaultOptions, options);
        qrCode.append(optionRef.current);
        qrCode.update(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadClick = async () =>
    {
        await qrCode.download({
            name: "QR",
            extension: "png"
        });
    };

    const downloadButton = properties.isDownload === true
        ? <Tags.Button
            className={properties.button?.className}
            variant={properties.button?.variant}
            startIcon={<Tags.Icons.SaveAlt/>}
            textResource={AppResources.Actions.DownloadQR}
            onClickAsync={onDownloadClick}/>
        : <Tags.Empty/>

    return (
        <div className={"qr-code-container"}>
            <div ref={optionRef}/>
            {downloadButton}
        </div>
    );
}


export {QRCode}


