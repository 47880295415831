import {useRef} from "react";
import {IPageProperties, PageRoutes, PaymentController, React, Tags} from "Universal/Packages";


const InternationalSetupPage = (properties: IPageProperties) =>
{
    const setupRegion = useRef<any>();
    const controller = new PaymentController();
    const transaction = controller.Transaction;
    if (!transaction ||
        !transaction.payment.internationalInfo ||
        !transaction.payment.internationalInfo.setupInfo)
    {
        controller.navigateWithCurrentParams(PageRoutes.Payment.Finish);
        return <Tags.Empty/>;
    }

    const setupForm = transaction.payment.internationalInfo.setupInfo?.htmlContent;
    const fragment = document
        .createRange()
        .createContextualFragment(setupForm);
    setupRegion.current.append(fragment);

    // Register global function to window
    (window as any).submitCheckEnrollment = () =>
    {
        if (controller)
        {
            controller.navigateWithCurrentParams(PageRoutes.Payment.InternationalAuthenticate);
        }
    }

    return (
        <>
            <div hidden ref={setupRegion}/>
            <controller.Page skin={properties.skin} 
                             store={properties.store}
                             controller={controller}
                             isLoading={true}/>
        </>
    );
};


export {InternationalSetupPage}