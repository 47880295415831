import React, {useEffect} from "react";
import {Components, Functions, PageRoutes, ResultCodes} from "Universal/Packages";
import {IApplePayProperties} from "./IApplePayProperties";


const ApplePayMessageID = "GalaxyPay_ApplePay";
const ApplePayState = {
    ShowPayment: "ShowPayment",
    InitPayment: "InitPayment",
    ValidatePayment: "ValidatePayment",
    ConfirmPayment: "ConfirmPayment",
    CancelPayment: "CancelPayment",
    ClosePayment: "ClosePayment",
    EndPayment: "EndPayment"
};

const win: any = (window as any);
win.isApplePayInitial = false;

const ApplePayIFramePage = (properties: IApplePayProperties) =>
{
    const isAvailable = properties.isApplePayAvailable;
    const request = properties.applePayRequest;
    const controller = properties.controller;
    const onValidateMerchant = properties.onValidateMerchant;
    const onConfirmPayment = properties.onConfirmPayment;
    const onApplePayCancel = properties.onApplePayCancel;
    const getFingerprint = properties.getFingerprint;

    const postMessage = (state: string, content: any) =>
    {
        const message = {
            id: ApplePayMessageID,
            state: state,
            domain: window.location.host,
            content: content
        };

        const targetOrigin = (window as any).TargetOrigin ?? "*";
        Functions.logDebug("WINDOW POST MESSAGE", targetOrigin, message);
        window.parent.postMessage(message, targetOrigin);
    };

    const onTimeout = async () =>
    {
        await controller.processTimeoutAsync();
        postMessage(ApplePayState.ConfirmPayment, false);
    };

    useEffect(() =>
    {
        if (win.isApplePayInitial)
        {
            return;
        }

        window.addEventListener("message", async (event: any) =>
        {
            const data = event.data;
            if (event.origin !== (window as any).TargetOrigin ||
                Functions.isNullOrWhiteSpace(data.id) ||
                data.id !== ApplePayMessageID)
            {
                return;
            }

            switch (data.state)
            {
                case ApplePayState.InitPayment:
                    postMessage(ApplePayState.InitPayment, request);
                    break;

                case ApplePayState.ValidatePayment:
                    const validateResult = await onValidateMerchant(data.content, data.domain);
                    postMessage(ApplePayState.ValidatePayment, validateResult.payment.appleInfo.appleInfo);
                    
                    const fingerprint = validateResult.payment.appleInfo.fingerprint.htmlContent;
                    const fragment = document
                        .createRange()
                        .createContextualFragment(fingerprint);
                    getFingerprint!.current.append(fragment);
                    
                    break;

                case ApplePayState.ConfirmPayment:
                    const result = await onConfirmPayment(data.content);
                    const resultCode = result.payment.captureInfo?.resultCode || ResultCodes.Error;
                    const isSuccess = resultCode === ResultCodes.Success;
                    postMessage(ApplePayState.ConfirmPayment, isSuccess);

                    setTimeout(() =>
                    {
                        controller.queryPaymentAsync().then(() =>
                        {
                            controller.navigateWithCurrentParams(PageRoutes.Payment.Result);
                        });
                    }, 1000);
                    break;

                case ApplePayState.CancelPayment:
                    onApplePayCancel(data.content);
                    break;

                case ApplePayState.ClosePayment:
                    await controller.processCancelAsync();
                    break;

                case ApplePayState.EndPayment:
                    const message = data.content;
                    await controller.processFailureAsync(message);
                    postMessage(ApplePayState.ConfirmPayment, false);
                    break;
            }
        });

        win.isApplePayInitial = true;
        postMessage(ApplePayState.ShowPayment, null);
    }, [isAvailable, controller, request, onValidateMerchant, onConfirmPayment, onApplePayCancel]);

    return (
        <>
            <Components.Timeout store={controller.Transaction}
                                onTimeout={onTimeout}
                                isHideCountdown={true}/>
        </>
    );
}


export {ApplePayIFramePage};