import {
    AppResources,
    BaseRequest,
    CheckoutBusiness,
    Functions,
    LanguageOption,
    PageRoutes,
    PreOrderData,
    ResponseCodeOption,
    ResponseData,
    SourceData,
    Stores,
    TransactionStages
} from "Universal/Packages";
import {UPCController} from "../Base/UPCController";


interface IPageLoadEvent
{
    onSuccess: (preorder: PreOrderData) => void
}

class CheckoutController extends UPCController
{
    public PreOrder = this.getStore<PreOrderData>(Stores.PreOrder);
    private Business = new CheckoutBusiness();
    private Translate = Functions.translate();


    public queryCheckout = (event: IPageLoadEvent) =>
    {
        this.onPageLoad(() =>
        {
            const request: BaseRequest = this.createRequest();
            this.Business
                .getCheckoutAsync(request, this.PreOrder)
                .then(this.onComplete)
                .then(event.onSuccess, () => {});
        });
    };

    public createPaymentAsync = async (source: SourceData) =>
    {
        source.sessionKey = this.PreOrder.sessionKey;
        source.paymentMethod = this.PreOrder.payment.paymentMethod;

        const request: BaseRequest = this.createRequest();
        const result = await this.Business.createPaymentAsync(request, source, this.PreOrder);
        const responseCode = result?.responseCode ?? "";

        if (responseCode === ResponseCodeOption.Success)
        {
            this.navigateWithCurrentParams(PageRoutes.Payment.Index);
        }
        else if (Functions.isInArray(
            responseCode,
            ResponseCodeOption.ServiceUnavailable, ResponseCodeOption.BadGateway) === false)
        {
            this.navigateWithCurrentParams(PageRoutes.Checkout.Finish);
        }

        return result;
    };

    public processCancelAsync = async () =>
    {
        const request: BaseRequest = this.createRequest();
        await this.Business.cancelCheckoutAsync(request)
    }

    public processCloseAsync = async () =>
    {
        await this.processCancelAsync();
        
        if(Functions.isIframe())
        {
            // Post event message
            const currentState = TransactionStages.Close;
            const result: any = {};
            this.changeState(currentState, "", result);
        }
        else 
        {
            window.location.href = this.PreOrder.merchant.failureURL;
        }
    }

    public closeOnCreatePaymentFailure = (response: ResponseData<any>) =>
    {
        return async () =>
        {
            if (response.responseCode === ResponseCodeOption.BadGateway)
            {
                await this.processCloseAsync();
            }
        };
    }


    private onComplete = (preorder: PreOrderData) =>
    {
        if (!preorder ||
            preorder.responseCode !== ResponseCodeOption.Success)
        {
            this.navigateWithCurrentParams(PageRoutes.Checkout.Finish);
            return Promise.reject(new Error("Transaction has error."));
        }

        const language = preorder.order.language || LanguageOption.English
        Functions.changeLanguage(language);

        // Send default state
        const currentState = TransactionStages.Init;
        const title = this.Translate(AppResources.Messages.StageTitle, {stage: currentState})
        const result: any = {
            providerCode: preorder.payment.providerCode,
            language: language
        };
        
        this.changeState(currentState, title, result);

        return preorder;
    };

    private createRequest = (): BaseRequest =>
    {
        const {transactionID, dateTimeExpire, signature} = this.LocationParams;
        const request: BaseRequest = new BaseRequest();
        request.transactionID = transactionID;
        request.dateTimeExpire = this.Functions.toNumber(dateTimeExpire);
        request.signature = signature;

        return request;
    };
}


export {CheckoutController};