import {IFieldProperties, Tags} from "Core/References";
import {useState} from "react";


const PasswordField = (properties: IFieldProperties) =>
{
    const [invisible, setInvisible] = useState(true);
    const className = invisible ? "password" : "";
    const toggleIcon =
        <Tags.InputAdornment position={"end"}>
            <Tags.IconButton
                aria-label="toggle password visibility"
                onClick={() => setInvisible(!invisible)}>
                {invisible ? <Tags.Icons.VisibilityOff/> : <Tags.Icons.Visibility/>}
            </Tags.IconButton>
        </Tags.InputAdornment>;

    return (
        <Tags.TextField {...properties} className={className} endIcon={toggleIcon}/>
    );
}


export {PasswordField};