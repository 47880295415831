import {Material} from "../MaterialPackage";


const Box = (properties: any) =>
{
    return (
        <Material.Box {...properties} />
    );
};


export {Box};