interface ICountdownProperties
{
    totalSeconds: number,
    remainSeconds: number,

    classTimer?: string
}

const Countdown = (properties: ICountdownProperties) =>
{
    let minutes = "00";
    let seconds = "00";

    if (properties.totalSeconds > 0 &&
        properties.remainSeconds > 0)
    {
        const count = properties.totalSeconds - properties.remainSeconds;
        const timeRemain = properties.totalSeconds - (count % properties.totalSeconds);
        minutes = String(Math.floor(timeRemain / 60)).padStart(2, "0");
        seconds = String(timeRemain % 60).padStart(2, "0");

        // Functions.logDebug(`Remain Time: ${minutes}:${seconds}`);
    }

    return (
        <div className={`order-timer ${properties.classTimer}`}>
            <div className="order-timer-minute">
                <span>{minutes}</span>
            </div>
            <div className="order-timer-space">
                <span>:</span>
            </div>
            <div className="order-timer-second">
                <span>{seconds}</span>
            </div>
        </div>
    );
};


export {Countdown};