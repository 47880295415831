import {IContainerRowProperties} from "Core/References";
import {Material} from "../MaterialPackage";


const Row = (properties: IContainerRowProperties) =>
{
    const spacing = properties.spacing ?? 2;
    return (
        <Material.Grid container
                       id={properties.id}
                       className={properties.className}
                       style={properties.style}
                       spacing={spacing}
                       onClick={properties.onClick}
        >
            {properties.children}
        </Material.Grid>
    );
};


export {Row};